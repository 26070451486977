import { Input, LinkListItem, FormGroup, LinkList, Col, Toggle, Row, Button, Table, Alert, Icon, ModalFooter, ModalBody, ModalHeader, Modal, Tooltip, Container, Label } from 'design-react-kit';
import React, { useState, useEffect, useRef } from 'react';
import prestazioniService from '../service/prestazioniService';
import { wait } from '../common/Loading';
import '../../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import { PaginationControl } from '../custom/PaginationControl';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function PrestazioniPage({ isPageChanged, setIsPageChanged }) {
    const navigate = useNavigate();
    const [denominazione, setDenominazione] = useState("");
    const [denominazioneHelper, setDenominazioneHelper] = useState("");
    const [denominazioneError, setDenominazioneError] = useState(false);
    const [codPrestazione, setCodPrestazione] = useState("");
    const [codPrestazioneHelper, setCodPrestazioneHelper] = useState("");
    const [codPrestazioneError, setCodPrestazioneError] = useState(false);
    const [filterFlgVisibile, setFilterFlgVisibile] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [sortOrder, setOrder] = useState("asc");
    const [sortKey, setSortKey] = useState("nomePrestazione");
    const [open, toggleModal] = useState(false);
    const [username, setUsername] = useState('');
    const [idPrestazioni, setIdPrestazioni] = useState(null);
    const [denominazioneForm, setDenominazioneForm] = useState("");
    const [denominazioneFormHelper, setDenominazioneFormHelper] = useState("");
    const [denominazioneFormError, setDenominazioneFormError] = useState(false);
    const [titoloModal, setTitoloModal] = useState("Inserisci Prestazioni");
    const [codPrestazioneForm, setCodPrestazioneForm] = useState("");
    const [codPrestazioneFormHelper, setCodPrestazioneFormHelper] = useState("");
    const [codPrestazioneFormError, setCodPrestazioneFormError] = useState(false);
    const [flgVisibile, setFlgVisibile] = useState(true);
    const [prestazioniList, setPrestazioniList] = useState([]);
    const [codiceFiscale, setCodiceFiscale] = useState('GLTFNC93B05H501U');
    const [isOpenDelete, toggleModalDelete] = useState(false);
    const [item, setItem] = useState(null);
    const [fileUploadErrorModal, toggleFileUploadErrorModal] = useState(false);
    const [fileUploadModal, toggleFileUploadModal] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileValidationMessage, setFileValidationMessage] = useState("");

    function getFileExtension(file) {
        return file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    }

    const hiddenFileInput = useRef(null);

    const handleHiddenButtonClick = event => {
        hiddenFileInput.current.click();
    };

    function fileTemplateValidation(file) {
        let validation = {
            result: false,
            message: "",
        };

        ["xlsx", "xlsm", "xlsb", "xls"].includes(
            getFileExtension(file).toLowerCase()
        )
            ? (validation.result = true)
            : (validation.message =
                "È possibile caricare solo file con formato Excel");

        return validation;
    }

    const handleHiddenInputChange = event => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            setUploadedFile(fileUploaded);
            toggleFileUploadModal(true);
        }
    };

    const handleUploadedFileSending = () => {
        toggleFileUploadModal(false);
        let validation = fileTemplateValidation(uploadedFile);

        if (validation.result) {
            // send request
            const formData = new FormData();
            formData.append('file', uploadedFile);
            wait(prestazioniService.uploadExcelFile(formData))
                .then((response) => {
                    console.log(response);
                    setNumberOfPages(Math.ceil(response.data.paginationData.numElements / pageSize));
                    let prestazioniTmp = [];
                    if (response.data.data) {
                        response.data.data.map((index) => {
                            prestazioniTmp.push({
                                'denominazione': index.descrizione,
                                'flgVisibile': index.flgVisibile,
                                'codice': index.codice,
                                'id': index.id,
                            });
                        });
                        setPrestazioniList(prestazioniTmp);
                    } else
                        setPrestazioniList([]);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        console.log(error);
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        } else {
            setFileValidationMessage(validation.message);
            toggleFileUploadErrorModal(true);
        }

    };

    const handleDownloadTemplate = () => {
        wait(prestazioniService.downloadTemplate())
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `template.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
    }

    const confirmDeleteClick = (item) => {
        handleDelete(item);
        toggleModalDelete(!isOpenDelete);
    }

    const [alert, setAlert] = useState({
        'state': false,
        'text': '',
        'type': ''
    });
    const [cercaTooltip, setCercaTooltip] = useState(false);
    const [inserisciTooltip, setInserisciTooltip] = useState(false);
    const [inserisciExcelTooltip, setInserisciExcelTooltip] = useState(false);
    const [scaricaTemplateTooltip, setScaricaTemplateTooltip] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        localStorage.setItem('page', 0);
    }, []);

    useEffect(() => {
        handleSubmit();
    }, [pageNumber]);

    useEffect(() => {
        handleSubmit();
    }, []);

    const handleSubmit = () => {
        clearAll();
        let count = 0;
        if (denominazione && denominazione.length < 3) {
            setDenominazioneError(true);
            setDenominazioneHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setDenominazioneError(false);
            setDenominazioneHelper("");
        }

        if (codPrestazione && codPrestazione.length < 3) {
            setCodPrestazioneError(true);
            setCodPrestazioneHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setCodPrestazioneError(false);
            setCodPrestazioneHelper("");
        }

        if (count <= 0) {
            let payload = {
                data: {
                    denominazione: denominazione,
                    codice: codPrestazione,
                    flgVisibile: filterFlgVisibile,
                },
                paginationData: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortKey: sortKey,
                    sortOrder: sortOrder
                }
            };
            wait(prestazioniService.searchPrestazioni(payload))
                .then((response) => {
                    setNumberOfPages(Math.ceil(response.data.paginationData.numElements / pageSize));
                    let prestazioniTmp = [];
                    if (response.data.data) {
                        response.data.data.map((index) => {
                            prestazioniTmp.push({
                                'denominazione': index.descrizione,
                                'flgVisibile': index.flgVisibile,
                                'codice': index.codice,
                                'id': index.id,
                            });
                        });
                        setPrestazioniList(prestazioniTmp);
                    } else
                        setPrestazioniList([]);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        }

    }

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    const handleNavigation = (titolo) => {
        setTitoloModal(titolo);
        toggleModal(true);
    };

    const handleDelete = (item) => {
        wait(prestazioniService.deletePrestazioni(item.id))
            .then((response) => {
                handleSubmit();
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    };


    const toggle = () => { clearAll(); toggleModal(!open) };

    const clearAll = () => {
        setDenominazioneFormError(false);
        setDenominazioneFormHelper("");
        setDenominazioneError(false);
        setDenominazioneHelper("");
        setCodPrestazioneError(false);
        setCodPrestazioneHelper("");
        setCodPrestazioneFormHelper("");
        setCodPrestazioneFormError(false);
    }

    const toggleSave = () => {
        clearAll();
        let count = 0;
        if (!denominazioneForm || denominazioneForm === "") {
            setDenominazioneFormError(true);
            setDenominazioneFormHelper("Campo obbligatorio");
            count++;
        } else if (denominazioneForm.length < 3) {
            setDenominazioneFormError(true);
            setDenominazioneFormHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setDenominazioneFormError(false);
            setDenominazioneFormHelper("");
        }

        if (!codPrestazioneForm || codPrestazioneForm === "") {
            setCodPrestazioneFormError(true);
            setCodPrestazioneFormHelper("Campo obbligatorio");
            count++;
        } else if (codPrestazioneForm.length < 3) {
            setCodPrestazioneFormError(true);
            setCodPrestazioneFormHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setCodPrestazioneFormError(false);
            setCodPrestazioneFormHelper("");
        }

        if (count <= 0) {
            let payload = {
                id: idPrestazioni,
                descrizione: denominazioneForm,
                codice: codPrestazioneForm,
                flgVisibile: flgVisibile
            };
            wait(prestazioniService.savePrestazioni(payload))
                .then((response) => {
                    toggleModal(!open);
                    handleSubmit();
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        }
    };

    const openModal = (name) => {
        setUsername(name);
        toggleModal(true);
    };

    return (<Container fluid>
        <CustomBreadCrumb
            first={{
                "name": "Elenco Prestazioni",
                "onClick": () => { },
                "active": true
            }}
        />
        <Modal fade centered isOpen={open} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {titoloModal}
            </ModalHeader>
            <ModalBody>
                <div>
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Denominazione'
                        value={denominazioneForm}
                        onChange={(event) => { setDenominazioneForm(event.target.value); }}
                        infoText={denominazioneFormHelper}
                        invalid={denominazioneFormError}
                        maxLength={100}
                    />
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Codice'
                        value={codPrestazioneForm}
                        onChange={(event) => setCodPrestazioneForm(event.target.value)}
                        infoText={codPrestazioneFormHelper}
                        invalid={codPrestazioneFormError}
                    />
                    <Toggle label="Visibile" disabled={false} checked={flgVisibile} onChange={(e) => setFlgVisibile(e.target.checked)} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="buttonPrimary" color='secondary' onClick={toggle}>
                    Chiudi
                </Button>
                <Button className="buttonPrimary" color='primary' onClick={toggleSave}>
                    Salva modifiche
                </Button>
            </ModalFooter>
        </Modal>

        <Modal
            isOpen={fileUploadModal}
            toggle={() => toggleFileUploadModal(!fileUploadModal)}
        >
            <ModalHeader toggle={() => toggleFileUploadModal(!fileUploadModal)} id='esempio12'>
                Conferma
            </ModalHeader>
            <ModalBody>
                <p>Sei sicuro di voler procedere? Il caricamento da foglio excel renderà non più visibili tutte le prestazioni che non sono presenti nel foglio stesso</p>
            </ModalBody>
            <ModalFooter>
                <Button className="buttonPrimary" onClick={() => {
                    toggleFileUploadModal(!fileUploadModal);
                    setUploadedFile(null)
                }}>
                    Annulla
                </Button>
                <Button className="buttonPrimary" onClick={() => handleUploadedFileSending()}>
                    Conferma
                </Button>
            </ModalFooter>
        </Modal>

        <Modal
            isOpen={fileUploadErrorModal}
            toggle={() => toggleFileUploadErrorModal(!fileUploadErrorModal)}
        >
            <ModalBody>
                <p>{fileValidationMessage}</p>
            </ModalBody>
            <ModalFooter>
                <Button className="buttonPrimary" onClick={() => {
                    toggleFileUploadErrorModal(!fileUploadErrorModal);
                    setUploadedFile(null)
                }}>
                    Annulla
                </Button>
            </ModalFooter>
        </Modal>
        <Col>
            <span class='bi bi-check-circle' />
            <Row style={{ marginTop: '4vh', columnGap: "1em" }}>
                <Col xs={12} md={3}  >
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Denominazione'
                        value={denominazione}
                        onChange={(event) => { setIsPageChanged(true); setDenominazione(event.target.value); }}
                        infoText={denominazioneHelper}
                        invalid={denominazioneError}
                    />
                </Col>
                <Col xs={12} md={3}  >
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Codice'
                        value={codPrestazione}
                        onChange={(event) => { setIsPageChanged(true); setCodPrestazione(event.target.value); }}
                        infoText={codPrestazioneHelper}
                        invalid={codPrestazioneError}
                    />
                </Col>
                <Col xs={12} md={5}>
                    <form >
                        <fieldset>
                            <FormGroup check inline>
                                <Input name='gruppo1' type='radio' id='radio1' defaultChecked onChange={() => { setFilterFlgVisibile(null); }} />
                                <Label check htmlFor='radio1'>
                                    Tutti
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Input name='gruppo1' type='radio' id='radio2' onChange={() => { setFilterFlgVisibile(true) }} />
                                <Label check htmlFor='radio2'>
                                    Visibile
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Input name='gruppo1' type='radio' id='radio3' onChange={() => { setFilterFlgVisibile(false) }} />
                                <Label check htmlFor='radio3'>
                                    Non Visibile
                                </Label>
                            </FormGroup>
                        </fieldset>
                    </form>
                </Col>
            </Row>
            <Row className='d-flex justify-content-end'>

                <Col xs={12} md={6} xl={2}>
                    <Button className="buttonPrimary"
                        id='scarica_template_btn'
                        color='primary'
                        block
                        onClick={handleDownloadTemplate}
                    >
                        SCARICA TEMPLATE
                    </Button>{' '}
                </Col>

                <Col xs={12} md={6} xl={2}>
                    <Button className="buttonPrimary"
                        id='inserisci_excel_btn'
                        color='primary'
                        block
                        onClick={handleHiddenButtonClick}
                    >
                        INSERISCI DA EXCEL
                    </Button>
                    <input type="file"
                        ref={hiddenFileInput}
                        onChange={handleHiddenInputChange}
                        style={{ display: 'none' }}
                    />
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={inserisciExcelTooltip}
                    target={'inserisci_excel_btn'}
                    toggle={() => setInserisciExcelTooltip(!inserisciExcelTooltip)}
                >
                    Inserisci da Excel
                </Tooltip>

                <Col xs={12} md={6} xl={2}>
                    <Button className="buttonPrimary"
                        id='inserisci_btn'
                        color='primary'
                        block
                        onClick={() => {
                            setIdPrestazioni(null);
                            setDenominazioneForm("");
                            setCodPrestazioneForm("");
                            setFlgVisibile(true);
                            handleNavigation("Inserisci Prestazioni");
                        }}
                    >
                        INSERISCI
                    </Button>{' '}
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={inserisciTooltip}
                    target={'inserisci_btn'}
                    toggle={() => setInserisciTooltip(!inserisciTooltip)}
                >
                    Inserisci Prestazioni
                </Tooltip>
                <Col xs={12} md={6} xl={2}>
                    <Button className="buttonPrimary"
                        id='cerca_btn'
                        color='primary'
                        block
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        CERCA
                    </Button>{' '}
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={cercaTooltip}
                    target={'cerca_btn'}
                    toggle={() => setCercaTooltip(!cercaTooltip)}
                >
                    Cerca Prestazioni
                </Tooltip>
            </Row>
            <LinkList>
                <LinkListItem divider />
            </LinkList>
            {prestazioniList && prestazioniList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th scope='col'>Denominazione</th>
                            <th scope='col'>Codice</th>
                            <th scope='col'>Visibile</th>
                            <th scope='col' class="text-center">Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prestazioniList.map((item, index) => (
                            <tr>
                                <td>{item.denominazione}</td>
                                <td>{item.codice}</td>
                                <td>{item.flgVisibile ? "SI" : "NO"}</td>
                                <td class="text-center">
                                    <Button title="Aggiorna" onClick={() => {
                                        setIdPrestazioni(item.id);
                                        setDenominazioneForm(item.denominazione);
                                        setFlgVisibile(item.flgVisibile);
                                        setCodPrestazioneForm(item.codice);
                                        handleNavigation("Modifica Prestazioni");
                                    }}>  <Icon icon={"it-pencil"} /></Button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)}

            {alert.state &&
                <div>
                    <div className='modal-content'>
                        <Modal fade centered isOpen={alert.state}>
                            <ModalHeader>Errore</ModalHeader>
                            <ModalBody>{alert.text}</ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => {
                                    setAlert({
                                        'state': false,
                                        'text': "",
                                        'type': ''
                                    });
                                }} color='secondary'>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            }

            {isOpenDelete &&
                <Modal
                    isOpen={isOpenDelete}
                    toggle={() => toggleModalDelete(!isOpenDelete)}
                >
                    <ModalHeader toggle={() => toggleModalDelete(!isOpenDelete)} id='esempio12'>
                        Conferma
                    </ModalHeader>
                    <ModalBody>
                        <p>L'operazione è irreversibile. Sei sicuro di voler procedere?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="buttonPrimary" onClick={() => toggleModalDelete(!isOpenDelete)}>
                            Annulla
                        </Button>
                        <Button className="buttonPrimary" onClick={() => confirmDeleteClick(item)}>
                            Conferma
                        </Button>
                    </ModalFooter>
                </Modal>}
            <Row style={{ display: "flex", justifyContent: "end" }}>
                <PaginationControl
                    selectedPage={pageNumber}
                    numberOfPages={numberOfPages}
                    changePage={setPageNumber}
                />
            </Row>
        </Col></Container>

    );
}

export default PrestazioniPage;