import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchSpecialita = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/specialita/search`, payload);
};

const saveSpecialita = payload => {
    return axios.put(`${BASE_ENDPOINT}/v1/specialita`, payload);
};

const deleteSpecialita = payload => {
    return axios.delete(`${BASE_ENDPOINT}/v1/specialita/` + payload);
};

export default {
    deleteSpecialita,
    searchSpecialita,
    saveSpecialita
};