import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchServizi = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/servizi/search`, payload);
};

const saveServizi = payload => {
    return axios.put(`${BASE_ENDPOINT}/v1/servizi`, payload);
};

const deleteServizi = payload => {
    return axios.delete(`${BASE_ENDPOINT}/v1/servizi/` + payload);
};

export default {
    deleteServizi,
    searchServizi,
    saveServizi
};