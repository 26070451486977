import { Icon, Pager, PagerItem, PagerLink } from 'design-react-kit';

export const PaginationControl = (props) => {
    const currentPage = props.selectedPage + 1;

    return (
        <Pager className='mb-3' aria-label='Esempio di paginazione'>        
            <ul className='pagination'>
            <PagerItem>
                <PagerLink previous href='#' className='text' onClick={() => {
                                                                    if(currentPage > 1){ 
                                                                        localStorage.setItem('page', props.selectedPage - 1);
                                                                        props.changePage(props.selectedPage - 1);
                                                                    }}}>
                    <Icon icon='it-chevron-left' aria-hidden />
                </PagerLink>
            </PagerItem>           
            <PagerItem>
                <PagerLink aria-current='page' href='#'>
                  {currentPage}
                </PagerLink>
            </PagerItem>
            <PagerItem className='d-none d-sm-block'>
                <PagerLink tag='span'>/</PagerLink>
            </PagerItem>                
            <PagerItem className='d-none d-sm-block'>
                    <PagerLink 
                      href='#'
                      onClick={() => { 
                            localStorage.setItem('page', props.numberOfPages - 1);
                            props.changePage(props.numberOfPages - 1);
                      }}>
                            {props.numberOfPages}
                    </PagerLink>
            </PagerItem>
            <PagerItem>
                <PagerLink next href='#' className='text' onClick={() => {
                                                                    if(currentPage < props.numberOfPages){ 
                                                                        localStorage.setItem('page', props.selectedPage + 1);
                                                                        props.changePage(props.selectedPage + 1);
                                                                    }}}>
                    <Icon icon='it-chevron-right' aria-hidden />
                </PagerLink>
            </PagerItem>
            </ul>
        </Pager>
    )
}




