import { CookieBar, CookieBarButton, CookieBarButtons } from 'design-react-kit';
import React, { useEffect, useState } from "react";
import '../../App.css';

export const CookiesBanner = (props) => {
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let cookieChecked = localStorage.getItem("regione.sicilia.atlante.cookie-checked");

    setIsVisible(!cookieChecked);
  }, []);

    const acceptCookies = () => {
        localStorage.setItem("regione.sicilia.atlante.cookie-checked", true);
        setIsVisible(false);
    }

    return (
        isVisible && 
            <CookieBar style={{zIndex: "1"}}>
                <p>
                    Per offrirti una migliore esperienza possibile questo sito
                    utilizza cookies. Continuando la navigazione nel sito ne
                    autorizzi l'uso. Per maggiori informazioni consulta la
                    nostra&nbsp;
                    <strong
                    style={{
                        fontStyle: "italic",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                    onClick={() => {props.setOpenDialogCookie(!props.openDialogCookie);}}
                    >
                        Cookies policy
                    </strong>            
                </p>
                <CookieBarButtons>
                    <CookieBarButton className= "accettaButton" onClick={acceptCookies}>
                    Ho capito
                    <span className="sr-only">
                        {' '}i cookies
                    </span>
                    </CookieBarButton>
                </CookieBarButtons>
            </CookieBar>
    )
}

