import { useKeycloak } from "@react-keycloak/web";
import { Button, Icon, Modal, ModalFooter, ModalHeader } from 'design-react-kit';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import logo from '../../img/regione-sicilia-logo.svg';

export const RequiredProfileModal = ({
    requiredProfileModalIsOpen,
    toggleRequiredProfile
}) => {
    const { keycloak } = useKeycloak();

    return (<>
        {requiredProfileModalIsOpen && <Modal
            key={requiredProfileModalIsOpen}
            className="requiredProfileModal"
            isOpen={true}
            toggle={() => { }}
        >
            <ModalHeader toggle={() => { }} id='esempio12'>
                <Icon icon={logo} />
                <h2>Utente non autorizzato.</h2>
            </ModalHeader>
                <ModalFooter className="errorModalFooter">
                    <Button 
                      className="buttonPrimary esciButton" 
                      onClick={() => { 
                          keycloak.logout(); 
                      }}>
                        Esci
                    </Button>
                </ModalFooter>
        </Modal>
        }        
    </>)
}