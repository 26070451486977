import React, { memo } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AziendePage from "./aziende/AziendePage";
import SpecialitaPage from "./specialita/SpecialitaPage";
import ServiziPage from "./servizi/ServiziPage";
import PrestazioniPage from "./prestazioni/PrestazioniPage";
import GestioneStruttura from "./struttura/GestioneStruttura";
import Struttura from "./struttura/Struttura";
import Page from "./scheleton/page"
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import GestioneAzienda from "./aziende/GestioneAzienda";
import GestionePresidio from "./presidio/GestionePresidio";
import { useState, useEffect } from 'react';
import PresidiPage from "./presidio/PresidiPage";
import CaricamentoPresidio from "./caricamento/caricamentoPresidio";
import {TimeoutModal} from "./custom/TimeoutModal";

const Root = React.memo(function (props) {

  const { keycloak } = useKeycloak();
  const [openDialog, setOpenDialog] = useState(false);
  axios.interceptors.request.use((request) => {
    if (keycloak.token) {
      request.headers.common.Authorization = `Bearer ${keycloak.token}`; 
    }
    return request;
  },
    (err) => {
      return Promise.reject(err);
    }
  );
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  localStorage.setItem('lastActvity', new Date());
  events.forEach(event => {
      document.addEventListener(event,
      () =>{ 
        localStorage.setItem('lastActvity', new Date());
      })
  ;});

  let inactivityFunction = () => {
    if(openDialog) {
      setOpenDialog(false);
      keycloak.logout();
    }
  }

  const [counter, setCounter] = useState(30);

  useEffect(() => {
      if(openDialog){
         const timer = setTimeout(() => {
              inactivityFunction();
          }, 30000);
         return () => clearTimeout(timer);
      }
  }, 
  [openDialog]);

  useEffect(() => {

  let timeInterval = setInterval(() => {
   let lastAcivity = localStorage.getItem('lastActvity')
        let diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
        let seconds = Math.floor((diffMs/1000));
        let minute = Math.floor((seconds/60));
        // check if the seconds are equal to 29 minutes and 30 seconds
        if(seconds === 870){
          setOpenDialog(true);
        }
  }, 1000);
    return () => clearInterval(timeInterval);
  }, []);

  //check for "ATL_OP_ADM_RESP" profile
  let hasAuthority = () => {
    // TODO: to be changed to check for particular authority
    if(true) {
      props.toggleRequiredProfile(false);
      props.setIsATL_OP_ADM_RESP(true);
      return 1;
    } else if(keycloak.idTokenParsed.given_name === 'francesco2') {
      props.toggleRequiredProfile(false);
      props.setIsATL_OP_ADM(true);
      return 2;
    } else {
      props.toggleRequiredProfile(true);
    }
    return 0;
  }

  return (
    <>
    <Routes>
      <Route path="aziende" element={keycloak.authenticated && hasAuthority() !== 0 && <AziendePage isATL_OP_ADM_RESP={props.isATL_OP_ADM_RESP} isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="gestione-reparti" element={keycloak.authenticated && hasAuthority() !== 0 && <GestioneStruttura isATL_OP_ADM_RESP={props.isATL_OP_ADM_RESP} isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="reparti" element={keycloak.authenticated && hasAuthority() !== 0 && <Struttura isATL_OP_ADM_RESP={props.isATL_OP_ADM_RESP} isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="gestione-azienda" element={keycloak.authenticated && hasAuthority() !== 0 && <GestioneAzienda isATL_OP_ADM_RESP={props.isATL_OP_ADM_RESP} isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="specialita" element={keycloak.authenticated && hasAuthority() === 1 && <SpecialitaPage isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="servizi" element={keycloak.authenticated && hasAuthority() === 1 && <ServiziPage isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="prestazioni" element={keycloak.authenticated && hasAuthority() ===1 && <PrestazioniPage isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="gestione-presidi" element={keycloak.authenticated && hasAuthority() !== 0 && <GestionePresidio isATL_OP_ADM_RESP={props.isATL_OP_ADM_RESP} isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="presidi" element={keycloak.authenticated && hasAuthority() !== 0 && <PresidiPage isATL_OP_ADM_RESP={props.isATL_OP_ADM_RESP} isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="admin" element={keycloak.authenticated && hasAuthority() === 1 && <CaricamentoPresidio isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} />} />

      <Route path="" element={<Navigate replace to="aziende" />} />

    </Routes>
          {openDialog && (
                    <TimeoutModal
                        openDialog={true}
                        toggleDialog={(value) => setOpenDialog(value)}
                        seconds={counter}
                    />
          )}
    </>
  );
});
const RootPage = ({ ...props }) => {
  return <Page body={<Root {...props} />} />;
};
export default memo(RootPage);