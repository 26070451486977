import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchPrestazioni = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/prestazioni/search`, payload);
};

const savePrestazioni = payload => {
    return axios.put(`${BASE_ENDPOINT}/v1/prestazioni`, payload);
};

const deletePrestazioni = payload => {
    return axios.delete(`${BASE_ENDPOINT}/v1/prestazioni/` + payload);
};

const uploadExcelFile = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/prestazioni`, payload)
};

const downloadTemplate = () => {
    return axios.get(`${BASE_ENDPOINT}/v1/prestazioni/download-template`, { responseType: 'arraybuffer' })
}

export default {
    deletePrestazioni,
    searchPrestazioni,
    savePrestazioni,
    uploadExcelFile,
    downloadTemplate,
};