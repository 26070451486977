import { Input, LinkListItem, UncontrolledTooltip, LinkList, Col, Select, Row, Button, Table, CardTitle, Alert, Icon, ModalFooter, ModalBody, ModalHeader, Modal, Tooltip, Container } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import aziendaService from '../service/aziendaService';
import { wait } from '../common/Loading';
import '../../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import strutturaService from '../service/strutturaService';
import tipologiaService from '../service/tipologiaService';
import { PaginationControl } from '../custom/PaginationControl';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function Struttura({ isATL_OP_ADM_RESP, isPageChanged, setIsPageChanged }) {
    const navigate = useNavigate();

    const [denominazione, setDenominazione] = useState(null);
    const [denominazioneHelper, setDenominazioneHelper] = useState("");
    const [denominazioneError, setDenominazioneError] = useState(false);

    const [codiceStruttura, setCodiceStruttura] = useState("");
    const [codiceAzienda, setCodiceAzienda] = useState(null);

    const [tipoStruttura, setTipoStruttura] = useState(null);
    const [tipoStrutturaList, setTipoStrutturaList] = useState([]);
    const [isTipoStrutturaEnabled, setIsTipoStrutturaEnabled] = useState(false);

    const [tipoSpecialita, setTipoSpecialita] = useState(null);
    const [tipoSpecialitaList, setTipoSpecialitaList] = useState([]);
    const [isTipoSpecialitaEnabled, setIsTipoSpecialitaEnabled] = useState(false);

    const [strutturaList, setStrutturaList] = useState([]);

    const [pageNumber, setPageNumber] = useState(localStorage.getItem('page') ?
        Number(localStorage.getItem('page')) :
        0);
    const [pageSize, setPageSize] = useState(10);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [sortOrder, setOrder] = useState("asc");
    const [sortKey, setSortKey] = useState("nomeStruttura");

    const [cercaTooltip, setCercaTooltip] = useState(false);


    const [isOpenDelete, toggleModalDelete] = useState(false);
    const [item, setItem] = useState(null);

    const [alert, setAlert] = useState({
        'state': false,
        'text': '',
        'type': ''
    });

    const handleDeleteToggle = (item) => {
        setItem(item);
        toggleModalDelete(!isOpenDelete);
    }


    const onChangeTipoStruttura = (event) => {
        setTipoStruttura(event);
        setIsTipoStrutturaEnabled(false);
    }

    const onChangeTipoSpecialita = (event) => {
        setTipoSpecialita(event);
        setIsTipoSpecialitaEnabled(false);
    }



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        handleSubmit();
    }, []);

    useEffect(() => {
        handleSubmit();
    }, [pageNumber]);

    useEffect(() => {
        wait(tipologiaService.getTipoStruttura())
            .then((response) => {
                let tipoStrutturaTmp = [];
                response.data.data.map((index) => {
                    tipoStrutturaTmp.push({
                        'label': index.descrizione,
                        'value': index.id
                    });
                });
                setTipoStrutturaList(tipoStrutturaTmp);
            });
    }, []);

    useEffect(() => {
        wait(strutturaService.getSpecializzazioneOptions())
            .then((response) => {
                let tipoSpecialitaTmp = [];
                response.data.data.map((index) => {
                    tipoSpecialitaTmp.push({
                        'label': index.descrizione,
                        'value': index.id
                    });
                });
                setTipoSpecialitaList(tipoSpecialitaTmp);
            });
    }, []);

    const handleNavigationStrutture = (pageStatus, item) => {
        navigate("/gestione-reparti", {
            state: {
                pageStatus: pageStatus,
                id: item.idStruttura,
                idAzienda: item.idAzienda ? item.idAzienda : null,
                idPresidio: item.idPresidio ? item.idPresidio : null,
                fromPresidio: false,
                fromAzienda: true,
                fromStruttura: true,
            }
        });
    };

    const handleDelete = (item) => {
        wait(strutturaService.deleteStrutture(item.idStruttura))
            .then((response) => {
                handleSubmit();
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    };

    const confirmDeleteClick = (item) => {
        handleDelete(item);
        toggleModalDelete(!isOpenDelete);
    }

    const handleSubmit = () => {
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        let count = 0;
        if (denominazione && denominazione.length < 3) {
            setDenominazioneError(true);
            setDenominazioneHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setDenominazioneError(false);
            setDenominazioneHelper("");
        }

        console.log(strutturaList);
        if (count <= 0) {
            let payload = {
                data: {
                    denominazione: denominazione,
                    tipoStruttura: tipoStruttura,
                    tipoSpecialita: tipoSpecialita,
                    codiceStruttura: codiceStruttura,
                    codiceAzienda: codiceAzienda === "" ? null : codiceAzienda,
                },
                paginationData: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortKey: sortKey,
                    sortOrder: sortOrder
                }
            };
            wait(strutturaService.searchStruttura(payload))
                .then((response) => {
                    setNumberOfPages(Math.ceil(response.data.paginationData.numElements / pageSize));
                    let aziendaTmp = [];
                    if (response && response.data && response.data.data) {
                        response.data.data.map((index) => {
                            aziendaTmp.push({
                                'denominazione': index.nomeStruttura,
                                'tipoStruttura': index.tipoStruttura.descrizione,
                                'tipoSpecialita': index.tipoSpecialita.descrizione,
                                'indirizzo': index.indirizzo,
                                'idStruttura': index.idStruttura,
                                'idAzienda': index.azienda ? index.azienda.idAzienda : null,
                                'nomeAzienda': index.azienda ? index.azienda.nomeAzienda : null,
                                'idPresidio': index.presidio ? index.presidio.idPresidio : null,
                                'nomePresidio': index.presidio ? index.presidio.nomePresidio : null,
                                'responsabile': index.responsabile,
                                'telefonoDirezione': index.telefonoDirezione,
                                'telefonoSegreteria': index.telefonoSegreteria,
                                'codStruttura': index.codStruttura
                            });
                        });
                        setStrutturaList(aziendaTmp)
                    } else
                        setStrutturaList([]);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        }
    }

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    //     return (
    //     <div>Struttura Search</div>
    // )

    return (<Container fluid>
        <CustomBreadCrumb
            first={{
                "name": "Elenco Reparti",
                "onClick": () => { },
                "active": true
            }}
        />
        <Row style={{ marginTop: '4vh' }}>
            <Col xs={12} md={4}>
                <Input
                    type='text'
                    className='clickableLabel'
                    label='Denominazione'
                    value={denominazione}
                    onChange={(event) => { setDenominazione(event.target.value); }}
                    infoText={denominazioneHelper}
                    invalid={denominazioneError}
                />
            </Col>
            <Col xs={12} md={4}>
                <Input
                    type='text'
                    className='clickableLabel'
                    label='Codice Struttura'
                    value={codiceStruttura}
                    onChange={(event) => { setCodiceStruttura(event.target.value); }}
                />
            </Col>
            <Col xs={12} md={4}>
                <div
                    class='form-group'
                    onClick={() => {
                        setIsTipoStrutturaEnabled(true);
                        document.getElementById('select tipo struttura').focus();
                    }}
                >
                    <label
                        htmlFor='selectExampleClassic'
                        class={(isTipoStrutturaEnabled || tipoStruttura != null) ? 'active' : ''}
                        style={{ cursor: 'context-menu' }}
                    >
                        Tipo</label>
                    <Select
                        id='selectTipoStruttura'
                        inputId='select tipo struttura'
                        onBlur={() => { setIsTipoStrutturaEnabled(false); }}
                        isClearable={true}
                        placeholder=''
                        aria-label='Scegli una opzione'
                        isSearchable={false}
                        options={tipoStrutturaList}
                        onChange={(event) => { onChangeTipoStruttura(event ? event.value : null); }}
                        styles={customStylesInputSelect}
                        menuPortalTarget={document.body}
                        menuIsOpen={isTipoStrutturaEnabled}
                    />
                </div>
            </Col>
            <Col xs={12} md={6}>
                <div
                    class='form-group'
                    onClick={() => {
                        setIsTipoSpecialitaEnabled(true);
                        document.getElementById('select tipo specialita').focus();
                    }}
                >
                    <label
                        htmlFor='selectExampleClassic'
                        class={(isTipoSpecialitaEnabled || tipoSpecialita != null) ? 'active' : ''}
                        style={{ cursor: 'context-menu' }}
                    >
                        Specialità</label>
                    <Select
                        id='selectTipoSpecialita'
                        inputId='select tipo specialita'
                        onBlur={() => { setIsTipoSpecialitaEnabled(false); }}
                        isClearable={true}
                        placeholder=''
                        aria-label='Scegli una opzione'
                        isSearchable={false}
                        options={tipoSpecialitaList}
                        onChange={(event) => { onChangeTipoSpecialita(event ? event.value : null); }}
                        styles={customStylesInputSelect}
                        menuPortalTarget={document.body}
                        menuIsOpen={isTipoSpecialitaEnabled}
                    />
                </div>
            </Col>
            <Col xs={12} md={6}>
                <Input
                    type='text'
                    className='clickableLabel'
                    label='Codice Azienda'
                    value={codiceAzienda}
                    onChange={(event) => { setCodiceAzienda(event.target.value); }}
                />
            </Col>
        </Row>
        <Row className='d-flex justify-content-end'>
            <Col xs={12} xl={2}>
                <Button className="buttonPrimary"
                    id='cerca_btn'
                    color='primary'
                    block
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    CERCA
                </Button>{' '}
            </Col>
            <Tooltip
                placement='top'
                isOpen={cercaTooltip}
                target={'cerca_btn'}
                toggle={() => setCercaTooltip(!cercaTooltip)}
            >
                Cerca aziende
            </Tooltip>
        </Row>
        <LinkList>
            <LinkListItem divider />
        </LinkList>
        {
            strutturaList && strutturaList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th scope='col'>Codice</th>
                            <th scope='col'>Denominazione</th>
                            <th scope='col'>Responsabile</th>
                            <th scope='col'>Telefono Direzione</th>
                            <th scope='col'>Telefono Segreteria</th>
                            <th scope='col'>Azienda</th>
                            <th scope='col'>Presidio</th>
                            <th scope='col' class="text-center">Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {strutturaList.map((item, index) => (
                            <tr>
                                <td>{item.codStruttura}</td>
                                <td>{item.denominazione}</td>
                                <td>{item.responsabile}</td>
                                <td>{item.telefonoDirezione}</td>
                                <td>{item.telefonoSegreteria}</td>
                                <td>{item.nomeAzienda}</td>
                                <td>{item.nomePresidio}</td>
                                <td class="text-center">
                                    <Button title="Visualizza" onClick={() => {
                                        handleNavigationStrutture("detail", item);
                                    }}> <Icon icon={"it-password-visible"} /></Button>
                                    <Button title="Aggiorna" onClick={() => {
                                        handleNavigationStrutture("update", item);
                                    }}>  <Icon icon={"it-pencil"} /></Button>
                                    {isATL_OP_ADM_RESP ? <Button title="Elimina" onClick={() => {
                                        handleDeleteToggle(item);
                                    }}>  <Icon icon={"it-delete"} /></Button> : null}
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
        }
        <Row style={{ display: "flex", justifyContent: "end" }}>
            <PaginationControl
                selectedPage={pageNumber}
                numberOfPages={numberOfPages}
                changePage={setPageNumber}
            />
        </Row>
        {isOpenDelete &&
            <Modal
                isOpen={isOpenDelete}
                toggle={() => toggleModalDelete(!isOpenDelete)}
            >
                <ModalHeader toggle={() => toggleModalDelete(!isOpenDelete)} id='esempio12'>
                    Conferma
                </ModalHeader>
                <ModalBody>
                    <p>L'operazione è irreversibile. Sei sicuro di voler procedere?</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="buttonPrimary" onClick={() => toggleModalDelete(!isOpenDelete)}>
                        Annulla
                    </Button>
                    <Button className="buttonPrimary" onClick={() => confirmDeleteClick(item)}>
                        Conferma
                    </Button>
                </ModalFooter>
            </Modal>}
        {alert.state &&
            <div>
                <div className='modal-content'>
                    <Modal fade centered isOpen={alert.state}>
                        <ModalHeader>Errore</ModalHeader>
                        <ModalBody>{alert.text}</ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => {
                                setAlert({
                                    'state': false,
                                    'text': "",
                                    'type': ''
                                });
                            }} color='secondary'>Chiudi</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        }
    </Container>)
}

export default Struttura;