import { Input, LinkListItem, LinkList, Col, Toggle, Row, FormGroup, Label, Button, Table, Alert, Icon, ModalFooter, ModalBody, ModalHeader, Modal, Tooltip, Container } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import serviziService from '../service/serviziService';
import { wait } from '../common/Loading';
import '../../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import { PaginationControl } from '../custom/PaginationControl';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function ServiziPage({ isPageChanged, setIsPageChanged }) {
    const navigate = useNavigate();
    const [denominazione, setDenominazione] = useState("");
    const [denominazioneHelper, setDenominazioneHelper] = useState("");
    const [denominazioneError, setDenominazioneError] = useState(false);
    const [filterFlgVisibile, setFilterFlgVisibile] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [sortOrder, setOrder] = useState("asc");
    const [sortKey, setSortKey] = useState("nomeServizio");
    const [open, toggleModal] = useState(false);
    const [username, setUsername] = useState('');
    const [idServizi, setIdServizi] = useState(null);
    const [denominazioneForm, setDenominazioneForm] = useState("");
    const [denominazioneFormHelper, setDenominazioneFormHelper] = useState("");
    const [denominazioneFormError, setDenominazioneFormError] = useState(false);
    const [titoloModal, setTitoloModal] = useState("Inserisci Servizi");
    const [showError, setShowError] = useState(false);
    const [mexError, setMexError] = useState("");

    const [flgVisibile, setFlgVisibile] = useState(true);
    const [serviziList, setServiziList] = useState([]);
    const [codiceFiscale, setCodiceFiscale] = useState('GLTFNC93B05H501U');
    const [isOpenDelete, toggleModalDelete] = useState(false);

    const [item, setItem] = useState(null);

    const confirmDeleteClick = (item) => {
        handleDelete(item);
        toggleModalDelete(!isOpenDelete);
    }
    const [alert, setAlert] = useState({
        'state': false,
        'text': '',
        'type': ''
    });

    const [cercaTooltip, setCercaTooltip] = useState(false);
    const [inserisciTooltip, setInserisciTooltip] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        localStorage.setItem('page', 0);
    }, []);

    useEffect(() => {
        handleSubmit();
    }, [pageNumber]);

    useEffect(() => {
        handleSubmit();
    }, []);

    const handleSubmit = () => {
        clearAll();
        let count = 0;
        if (denominazione && denominazione.length < 3) {
            setDenominazioneError(true);
            setDenominazioneHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setDenominazioneError(false);
            setDenominazioneHelper("");
        }

        if (count <= 0) {
            let payload = {
                data: {
                    denominazione: denominazione,
                    flgVisibile: filterFlgVisibile,
                },
                paginationData: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortKey: sortKey,
                    sortOrder: sortOrder
                }
            };
            wait(serviziService.searchServizi(payload))
                .then((response) => {
                    setNumberOfPages(Math.ceil(response.data.paginationData.numElements / pageSize));
                    let serviziTmp = [];
                    if (response.data.data) {
                        response.data.data.map((index) => {
                            serviziTmp.push({
                                'denominazione': index.descrizione,
                                'flgVisibile': index.flgVisibile,
                                'id': index.id,
                            });
                        });
                        setServiziList(serviziTmp);
                    } else
                        setServiziList([]);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        }

    }

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    const handleNavigation = (titolo) => {
        setTitoloModal(titolo);
        toggleModal(true);
    };

    const handleDelete = (item) => {
        wait(serviziService.deleteServizi(item.id))
            .then((response) => {
                setShowError(false);
                setMexError("");
                setAlert({
                    'state': true,
                    'text': "Cancellazione avvenuta con successo",
                    'type': 'success'
                });
                handleSubmit();
                return;
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante la cancellazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    };


    const toggle = () => { clearAll(); toggleModal(!open) };

    const clearAll = () => {
        setDenominazioneFormError(false);
        setDenominazioneFormHelper("");
        setDenominazioneError(false);
        setDenominazioneHelper("");
        setShowError(false);
        setMexError("");
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
    }

    const toggleSave = () => {
        clearAll();
        let count = 0;
        if (!denominazioneForm || denominazioneForm === "") {
            setDenominazioneFormError(true);
            setDenominazioneFormHelper("Campo obbligatorio");
            count++;
        } else if (denominazioneForm.length < 3) {
            setDenominazioneFormError(true);
            setDenominazioneFormHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setDenominazioneFormError(false);
            setDenominazioneFormHelper("");
        }

        if (count <= 0) {
            let payload = {
                id: idServizi,
                descrizione: denominazioneForm,
                flgVisibile: flgVisibile
            };
            wait(serviziService.saveServizi(payload))
                .then((response) => {
                    toggleModal(!open);
                    handleSubmit();
                    return;
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        }
    };

    const openModal = (name) => {
        setUsername(name);
        toggleModal(true);
    };

    return (<Container fluid>
        <CustomBreadCrumb
            first={{
                "name": "Elenco Servizi",
                "onClick": () => { },
                "active": true
            }}
        />
        <Alert color='danger' isOpen={showError}>
            {mexError}
        </Alert>
        <Modal fade centered isOpen={open} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {titoloModal}
            </ModalHeader>
            <ModalBody>
                <div>
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Denominazione'
                        value={denominazioneForm}
                        onChange={(event) => { setDenominazioneForm(event.target.value); }}
                        infoText={denominazioneFormHelper}
                        invalid={denominazioneFormError}
                        maxLength={100}
                    />
                    <Toggle label="Visibile" disabled={false} checked={flgVisibile} onChange={(e) => setFlgVisibile(e.target.checked)} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="buttonPrimary" color='secondary' onClick={toggle}>
                    Chiudi
                </Button>
                <Button className="buttonPrimary" color='primary' onClick={toggleSave}>
                    Salva modifiche
                </Button>
            </ModalFooter>
        </Modal>
        <Col>
            <span class='bi bi-check-circle' />
            <Row style={{ marginTop: '4vh', columnGap: "1em" }}>
                <Col xs={12} md={3}  >
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Denominazione'
                        value={denominazione}
                        onChange={(event) => { setIsPageChanged(true); setDenominazione(event.target.value); }}
                        infoText={denominazioneHelper}
                        invalid={denominazioneError}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <form >
                        <fieldset>
                            <FormGroup check inline>
                                <Input name='gruppo1' type='radio' id='radio1' defaultChecked onChange={() => { setFilterFlgVisibile(null); }} />
                                <Label check htmlFor='radio1'>
                                    Tutti
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Input name='gruppo1' type='radio' id='radio2' onChange={() => { setFilterFlgVisibile(true) }} />
                                <Label check htmlFor='radio2'>
                                    Visibile
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Input name='gruppo1' type='radio' id='radio3' onChange={() => { setFilterFlgVisibile(false) }} />
                                <Label check htmlFor='radio3'>
                                    Non Visibile
                                </Label>
                            </FormGroup>
                        </fieldset>
                    </form>
                </Col>
            </Row>
            <Row className='d-flex justify-content-end'>
                <Col xs={12} xl={2}>
                    <Button className="buttonPrimary"
                        id='inserisci_btn'
                        color='primary'
                        block
                        onClick={() => {
                            setIdServizi(null);
                            setDenominazioneForm("");
                            setFlgVisibile(true);
                            handleNavigation("Inserisci Servizi");
                        }}
                    >
                        INSERISCI
                    </Button>{' '}
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={inserisciTooltip}
                    target={'inserisci_btn'}
                    toggle={() => setInserisciTooltip(!inserisciTooltip)}
                >
                    Inserisci Servizi
                </Tooltip>
                <Col xs={12} xl={2}>
                    <Button className="buttonPrimary"
                        id='cerca_btn'
                        color='primary'
                        block
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        CERCA
                    </Button>{' '}
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={cercaTooltip}
                    target={'cerca_btn'}
                    toggle={() => setCercaTooltip(!cercaTooltip)}
                >
                    Cerca Servizi
                </Tooltip>
            </Row>
            <LinkList>
                <LinkListItem divider />
            </LinkList>
            {serviziList && serviziList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th scope='col'>Denominazione</th>
                            <th scope='col'>Visibile</th>
                            <th scope='col' class="text-center">Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {serviziList.map((item, index) => (
                            <tr>
                                <td>{item.denominazione}</td>
                                <td>{item.flgVisibile ? "SI" : "NO"}</td>
                                <td class="text-center">
                                    <Button title="Aggiorna" onClick={() => {
                                        setIdServizi(item.id);
                                        setDenominazioneForm(item.denominazione);
                                        setFlgVisibile(item.flgVisibile);
                                        handleNavigation("Modifica Servizi");
                                    }}>  <Icon icon={"it-pencil"} /></Button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)}


            {alert.state &&
                <div>
                    <div className='modal-content'>
                        <Modal fade centered isOpen={alert.state}>
                            <ModalHeader>Errore</ModalHeader>
                            <ModalBody>{alert.text}</ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => {
                                    setAlert({
                                        'state': false,
                                        'text': "",
                                        'type': ''
                                    });
                                }} color='secondary'>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            }
            {isOpenDelete &&
                <Modal
                    isOpen={isOpenDelete}
                    toggle={() => toggleModalDelete(!isOpenDelete)}
                >
                    <ModalHeader toggle={() => toggleModalDelete(!isOpenDelete)} id='esempio12'>
                        Conferma
                    </ModalHeader>
                    <ModalBody>
                        <p>L'operazione è irreversibile. Sei sicuro di voler procedere?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="buttonPrimary" onClick={() => toggleModalDelete(!isOpenDelete)}>
                            Annulla
                        </Button>
                        <Button className="buttonPrimary" onClick={() => confirmDeleteClick(item)}>
                            Conferma
                        </Button>
                    </ModalFooter>
                </Modal>}
            <Row style={{ display: "flex", justifyContent: "end" }}>
                <PaginationControl
                    selectedPage={pageNumber}
                    numberOfPages={numberOfPages}
                    changePage={setPageNumber}
                />
            </Row>
        </Col></Container>
    );
}

export default ServiziPage;