import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchPresidio = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/presidi/search`, payload);
};

const deletePresidi = payload => {
    return axios.delete(`${BASE_ENDPOINT}/v1/presidi/` + payload);
};

const savePresidi = payload => {
    return axios.put(`${BASE_ENDPOINT}/v1/presidi`, payload);
};

const insertPresidi = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/presidi/insert`, payload);
};

const updatePresidi = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/presidi/update`, payload);
};

const getPresidi = payload => {
    return axios.get(`${BASE_ENDPOINT}/v1/presidi?idPresidio=` + payload);
};

export default {
    savePresidi,
    deletePresidi,
    searchPresidio,
    getPresidi,
    insertPresidi,
    updatePresidi
};