import {
    Accordion, Alert, AccordionHeader, Modal, ModalHeader, ModalBody, ModalFooter, AccordionBody, Col, Container, Row, Input, Button, Icon, UncontrolledTooltip, Select
} from 'design-react-kit';
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { EMAIL_REGEX, PHONE_REGEX } from "../constants/regexConstants";
import '../../App.css';
import strutturaService from '../service/strutturaService';
import { wait } from '../common/Loading';
import tipologiaService from '../service/tipologiaService';
import { isEmpty } from '../common/stringUtils';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';
import { CustomDateField } from '../custom/CustomDateField';

function GestioneStruttura({ isPageChanged, setIsPageChanged }) {

    const [comeFromTypeAspAzienda, setComeFromTypeAspAzienda] = useState(false);
    const [comeFromStruttura, setComeFromStruttura] = useState(false);
    const [comeFromAzienda, setComeFromAzienda] = useState(false);
    const [comeFromPresidio, setComeFromPresidio] = useState(false);
    const [idAzienda, setIdAzienda] = useState(null);
    const [idPresidio, setIdPresidio] = useState(null);
    const [idStruttura, setIdStruttura] = useState(null);
    const [dettaglioPage, setDettaglioPage] = useState(false);
    const [genericError, setGenericError] = useState(false);
    const [genericErrorMessage, setGenericErrorMessage] = useState("");
    const location = useLocation();

    const navigate = useNavigate();
    const [collapseOpen1, setCollapseOpen1] = useState(true);
    const [collapseOpen2, setCollapseOpen2] = useState(true);
    const [collapseOpen3, setCollapseOpen3] = useState(true);
    const [collapseOpen4, setCollapseOpen4] = useState(true);

    const [anagrafica, setAnagrafica] = useState({
        "denominazioneAzienda": null,
        "denominazionePresidio": null,
        "indirizzo": null,
        "denominazione": null,
        "specializzazione": {
            "descrizione": null,
            "idValue": null,
        },
        "tipoStruttura": {
            "descrizione": null,
            "idValue": null,
        }
    });
    const [isSpecializzazioneSelectEnabled, setIsSpecializzazioneSelectEnabled] = useState(false);
    const [isTipoStrutturaSelectEnabled, setIsTipoStrutturaSelectEnabled] = useState(false);

    const [strutturaComboList, setStrutturaComboList] = useState([]);
    const [specializzazioneComboList, setSpecializzazioneComboList] = useState([]);

    const [datiDiContatto, setDatiDiContatto] = useState({
        "responsabile": null,
        "mail": null,
        "telefonoDirezione": null,
        "telefonoSegreteriaFax": null,
        "email": null
    });

    const [serviziList, setServiziList] = useState([]);
    const [prestazioniList, setPrestazioniList] = useState([]);
    const [keyForServizi, setKeyForServizi] = useState({ "key": 0 });
    const [keyForPrestazioni, setKeyForPrestazioni] = useState({ "key": 0 });

    const [indirizzoHelper, setIndirizzoHelper] = useState("Campo obbligatorio");
    const [indirizzoError, setIndirizzoError] = useState(true);
    const [showServiziRequirements, setShowServiziRequirements] = useState(false);
    const [showPrestazioniRequirements, setShowPrestazioniRequirements] = useState(false);

    const [isSalvaClicked, setIsSalvaClicked] = useState(false);

    const [denominazioneHelper, setDenominazioneHelper] = useState("Campo obbligatorio");
    const [denominazioneError, setDenominazioneError] = useState(true);
    const [tipoSpecialitaError, setTipoSpecialitaError] = useState(true);
    const [tipoSpecialitaHelper, setTipoSpecialitaHelper] = useState("Campo obbligatorio");
    const [tipoStrutturaError, setTipoStrutturaError] = useState(true);
    const [tipoStrutturaHelper, setTipoStrutturaHelper] = useState("Campo obbligatorio");

    const [codiceStruttura, setCodiceStruttura] = useState("");
    const [codiceStrutturaHelper, setCodiceStrutturaHelper] = useState("Campo obbligatorio");
    const [codiceStrutturaError, setCodiceStrutturaError] = useState(true);

    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidTelefonoDirezione, setIsValidTelefonoDirezione] = useState(true);
    const [isValidTelefonoSegreteriaFax, setIsValidTelefonoSegreteriaFax] = useState(true);
    const [serviziComboList, setServiziComboList] = useState([]);

    const [fonte, setFonte] = useState(null);
    const [fonteError, setFonteError] = useState(true);
    const [fonteHelper, setFonteHelper] = useState("Campo obbligatorio");
    const [fonteList, setFonteList] = useState([]);
    const [isFonteEnabled, setIsFonteEnabled] = useState(false);
    const[isUpdateDetail, setIsUpdateDetail] = useState(false);

    const formatDate = (date) => {
        return (date.getFullYear() + '-' + ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + ((date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate())))
    }

    const [datRifFonte, setDatRifFonte] = useState(null);

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    const [prestazioniComboList, setPrestazioniComboList] = useState([]);

    const requiredFielldLabel = 'Il campo è obbligatiorio';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!location || !location.state || (!location.state.idAzienda && !location.state.idPresidio))
            navigate("/reparti");
        else {
            setComeFromTypeAspAzienda(location.state.isTypeAspAzienda);
            setComeFromStruttura(location.state.fromStruttura);
            setComeFromAzienda(location.state.fromAzienda);
            setComeFromPresidio(location.state.fromPresidio);
            setIdAzienda(location.state.idAzienda);
            setIdPresidio(location.state.idPresidio);
            setIdStruttura(location.state.id);
            console.log(location.state.indirizzo);
            handleChangeAnagrafica("indirizzo", location.state.indirizzo);
            setIndirizzoError(false);
        }
    }, []);

    useEffect(() => {
        wait(strutturaService.getServiziOptions({
            data: {
                "excludeServizi": [...(serviziList.filter((data) => data.idValue != null).map((data, index) => { return data.idValue; }))]
            }
        })).then(
            (resp) => {
                if (resp.data.data) {
                    let comboListServiziToBeSet = [];
                    resp.data.data.forEach(element => {
                        comboListServiziToBeSet.push({
                            "label": element.descrizione,
                            "value": element.id
                        });
                    });
                    setServiziComboList(comboListServiziToBeSet);
                } else {
                    setServiziComboList([]);
                }
            }).catch((err) => {
                console.log(err);

            })
    }, [serviziList]);

    useEffect(() => {
        wait(strutturaService.getPrestazioniOptions({
            data: {
                "excludePrestazioni": [...(prestazioniList.filter((data) => data.idValue != null).map((data, index) => { return data.idValue; }))]
            }
        })).then(
            (resp) => {
                if (resp.data.data) {
                    let comboListPrestazioniToBeSet = [];
                    resp.data.data.forEach(element => {
                        comboListPrestazioniToBeSet.push({
                            "label": element.descrizione,
                            "value": element.id
                        });
                    });
                    setPrestazioniComboList(comboListPrestazioniToBeSet);
                } else {
                    setPrestazioniComboList([]);
                }
            }).catch((err) => {
                console.log(err);

            })
    }, [prestazioniList]);

    useEffect(() => {
        wait(strutturaService.getSpecializzazioneOptions()).then(
            (resp) => {
                if (resp.data.data) {
                    let comboListSpecializzazioneToBeSet = [];
                    resp.data.data.forEach(element => {
                        comboListSpecializzazioneToBeSet.push({
                            "label": element.descrizione,
                            "value": element.id
                        });
                    });
                    setSpecializzazioneComboList(comboListSpecializzazioneToBeSet);
                } else {
                    setSpecializzazioneComboList([]);
                }
            }).catch((err) => {
                console.log(err);

            })
        wait(tipologiaService.getTipoStruttura())
            .then((response) => {
                let tipoStrutturaTmp = [];
                response.data.data.map((index) => {
                    tipoStrutturaTmp.push({
                        'label': index.descrizione,
                        'value': index.id
                    });
                });
                setStrutturaComboList(tipoStrutturaTmp);
            });
    }, []);

    useEffect(() => {
        wait(tipologiaService.getFonte())
            .then((response) => {
                let fonteListTmp = [];
                response.data.data.map((index) => {
                    fonteListTmp.push({
                        'label': index.descrizione + " - " + index.codice,
                        'value': index.id
                    });
                });
                setFonteList(fonteListTmp);
            });
    }, []);

    const buildStruttura = (data) => {
        setIdStruttura(data.idStruttura);
        console.log(data)
        setAnagrafica({
            "denominazioneAzienda": data.azienda ? data.azienda.nomeAzienda : data.presidio.azienda ? data.presidio.azienda.nomeAzienda : null,
            "denominazionePresidio": data.presidio ? data.presidio.nomePresidio : null,
            "indirizzo": (data.indirizzo !== null && data.indirizzo !== "") ? data.indirizzo : data.azienda ? data.azienda.indirizzo : data.presidio ? data.presidio.indirizzo : null,
            "denominazione": data.nomeStruttura,
            "specializzazione": data.tipoSpecialita ? {
                "descrizione": data.tipoSpecialita.descrizione,
                "idValue": data.tipoSpecialita.id
            } :
                {
                    "descrizione": null,
                    "idValue": null
                },
            "tipoStruttura": data.tipoStruttura ? {
                "descrizione": data.tipoStruttura.descrizione,
                "idValue": data.tipoStruttura.id
            } :
                {
                    "descrizione": null,
                    "idValue": null
                }
        });
        setDenominazioneError(data.nomeStruttura === null);
        setDenominazioneHelper("Campo obbligatorio");
        setTipoSpecialitaError(data.tipoSpecialita === null);
        setTipoSpecialitaHelper("Campo obbligatorio");
        setTipoStrutturaError(data.tipoStruttura === null);
        setTipoStrutturaHelper("Campo obbligatorio");
        setIndirizzoError(data.indirizzo === null && data.indirizzo === "");
        setIndirizzoHelper("Campo obbligatorio");

        setFonte(data.fonte ? { 'label': data.fonte.descrizione + " - " + data.fonte.codice, 'value': data.fonte.id } : null);
        setFonteError(data.fonte === null);
        setFonteHelper("Campo obbligatorio");
        setCodiceStruttura(data.codStruttura);
        setCodiceStrutturaError(data.codStruttura === null);
        setCodiceStrutturaHelper("Campo obbligatorio");
        setDatRifFonte(data.datRifFonte ? formatDate(new Date(data.datRifFonte)) : null);

        setDatiDiContatto({
            "responsabile": data.responsabile,
            "mail": data.mail,
            "telefonoDirezione": data.telefonoDirezione,
            "telefonoSegreteriaFax": data.telefonoSegreteria,
            "email": data.azienda ? data.azienda.mail : null,
        });
        if (data.servizi.length > 0) {
            let serviziToBeSet = [];
            let key = 0;
            data.servizi.forEach(element => {
                serviziToBeSet.push({
                    "denominazione": element.descrizione,
                    "idValue": element.id,
                    "isDenominazioneEnabled": false,
                    "key": key++,
                    "isChanged": true,
                });
            });
            setServiziList(serviziToBeSet);

            setKeyForServizi({ "key": key });
        } else {
            setServiziList([{
                "denominazione": null,
                "idValue": null,
                "isDenominazioneEnabled": false,
                "key": 0,
                "isChanged": false,
            }
            ]);
        }
        if (data.prestazioni.length > 0) {
            let prestazioniToBeSet = [];
            let key = 0;
            data.prestazioni.forEach(element => {
                prestazioniToBeSet.push({
                    "denominazione": element.descrizione,
                    "idValue": element.id,
                    "isDenominazioneEnabled": false,
                    "key": key++,
                    "isChanged": true,
                });
            });
            setPrestazioniList(prestazioniToBeSet);

            setKeyForPrestazioni({ "key": key });

        } else {
            setPrestazioniList([{
                "denominazione": null,
                "idValue": null,
                "isDenominazioneEnabled": false,
                "key": 0,
                "isChanged": false,
            }
            ]);
        }
    };

    useEffect(() => {
        if (location.state && (location.state.pageStatus === "update" || location.state.pageStatus === "detail")) {
            setIsUpdateDetail(true);
            if (location.state.pageStatus === "detail") { setDettaglioPage(true); }
            wait(strutturaService.getStrutture(idStruttura ? idStruttura : location.state.id)).then((response) => {
                if (response && response.data && response.data.data) {
                    setComeFromTypeAspAzienda((response.data.data.azienda
                        && response.data.data.azienda.tipoAzienda.id === 1)
                        || (response.data.data.presidio
                            && response.data.data.presidio.azienda
                            && response.data.data.presidio.azienda.tipoAzienda.id === 1));
                    buildStruttura(response.data.data);
                    setGenericError(false);
                }
            }).catch((err) => {
                console.log("error");
            });
        } else {
            setServiziList([{
                "denominazione": null,
                "idValue": null,
                "isDenominazioneEnabled": false,
                "key": 0,
                "isChanged": false,
            }
            ]);
            setPrestazioniList([{
                "denominazione": null,
                "idValue": null,
                "isDenominazioneEnabled": false,
                "key": 0,
                "isChanged": false,
            }
            ]);
            if(location.state && location.state.pageStatus === "insert"){
                setAnagrafica({
                    "denominazioneAzienda": location.state.nomeAzienda ? location.state.nomeAzienda : null,
                    "denominazionePresidio": location.state.nomePresidio ? location.state.nomePresidio : null,
                    "indirizzo": location.state.indirizzo ? location.state.indirizzo : null,
                    "denominazione": null,
                    "specializzazione": {
                        "descrizione": null,
                        "idValue": null,
                    },
                    "tipoStruttura": {
                        "descrizione": null,
                        "idValue": null,
                    }
                }
                )
            }
        }
    }, []);

    const emailValidation = (email) => {
        setIsValidEmail(!email || EMAIL_REGEX.test(email));
    }

    const phoneValidation = (phone, setIsValid) => {
        setIsValid(!phone || PHONE_REGEX.test(phone));
    }

    const handleChangeAnagrafica = (inputField, event) => {
        setIsPageChanged(true);
        setAnagrafica({
            "denominazioneAzienda": (inputField === "denominazioneAzienda") ? event.target.value : anagrafica.denominazioneAzienda,
            "denominazionePresidio": (inputField === "denominazionePresidio") ? event.target.value : anagrafica.denominazionePresidio,
            "indirizzo": inputField === "indirizzo" ? event : anagrafica.indirizzo,
            "denominazione": inputField === "denominazione" ? event.target.value : anagrafica.denominazione,
            "specializzazione": inputField === "specializzazione" ? { "descrizione": event === null ? null : event.label, "idValue": event === null ? null : event.value } : anagrafica.specializzazione,
            "tipoStruttura": inputField === "tipoStruttura" ? { "descrizione": event === null ? null : event.label, "idValue": event === null ? null : event.value } : anagrafica.tipoStruttura
        });
        if (inputField === "tipoStruttura" || inputField === "specializzazione") {
            setIsSpecializzazioneSelectEnabled(false);
            setIsTipoStrutturaSelectEnabled(false);
        }
        if (inputField === "denominazione") {
            if (!event.target.value) {
                setDenominazioneError(true);
                setDenominazioneHelper("Campo obbligatorio");
            } else if (event.target.value < 3) {
                setDenominazioneError(true);
                setDenominazioneHelper("Inserire almeno tre caratteri");
            }
            else {
                setDenominazioneError(false);
                setDenominazioneHelper("");
            }
        }
        if (inputField === "tipoStruttura") {
            if (!event) {
                setTipoStrutturaError(true);
                setTipoStrutturaHelper("Campo obbligatorio");
            } else {
                setTipoStrutturaError(false);
                setTipoStrutturaHelper("");
            }
        }
        if (inputField === "specializzazione") {
            if (!event) {
                setTipoSpecialitaError(true);
                setTipoSpecialitaHelper("Campo obbligatorio");
            } else {
                setTipoSpecialitaError(false);
                setTipoSpecialitaHelper("");
            }
        }
    };

    const handleChangeDatiDiContatto = (inputField, event) => {
        setIsPageChanged(true);
        setDatiDiContatto({
            "responsabile": (inputField === "responsabile") ? event.target.value : datiDiContatto.responsabile,
            "mail": (inputField === "mail") ? event.target.value : datiDiContatto.mail,
            "telefonoDirezione": (inputField === "telefonoDirezione") ? event.target.value : datiDiContatto.telefonoDirezione,
            "telefonoSegreteriaFax": inputField === "telefonoSegreteriaFax" ? event.target.value : datiDiContatto.telefonoSegreteriaFax,
            "email": inputField === "email" ? event.target.value : datiDiContatto.email
        });
        if (inputField === "mail") {
            emailValidation(event.target.value);
        }
        if (inputField === "telefonoDirezione") {
            phoneValidation(event.target.value, setIsValidTelefonoDirezione);
        }
        if (inputField === "telefonoSegreteriaFax") {
            phoneValidation(event.target.value, setIsValidTelefonoSegreteriaFax);
        }
    };

    const handleServiziChange = (inputField, event, index) => {
        console.log("entrato")
        setIsPageChanged(true);
        if (event == null) {
            serviziList[index].denominazione = null;
            serviziList[index].idValue = null;
            serviziList[index].isChanged = false;
        } else {
            serviziList[index].denominazione = event.label;
            serviziList[index].idValue = event.value;
            serviziList[index].isChanged = true;
        }
        setServiziList([...serviziList]);
    }

    const handlePrestazioniChange = (inputField, event, index) => {
        setIsPageChanged(true);
        if (event == null) {
            prestazioniList[index].denominazione = null;
            prestazioniList[index].idValue = null;
            prestazioniList[index].isChanged = false;
        } else {
            prestazioniList[index].denominazione = event.value;
            prestazioniList[index].idValue = event.value;
            prestazioniList[index].isChanged = true;
        }
        setPrestazioniList([...prestazioniList]);
    }

    const setIsDenominazioneForServiziEnabled = (index, toEnabled) => {
        serviziList[index].isDenominazioneEnabled = toEnabled;

        setServiziList([...serviziList]);
    }


    const setIsDenominazioneForPrestazioniEnabled = (index, toEnabled) => {
        prestazioniList[index].isDenominazioneEnabled = toEnabled;

        setPrestazioniList([...prestazioniList]);
    }

    const addToServiziList = () => {
        if (areDenominazioneForServiziListPopulated()) {
            setShowServiziRequirements(false);
            setKeyForServizi({ "key": (++keyForServizi.key) });
            setServiziList([...serviziList,
            {
                "denominazione": null,
                "idValue": null,
                "isDenominazioneEnabled": false,
                "key": keyForServizi.key,
                "isChanged": false,
            }]);
        } else {
            setShowServiziRequirements(true);
        }
    }

    // Helper value to make the values for sostituti null when clicking and there is only one row
    const [clearDataForServizi, setClearDataForServizi] = useState(false);
    const removeFromServiziList = (index) => {
        if (serviziList.length > 1) {
            serviziList.splice(index, 1);
            setServiziList([...serviziList]);
        } else {
            setServiziList([{
                "denominazione": null,
                "idValue": null,
                "isDenominazioneEnabled": false,
                "key": 0,
                "isChanged": false,
            }
            ]);
            setShowServiziRequirements(false);
            setClearDataForServizi(!clearDataForServizi);
        }
    }

    const addToPrestazioniList = () => {
        if (areDenominazioneForPrestazioniListPopulated()) {
            setShowPrestazioniRequirements(false);
            setKeyForPrestazioni({ "key": (++keyForPrestazioni.key) });
            setPrestazioniList([...prestazioniList,
            {
                "denominazione": null,
                "idValue": null,
                "isDenominazioneEnabled": false,
                "key": keyForPrestazioni.key,
                "isChanged": false,
            }]);
        } else {
            setShowPrestazioniRequirements(true);
        }
    }

    // Helper value to make the values for sostituti null when clicking and there is only one row
    const [clearDataForPrestazioni, setClearDataForPrestazioni] = useState(false);
    const removeFromPrestazioniList = (index) => {
        if (prestazioniList.length > 1) {
            prestazioniList.splice(index, 1);
            setPrestazioniList([...prestazioniList]);
        } else {
            setPrestazioniList([{
                "denominazione": null,
                "idValue": null,
                "isDenominazioneEnabled": false,
                "key": 0,
                "isChanged": false,
            }
            ]);
            setShowPrestazioniRequirements(false);
            setClearDataForPrestazioni(!clearDataForPrestazioni);
        }
    }

    const areDenominazioneForServiziListPopulated = () => {
        for (let i = 0; i < serviziList.length; ++i) {
            if (!serviziList[i].isChanged) {
                return false;
            }
        }
        return true;
    }

    const areDenominazioneForPrestazioniListPopulated = () => {
        for (let i = 0; i < prestazioniList.length; ++i) {
            if (!prestazioniList[i].isChanged) {
                return false;
            }
        }
        return true;
    }

    const salvaClick = () => {
        console.log(anagrafica);
        if (isValidEmail && isValidTelefonoDirezione && isValidTelefonoSegreteriaFax) {
            if (tipoSpecialitaError || denominazioneError || codiceStrutturaError || tipoStrutturaError || indirizzoError || fonteError || !datRifFonte) {
                setIsSalvaClicked(true);
            } else {
                let payload = {
                    "idStruttura": location && location.state ? location.state.id : idStruttura,
                    "nomeStruttura": anagrafica.denominazione,
                    "responsabile": datiDiContatto.responsabile,
                    "mail": datiDiContatto.mail ? datiDiContatto.mail : null,
                    "indirizzo": anagrafica.indirizzo ? anagrafica.indirizzo : null,
                    "telefonoSegreteria": datiDiContatto.telefonoSegreteriaFax,
                    "telefonoDirezione": datiDiContatto.telefonoDirezione,
                    "servizi": serviziList.map(element => { return { "descrizione": element.denominazione, "id": element.idValue } }),
                    "prestazioni": prestazioniList.map(element => { return { "descrizione": element.denominazione, "id": element.idValue } }),
                    "tipoSpecialita": { "descrizione": anagrafica.specializzazione.descrizione, "id": anagrafica.specializzazione.idValue },
                    "tipoStruttura": { "descrizione": anagrafica.tipoStruttura.descrizione, "id": anagrafica.tipoStruttura.idValue },
                    "presidio": idPresidio ? { idPresidio: idPresidio } : null,
                    "azienda": idAzienda ? { idAzienda: idAzienda } : null,
                    "flgVisibile": true,
                    "codStruttura": codiceStruttura,
                    "fonte": { id: fonte.value },
                    "datRifFonte": new Date(datRifFonte)

                };
                if(payload.idStruttura) {
                    wait(strutturaService.updateStrutture(payload))
                    .then((result) => {
                        navigate(-1);
                        setIsPageChanged(false);                        
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {
                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
                } else {
                wait(strutturaService.insertStrutture(payload))
                    .then((result) => {
                        navigate(-1);
                        setIsPageChanged(false);                        
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {
                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
                }
            }
        } else {
            // invalid data
        }
    }

    const onChangeFonte = (event) => {
        setIsFonteEnabled(false);
        setFonte(event);
    }

    const onChangeDatRifFonte = (event) => {
        console.log(datRifFonte);
        setDatRifFonte((event.target.value === '') ? null : event.target.value);
    }

    const [isOpenSalva, toggleModalSalva] = useState(false);
    const [isOpenIndietro, toggleModalIndietro] = useState(false);
    const [indietroTo, setIndietroTo] = useState(-1);

    const confirmSalvaClick = () => {
        salvaClick();
        toggleModalSalva(false);
    }

    return (
        <Container fluid>
            {!comeFromStruttura &&
                <CustomBreadCrumb
                    key={comeFromAzienda}
                    first={{
                        "name": comeFromAzienda ? "Elenco Aziende" : "Elenco Presidi",
                        "onClick": () => {
                            setIndietroTo((comeFromAzienda && !comeFromPresidio) ||
                                (!comeFromAzienda && comeFromPresidio) ? -2 : -3);
                            if (!dettaglioPage) {
                                if (!isPageChanged) {
                                    navigate((comeFromAzienda && !comeFromPresidio) ||
                                        (!comeFromAzienda && comeFromPresidio) ? -2 : -3);
                                } else {
                                    toggleModalIndietro(!isOpenIndietro);
                                    if (isOpenIndietro) {
                                        navigate((comeFromAzienda && !comeFromPresidio) ||
                                            (!comeFromAzienda && comeFromPresidio) ? -2 : -3);
                                    }
                                }
                            } else {
                                navigate((comeFromAzienda && !comeFromPresidio) ||
                                    (!comeFromAzienda && comeFromPresidio) ? -2 : -3);
                            }
                        },
                        "active": false
                    }}
                    second={{
                        "name": comeFromAzienda ? "Aziende" : "Presidio",
                        "onClick": () => {
                            setIndietroTo((comeFromAzienda && !comeFromPresidio) ||
                                (!comeFromAzienda && comeFromPresidio) ? -1 : -2);
                            if (!dettaglioPage) {
                                if (!isPageChanged) {
                                    navigate((comeFromAzienda && !comeFromPresidio) ||
                                        (!comeFromAzienda && comeFromPresidio) ? -1 : -2);
                                } else {
                                    toggleModalIndietro(!isOpenIndietro);
                                    if (isOpenIndietro) {
                                        navigate((comeFromAzienda && !comeFromPresidio) ||
                                            (!comeFromAzienda && comeFromPresidio) ? -1 : -2);
                                    }
                                }
                            } else {
                                navigate((comeFromAzienda && !comeFromPresidio) ||
                                    (!comeFromAzienda && comeFromPresidio) ? -1 : -2);
                            }
                        },
                        "active": false
                    }}
                    third={{
                        "name": (comeFromAzienda && !comeFromPresidio) ||
                            (!comeFromAzienda && comeFromPresidio) ? (comeFromTypeAspAzienda ? "Struttura" : "Reparto") : "Presidio",
                        "onClick": comeFromAzienda && !comeFromPresidio ? () => { } :
                            () => {
                                setIndietroTo(-1);
                                if (!dettaglioPage) {
                                    if (!isPageChanged) {
                                        navigate(-1);
                                    } else {
                                        toggleModalIndietro(!isOpenIndietro);
                                        if (isOpenIndietro) {
                                            navigate(-1);
                                        }
                                    }
                                } else {
                                    navigate(-1);
                                }
                            },
                        "active": (comeFromAzienda && !comeFromPresidio) ||
                            (!comeFromAzienda && comeFromPresidio)
                    }}
                    fourth={(comeFromAzienda && !comeFromPresidio) ||
                        (!comeFromAzienda && comeFromPresidio) ? null :
                        {
                            "name": "Strutture",
                            "onClick": () => { },
                            "active": true
                        }}
                />}
            {comeFromStruttura && <CustomBreadCrumb
                first={{
                    "name": "Elenco Reparti",
                    "onClick": () => { navigate(-1) },
                    "active": false
                }}
                second={{
                    "name": (comeFromTypeAspAzienda ? "Struttura" : "Reparto"),
                    "onClick": () => { },
                    "active": true
                }}
            />}
            <Accordion style={{ marginTop: "0px" }}>
                <AccordionHeader
                    className="d-none d-lg-block accordionButton"
                    active={collapseOpen1}
                    onToggle={() => setCollapseOpen1(!collapseOpen1)}
                >
                    Anagrafica {comeFromTypeAspAzienda ? "Struttura" : "Reparto"}
                </AccordionHeader>
                <AccordionBody active={collapseOpen1}>
                    <Row style={{ marginTop: "1em", marginBottom: "-1.5em" }}>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                type='text'
                                label='Denominazione Azienda'
                                disabled={true}
                                value={anagrafica.denominazioneAzienda}
                                onChange={(event) => { handleChangeAnagrafica("denominazioneAzienda", event); }}
                                className='clickableLabel' />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                type='text'
                                label='Denominazione Presidio'
                                disabled={true}
                                value={anagrafica.denominazionePresidio}
                                onChange={(event) => { handleChangeAnagrafica("denominazionePresidio", event); }}
                                className='clickableLabel' />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                type='text'
                                label='Denominazione*'
                                disabled={dettaglioPage}
                                value={anagrafica.denominazione}
                                onChange={(event) => { handleChangeAnagrafica("denominazione", event); }}
                                infoText={isSalvaClicked && denominazioneError && denominazioneHelper}
                                invalid={isSalvaClicked && denominazioneError}
                                className='clickableLabel'
                                maxLength={100}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                type='text'
                                className='clickableLabel'
                                label='Codice Struttura*'
                                value={codiceStruttura}
                                onChange={(event) => {
                                    setIsPageChanged(true);
                                    setCodiceStruttura(event.target.value);
                                    if (!event.target.value) {
                                        setCodiceStrutturaError(true);
                                        setCodiceStrutturaHelper("Campo obbligatorio");
                                    }
                                    else {
                                        setCodiceStrutturaError(false);
                                        setCodiceStrutturaHelper("");
                                    }
                                }}
                                infoText={isSalvaClicked && codiceStrutturaError && codiceStrutturaHelper}
                                invalid={isSalvaClicked && codiceStrutturaError}
                                readOnly={isUpdateDetail}
                                maxLength={100}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <div
                                class='form-group'
                                onClick={() => {
                                    if (location && location.state && location.state.pageStatus === "detail")
                                        return
                                    if (!dettaglioPage) {
                                        setIsSpecializzazioneSelectEnabled(true);
                                        setIsTipoStrutturaSelectEnabled(false);
                                        document.getElementById('select specializzazione').focus();
                                    }
                                }}
                            >
                                <label
                                    htmlFor='selectExampleClassic'
                                    class={(isSpecializzazioneSelectEnabled || anagrafica.specializzazione.idValue != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                    Specialità*
                                </label>
                                <Select
                                    key={anagrafica.specializzazione.idValue}
                                    id='selectExampleReset'
                                    inputId={'select specializzazione'}
                                    onBlur={() => { setIsSpecializzazioneSelectEnabled(false); }}
                                    isDisabled={dettaglioPage}
                                    isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}

                                    defaultValue={anagrafica.specializzazione.idValue && { label: anagrafica.specializzazione.descrizione, value: anagrafica.specializzazione.idValue }}
                                    placeholder=''
                                    aria-label='Scegli una opzione'
                                    isSearchable={false}
                                    options={specializzazioneComboList}
                                    onChange={(event) => { handleChangeAnagrafica('specializzazione', event) }}
                                    // styles={customStylesInputSelect}
                                    menuPortalTarget={document.body}
                                    menuIsOpen={isSpecializzazioneSelectEnabled}
                                />
                            </div>
                            {(isSalvaClicked && tipoSpecialitaError) && <div style={{ marginTop: "-3em" }}>
                                <label style={{ color: "red", fontSize: "0.70em" }}>{tipoSpecialitaHelper}</label>
                            </div>}
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <div
                                class='form-group'
                                onClick={() => {
                                    if (location && location.state && location.state.pageStatus === "detail")
                                        return
                                    if (!dettaglioPage) {
                                        setIsTipoStrutturaSelectEnabled(true);
                                        setIsSpecializzazioneSelectEnabled(false);
                                        document.getElementById('select tipo struttura').focus();
                                    }
                                }}
                            >
                                <label
                                    htmlFor='selectExampleClassic'
                                    class={(isTipoStrutturaSelectEnabled || anagrafica.tipoStruttura.idValue != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                    Tipo {comeFromTypeAspAzienda ? "Struttura" : "Reparto"}*
                                </label>
                                <Select
                                    key={anagrafica.tipoStruttura.idValue}
                                    id='selectExampleReset'
                                    inputId={'select tipo struttura'}
                                    onBlur={() => { setIsTipoStrutturaSelectEnabled(false); }}
                                    isDisabled={dettaglioPage}
                                    isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}

                                    defaultValue={anagrafica.tipoStruttura.idValue && { label: anagrafica.tipoStruttura.descrizione, value: anagrafica.tipoStruttura.idValue }}
                                    placeholder=''
                                    aria-label='Scegli una opzione'
                                    isSearchable={false}
                                    options={strutturaComboList}
                                    onChange={(event) => { handleChangeAnagrafica('tipoStruttura', event) }}
                                    // styles={customStylesInputSelect}
                                    menuPortalTarget={document.body}
                                    menuIsOpen={isTipoStrutturaSelectEnabled}
                                />
                            </div>
                            {(isSalvaClicked && tipoStrutturaError) && <div style={{ marginTop: "-3em" }}>
                                <label style={{ color: "red", fontSize: "0.70em" }}>{tipoStrutturaHelper}</label>
                            </div>}
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                type='text'
                                label='Indirizzo*'
                                disabled={dettaglioPage}
                                value={anagrafica.indirizzo}
                                onChange={(event) => {
                                    setIsPageChanged(true);
                                    handleChangeAnagrafica("indirizzo", event.target.value);
                                    if (!event.target.value) {
                                        setIndirizzoError(true);
                                        setIndirizzoHelper("Campo Indirizzo obbligatori");
                                    } else {
                                        setIndirizzoError(false);
                                        setIndirizzoHelper("");
                                    }
                                }}
                                infoText={isSalvaClicked && indirizzoError && indirizzoHelper}
                                invalid={isSalvaClicked && indirizzoError}
                                maxLength={100}
                                className='clickableLabel' />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <div
                                class='form-group'
                                onClick={() => {
                                    if (location && location.state && location.state.pageStatus === "detail")
                                        return
                                    setIsFonteEnabled(true);
                                    document.getElementById('select fonte').focus();
                                }}
                            >
                                <label
                                    htmlFor='selectExampleClassic'
                                    class={(isFonteEnabled || fonte != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                    Fonte*</label>
                                <Select
                                    id='selectTipoFonte'
                                    inputId='select fonte'
                                    onBlur={() => { setIsFonteEnabled(false); }}
                                    isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}
                                    placeholder=''
                                    aria-label='Scegli una opzione'
                                    isSearchable={false}
                                    options={fonteList}
                                    onChange={(event) => {
                                        setIsPageChanged(true);
                                        onChangeFonte(event ? event : null);
                                        if (!event) {
                                            setFonteError(true);
                                            setFonteHelper("Campo obbligatorio");
                                        } else {
                                            setFonteError(false);
                                            setFonteHelper("");
                                        }
                                    }}
                                    value={fonte}
                                    styles={customStylesInputSelect}
                                    menuPortalTarget={document.body}
                                    menuIsOpen={isFonteEnabled}
                                    infoText={fonteHelper}
                                    invalid={fonteError}
                                />
                            </div>
                            {(isSalvaClicked && fonteError) && <div style={{ marginTop: "-3em" }}>
                                <label style={{ color: "red", fontSize: "0.70em" }}>{fonteHelper}</label>
                            </div>}
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <CustomDateField
                                value={datRifFonte}
                                placeholder='Data Riferimento Fonte'
                                disabled={location && location.state && location.state.pageStatus === "detail" ? true : false}
                                onChange={(event) => { onChangeDatRifFonte(event); }}
                            />
                            {(isSalvaClicked && !datRifFonte &&
                                <label style={{ color: "red", fontSize: "0.70em" }}>Campo obbligatorio</label>)}
                        </Col>
                    </Row>
                </AccordionBody>

                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen2}
                    onToggle={() => setCollapseOpen2(!collapseOpen2)}
                >
                    Dati di contatto
                </AccordionHeader>
                <AccordionBody active={collapseOpen2}>
                    <Row style={{ justifyContent: "space-around", marginTop: "1em", marginBottom: "-2.5em" }}>
                        <Col xs={12} md={6} lg={3} >
                            <Input
                                type='text'
                                label='Responsabile'
                                disabled={dettaglioPage}
                                value={datiDiContatto.responsabile}
                                onChange={(event) => { handleChangeDatiDiContatto("responsabile", event); }}
                                className='clickableLabel'
                                maxLength={50}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={3} >
                            <Input
                                type='text'
                                label='Telefono Direzione'
                                value={datiDiContatto.telefonoDirezione}
                                disabled={dettaglioPage}
                                infoText={isValidTelefonoDirezione ? false : "Telefono non valido"}
                                invalid={!isValidTelefonoDirezione}
                                onChange={(event) => { handleChangeDatiDiContatto("telefonoDirezione", event); }}
                                className='clickableLabel' />
                        </Col>
                        <Col xs={12} md={6} lg={3} >
                            <Input
                                type='text'
                                label='Telefono Segreteria/ Fax'
                                infoText={isValidTelefonoSegreteriaFax ? false : "Telefono non valido"}
                                invalid={!isValidTelefonoSegreteriaFax}
                                disabled={dettaglioPage}
                                value={datiDiContatto.telefonoSegreteriaFax}
                                onChange={(event) => { handleChangeDatiDiContatto("telefonoSegreteriaFax", event); }}
                                className='clickableLabel' />
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Input
                                type='text'
                                label='Email'
                                disabled={dettaglioPage}
                                value={datiDiContatto.mail}
                                onChange={(event) => {
                                    setIsPageChanged(true);
                                    handleChangeDatiDiContatto("mail", event);
                                }}
                                className='clickableLabel'
                                infoText={isValidEmail ? false : "Email non valido"}
                                invalid={!isValidEmail}
                                readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            />
                        </Col>
                    </Row>
                </AccordionBody>

                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen3}
                    onToggle={() => setCollapseOpen3(!collapseOpen3)}
                >
                    Servizi
                </AccordionHeader>
                <AccordionBody active={collapseOpen3}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={8}>

                        <Row>
                            <Col>
                                {serviziList.map((data, index) => {
                                    return (
                                        <Row key={serviziList[index].key} style={{ marginTop: "5px" }}>
                                            <Col xs={12} sm={6} xl={5}>
                                                <div
                                                    class='form-group'
                                                    onClick={() => {
                                                        if (location && location.state && location.state.pageStatus === "detail")
                                                            return
                                                        if (!dettaglioPage) {
                                                            setIsDenominazioneForServiziEnabled(index, true);
                                                            setIsTipoStrutturaSelectEnabled(false);
                                                            setIsSpecializzazioneSelectEnabled(false);
                                                            document.getElementById(index + 'select servizi denominazione').focus();
                                                        }
                                                    }}
                                                >
                                                    <label
                                                        htmlFor='selectExampleClassic'
                                                        class={(data.isDenominazioneEnabled || data.denominazione != null) ? 'active' : ''}
                                                        style={{ cursor: 'context-menu' }}
                                                    >
                                                        Denominazione
                                                    </label>
                                                    <Select
                                                        key={clearDataForServizi}
                                                        id={'selectServizi' + index}
                                                        inputId={index + 'select servizi denominazione'}
                                                        onBlur={() => { setIsDenominazioneForServiziEnabled(index, false); }}
                                                        isDisabled={dettaglioPage}
                                                        isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}

                                                        defaultValue={data.isChanged && { label: data.isChanged && data.denominazione, value: data.isChanged && data.idValue }}
                                                        placeholder=''
                                                        aria-label='Scegli una opzione'
                                                        options={serviziComboList}
                                                        onChange={(event) => { handleServiziChange('denominazione', event, index) }}
                                                        // styles={customStylesInputSelect}
                                                        menuPortalTarget={document.body}
                                                        menuIsOpen={data.isDenominazioneEnabled}
                                                    />
                                                </div>
                                                {!dettaglioPage && showServiziRequirements && !data.isChanged && <div style={{ marginTop: "-3em", marginBottom: "1em" }}>
                                                    <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>
                                                </div>}
                                            </Col>
                                            {!dettaglioPage &&
                                                <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                                    <Row
                                                    //style={{ marginTop: "2.5em" }}
                                                    >
                                                        <Icon className="buttonPrimary" icon="it-minus" color="white" style={{ "marginLeft": "5%", borderRadius: "47px", width: "20%" }}
                                                            onClick={() => removeFromServiziList(index)}
                                                            id={'servizi_minus_button' + index}
                                                        ></Icon>
                                                        <UncontrolledTooltip
                                                            placement='top'
                                                            target={'servizi_minus_button' + index}
                                                        >
                                                            Elimina riga
                                                        </UncontrolledTooltip>
                                                        {index === serviziList.length - 1 &&
                                                            <>
                                                                <Icon className="buttonPrimary" icon="it-plus" color="white" style={{ "marginLeft": "5%", borderRadius: "47px", width: "20%" }}
                                                                    onClick={() => addToServiziList()}
                                                                    id={'servizi_plus_button' + index}
                                                                >

                                                                </Icon>
                                                                <UncontrolledTooltip
                                                                    placement='top'
                                                                    target={'servizi_plus_button' + index}
                                                                >
                                                                    Aggiungi riga
                                                                </UncontrolledTooltip>   </>
                                                        }
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                    )
                                })
                                }
                            </Col>
                        </Row>
                    </Col>
                </AccordionBody>

                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen4}
                    onToggle={() => setCollapseOpen4(!collapseOpen4)}
                >
                    Prestazioni
                </AccordionHeader>
                <AccordionBody active={collapseOpen4}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={8}>

                        <Row>
                            <Col>
                                {prestazioniList.map((data, index) => {
                                    return (
                                        <Row key={prestazioniList[index].key} style={{ marginTop: "5px" }}>
                                            <Col xs={12} sm={6} xl={5}>
                                                <div
                                                    class='form-group'
                                                    onClick={() => {
                                                        if (location && location.state && location.state.pageStatus === "detail")
                                                            return
                                                        if (!dettaglioPage) {
                                                            setIsTipoStrutturaSelectEnabled(false);
                                                            setIsSpecializzazioneSelectEnabled(false);
                                                            setIsDenominazioneForPrestazioniEnabled(index, true);
                                                            document.getElementById(index + 'select prestazioni denominazione').focus();
                                                        }
                                                    }}
                                                >
                                                    <label
                                                        htmlFor='selectExampleClassic'
                                                        class={(data.isDenominazioneEnabled || data.denominazione != null) ? 'active' : ''}
                                                        style={{ cursor: 'context-menu' }}
                                                    >
                                                        Denominazione
                                                    </label>
                                                    <Select
                                                        key={clearDataForPrestazioni}
                                                        id={'selectPrestazioni' + index}
                                                        inputId={index + 'select prestazioni denominazione'}
                                                        onBlur={() => { setIsDenominazioneForPrestazioniEnabled(index, false); }}
                                                        isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}

                                                        isDisabled={dettaglioPage}
                                                        defaultValue={data.isChanged && { label: data.isChanged && data.denominazione, value: data.isChanged && data.denominazione }}
                                                        placeholder=''
                                                        aria-label='Scegli una opzione'
                                                        options={prestazioniComboList}
                                                        onChange={(event) => { handlePrestazioniChange('denominazione', event, index) }}
                                                        // styles={customStylesInputSelect}
                                                        menuPortalTarget={document.body}
                                                        menuIsOpen={data.isDenominazioneEnabled}
                                                    />
                                                </div>
                                                {!dettaglioPage && showPrestazioniRequirements && !data.isChanged && <div style={{ marginTop: "-3em", marginBottom: "1em" }}>
                                                    <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>
                                                </div>}
                                            </Col>
                                            {!dettaglioPage &&
                                                <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                                    <Row
                                                    //style={{ marginTop: "2.5em" }}
                                                    >
                                                        <Icon className="buttonPrimary" icon="it-minus" color="white" style={{ "marginLeft": "5%", borderRadius: "47px", width: "20%" }}
                                                            onClick={() => removeFromPrestazioniList(index)}
                                                            id={'prestazioni_minus_button' + index}
                                                        ></Icon>
                                                        <UncontrolledTooltip
                                                            placement='top'
                                                            target={'prestazioni_minus_button' + index}
                                                        >
                                                            Elimina riga
                                                        </UncontrolledTooltip>
                                                        {index === prestazioniList.length - 1 &&
                                                            <>
                                                                <Icon className="buttonPrimary" icon="it-plus" color="white" style={{ "marginLeft": "5%", borderRadius: "47px", width: "20%" }}
                                                                    onClick={() => addToPrestazioniList()}
                                                                    id={'prestazioni_plus_button' + index}
                                                                >

                                                                </Icon>
                                                                <UncontrolledTooltip
                                                                    placement='top'
                                                                    target={'prestazioni_plus_button' + index}
                                                                >
                                                                    Aggiungi riga
                                                                </UncontrolledTooltip>
                                                            </>
                                                        }
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                    )
                                })
                                }
                            </Col>
                        </Row>
                    </Col>
                </AccordionBody>
            </Accordion>
            {
                (genericError) && <Alert color='danger' className='display-linebreak'>{genericErrorMessage}</Alert>
            }
            <Container fluid style={{ marginBottom: "3%" }}>
                <Row className="alignElementsCenterAndEnd" style={{ display: "flex" }}>
                    <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                        <Button className="buttonPrimary" onClick={() => {
                            setIndietroTo(-1);
                            if (!dettaglioPage) {
                                if (!isPageChanged) {
                                    navigate(-1);
                                } else {
                                    toggleModalIndietro(!isOpenIndietro);
                                    if (isOpenIndietro) {
                                        navigate(-1);
                                    }
                                }
                            } else {
                                navigate(-1);
                            }
                        }} id='indietro_btn'>INDIETRO</Button>
                        <Modal
                            isOpen={isOpenIndietro}
                            toggle={() => toggleModalIndietro(!isOpenIndietro)}
                        >
                            <ModalHeader toggle={() => toggleModalIndietro(!isOpenIndietro)} id='esempio12'>
                                Indietro
                            </ModalHeader>
                            <ModalBody>
                                <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => toggleModalIndietro(!isOpenIndietro)}
                                >
                                    Annulla
                                </Button>
                                <Button className="buttonPrimary" onClick={() => { setIsPageChanged(false); navigate(indietroTo); }}
                                >
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                    {!dettaglioPage &&
                        <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                            <Button
                                className="buttonPrimary"
                                onClick={() => {
                                    if (!isPageChanged) {
                                        confirmSalvaClick()
                                    } else {
                                        toggleModalSalva(true);
                                    }
                                }}
                                id='salva_btn'>SALVA</Button>
                            <Modal
                                isOpen={isOpenSalva}
                                toggle={() => toggleModalSalva(!isOpenSalva)}
                            >
                                <ModalHeader toggle={() => toggleModalSalva(!isOpenSalva)} id='esempio12'>
                                    Conferma
                                </ModalHeader>
                                <ModalBody>
                                    <p>Confermi il salvataggio delle modifiche?</p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="buttonPrimary" onClick={() => toggleModalSalva(false)}>
                                        Annulla
                                    </Button>
                                    <Button className="buttonPrimary" onClick={confirmSalvaClick}>
                                        Conferma
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </Col>}
                </Row>
            </Container>
        </Container>
    )
}

export default GestioneStruttura;