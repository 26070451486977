import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const getTipoAzienda = () => {
    return axios.get(`${BASE_ENDPOINT}/v1/list/tipoAzienda`);
};

const getFonte = () => {
    return axios.get(`${BASE_ENDPOINT}/v1/list/tipoFonte`);
};

const getComune = (payload) => {
    return axios.post(`${BASE_ENDPOINT}/v1/list/comune`, payload);
};

const listaComuni = payload => {
    return axios.get(`${BASE_ENDPOINT}/v1/list/listaComuni/?comuneRicercato=` + payload);
};

const getProvincia = (payload) => {
    return axios.post(`${BASE_ENDPOINT}/v1/list/provincia`, payload);
};

const getTipoPresidio = () => {
    return axios.get(`${BASE_ENDPOINT}/v1/list/tipoPresidio`);
};

const getTipoStruttura = () => {
    return axios.get(`${BASE_ENDPOINT}/v1/list/tipoStruttura`);
};

const getTipoFonte = () => {
    return axios.get(`${BASE_ENDPOINT}/v1/list/tipoFonte`);
};

export default {
    getTipoPresidio,
    getTipoAzienda,
    getComune,
    listaComuni,
    getProvincia,
    getTipoStruttura,
    getTipoFonte,
    getFonte
};