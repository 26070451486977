import { Input, LinkListItem, UncontrolledTooltip, LinkList, Col, Select, Row, Button, Table, CardTitle, Alert, Icon, ModalFooter, ModalBody, ModalHeader, Modal, Tooltip } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import aziendaService from '../service/aziendaService';
import { wait } from '../common/Loading';
import '../../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import tipologiaService from '../service/tipologiaService';
import { PaginationControl } from '../custom/PaginationControl';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function AziendePage({isATL_OP_ADM_RESP, isPageChanged, setIsPageChanged }) {
    const navigate = useNavigate();
    const [denominazione, setDenominazione] = useState("");
    const [denominazioneHelper, setDenominazioneHelper] = useState("");
    const [denominazioneError, setDenominazioneError] = useState(false);

    const [codiceAzienda, setCodiceAzienda] = useState("");
    const [tipoAzienda, setTipoAzienda] = useState(null);
    const [tipoAziendaList, setTipoAziendaList] = useState([]);
    const [isTipoEnabled, setIsTipoEnabled] = useState(false);
    const [comune, setComune] = useState({
        "codIstatComune": "",
        "nomeComune": "",
    });
    const [comuneList, setComuneList] = useState([]);
    const [isComuneEnabled, setIsComuneEnabled] = useState(false);

    const [comuniLista, setComuniLista] = useState([]);
    const [labelComune, setLabelComune] = useState(false);
    const [inputComune, setInputComune] = useState([]);
    const [isValidCodIstatComune, setIsValidCodIstatComune] = useState(false);
    const [isComunePopulated, setIsComunePopulated] = useState(false);

    const [indirizzo, setIndirizzo] = useState("");
    const [indirizzoHelper, setIndirizzoHelper] = useState("");
    const [indirizzoError, setIndirizzoError] = useState(false);
    const [pageNumber, setPageNumber] = useState(localStorage.getItem('page') ? 
                                                Number(localStorage.getItem('page')) :
                                                0);
    const [pageSize, setPageSize] = useState(10);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [sortOrder, setOrder] = useState("asc");
    const [sortKey, setSortKey] = useState("nomeAzienda");
    const [aziendaList, setAziendaList] = useState([]);
    const [codiceFiscale, setCodiceFiscale] = useState('GLTFNC93B05H501U');

    const [isOpenDelete, toggleModalDelete] = useState(false);

    const [item, setItem] = useState(null);

    const handleDeleteToggle = (item) => {
        setItem(item);
        toggleModalDelete(!isOpenDelete);
    }

    const confirmDeleteClick = (item) => {
        handleDelete(item);
        toggleModalDelete(!isOpenDelete);
    }

    const [alert, setAlert] = useState({
        'state': false,
        'text': '',
        'type': ''
    });
    const [cercaTooltip, setCercaTooltip] = useState(false);
    const [inserisciTooltip, setInserisciTooltip] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        handleSubmit();
    }, []);

    useEffect(() => {
        handleSubmit();
    }, [pageNumber]);

    useEffect(() => {
        wait(tipologiaService.getTipoAzienda())
            .then((response) => {
                let tipoAziendaTmp = [];
                response.data.data.map((index) => {
                    tipoAziendaTmp.push({
                        'label': index.descrizione,
                        'value': index.id
                    });
                });
                setTipoAziendaList(tipoAziendaTmp);
            });
    }, []);

    useEffect(() => {
        setLabelComune(inputComune.value != null && inputComune.label !== null)
        setIsComunePopulated(true);
        setComune({
            "codIstatComune": inputComune.value,
            "nomeComune": inputComune.label,
        })
    }, [inputComune]);

    const handleChangeComune = (selectedOption) => {
        setInputComune(selectedOption ? selectedOption : { "label": null, "value": null });
    };
    const onInputChangeComune = (inputValue, action) => {
        switch (action.action) {
            case 'input-change':
                {
                    if (inputValue === '') {
                        setComuniLista([]);
                    } else {
                        let payload = inputValue
                        wait(tipologiaService.listaComuni(payload)).then((response) => {
                            let comuni = [];
                            comuni = response.data.data.map(
                                (comune) => {
                                    return {
                                        "label": comune.descrizione,
                                        "value": comune.id
                                    };
                                }
                            );
                            setComuniLista(comuni);
                        });
                    }
                }
        }
    };

    const handleSubmit = () => {
        setAlert({
            'state': false,
            'text': "",
            'type': ''
        });
        let count = 0;
        if (denominazione && denominazione.length < 3) {
            setDenominazioneError(true);
            setDenominazioneHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setDenominazioneError(false);
            setDenominazioneHelper("");
        }
        if (indirizzo && indirizzo.length < 3) {
            setIndirizzoError(true);
            setIndirizzoHelper("Inserire almeno tre caratteri");
            count++;
        } else {
            setIndirizzoError(false);
            setIndirizzoHelper("");
        }

        if (count <= 0) {
            let payload = {
                data: {
                    denominazione: denominazione,
                    indirizzo: indirizzo,
                    tipoAzienda: tipoAzienda,
                    codComune: comune.codIstatComune,
                    codiceAzienda: codiceAzienda,
                },
                paginationData: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortKey: sortKey,
                    sortOrder: sortOrder
                }
            };
            wait(aziendaService.searchAzienda(payload))
                .then((response) => {
                    setNumberOfPages(Math.ceil(response.data.paginationData.numElements / pageSize));
                    let aziendaTmp = [];
                    if (response && response.data && response.data.data) {
                        response.data.data.map((index) => {
                            aziendaTmp.push({
                                'denominazione': index.nomeAzienda,
                                'tipoAzienda': index.tipoAzienda.descrizione,
                                'comune': index.comune.descrizione,
                                'indirizzo': index.indirizzo,
                                'id': index.idAzienda,
                                'codAzienda': index.codAzienda
                            });
                        });
                        setAziendaList(aziendaTmp)
                    } else
                        setAziendaList([]);
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setAlert({
                            'state': true,
                            'text': error.response.data.apierror.debugMessage,
                            'type': 'danger'
                        });
                        return;
                    } else {
                        setAlert({
                            'state': true,
                            'text': "Errore durante l'operazione",
                            'type': 'danger'
                        });
                        return;
                    }
                });
        }

    }

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    const handleNavigation = (pageStatus, item) => {
        navigate("/gestione-azienda", {
            state: {
                pageStatus: pageStatus,
                id: pageStatus && pageStatus === "insert" ? null : item && item.id
            }
        });
    };

    const onChangeTipoAzienda = (event) => {
        setTipoAzienda(event);
        setIsTipoEnabled(false);
    }

    const handleDelete = (item) => {
        wait(aziendaService.deleteAziende(item.id))
            .then((response) => {
                handleSubmit();
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setAlert({
                        'state': true,
                        'text': error.response.data.apierror.debugMessage,
                        'type': 'danger'
                    });
                    return;
                } else {
                    setAlert({
                        'state': true,
                        'text': "Errore durante l'operazione",
                        'type': 'danger'
                    });
                    return;
                }
            });
    };

    return (<>
        <Col>
            <CustomBreadCrumb
                first={{
                    "name": "Elenco Aziende",
                    "onClick": () => { },
                    "active": true
                }}
            />
            <span class='bi bi-check-circle' />
            <Row style={{ marginTop: '4vh' }}>
                <Col xs={12} md={4}  >
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Denominazione'
                        value={denominazione}
                        onChange={(event) => { setDenominazione(event.target.value); }}
                        infoText={denominazioneHelper}
                        invalid={denominazioneError}
                    />
                </Col>
                <Col xs={12} md={4}  >
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Codice Azienda'
                        value={codiceAzienda}
                        onChange={(event) => { setCodiceAzienda(event.target.value); }}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <div
                        class='form-group'
                        onClick={() => {

                            setIsTipoEnabled(true);
                            document.getElementById('select tipo').focus();
                        }}
                    >
                        <label
                            htmlFor='selectExampleClassic'
                            class={(isTipoEnabled || tipoAzienda != null) ? 'active' : ''}
                            style={{ cursor: 'context-menu' }}
                        >
                            Tipo</label>
                        <Select
                            id='selectTipoAzienda'
                            inputId='select tipo'
                            onBlur={() => { setIsTipoEnabled(false); }}
                            isClearable={true}
                            placeholder=''
                            aria-label='Scegli una opzione'
                            isSearchable={false}
                            options={tipoAziendaList}
                            onChange={(event) => { onChangeTipoAzienda(event ? event.value : null); }}
                            styles={customStylesInputSelect}
                            menuPortalTarget={document.body}
                            menuIsOpen={isTipoEnabled}
                        />
                    </div>
                </Col>
                <Col xs={12} md={6}  >
                    <div class="form-group">
                        <label htmlFor='selectExampleClassic'
                            class={(labelComune) ? "active" : null}
                            onClick={() => {
                                setLabelComune(true);
                                document.getElementById("input select comuni").focus();
                            }}
                        >Comune</label>
                        <Select
                            key={comune.nomeComune}
                            id={"inputComune"}
                            inputId={"input select comuni"}
                            onChange={handleChangeComune}
                            aria-label='Scegli una opzione'
                            defaultValue={isComunePopulated && { label: isComunePopulated && comune.nomeComune, value: isComunePopulated && comune.codIstatComune }}
                            placeholder=""
                            isClearable={true}
                            isSearchable={true}
                            styles={customStylesInputSelect}
                            onBlur={() => {
                                if ((inputComune.label == null || inputComune.label === "") && (inputComune.value == null || inputComune.value === "")) {
                                    setLabelComune(false)
                                }
                            }}
                            onFocus={() => { setLabelComune(true) }}
                            onInputChange={(inputValue, action) => { onInputChangeComune(inputValue, action) }}
                            options={comuniLista}
                            invalid={!isValidCodIstatComune}
                            noOptionsMessage={({ inputValue }) => !inputValue ? null : "Nessun risultato"}
                        />
                    </div>
                </Col>
                <Col xs={12} md={6}  >
                    <Input
                        type='text'
                        className='clickableLabel'
                        label='Indirizzo'
                        value={indirizzo}
                        onChange={(event) => { setIndirizzo(event.target.value); }}
                        infoText={indirizzoHelper}
                        invalid={indirizzoError}
                    />
                </Col>
            </Row>
            <Row className='d-flex justify-content-end'>
                {isATL_OP_ADM_RESP ? 
                <Col xs={12} xl={2}>
                    <Button className="buttonPrimary"
                        id='inserisci_btn'
                        color='primary'
                        block
                        onClick={() => {
                            handleNavigation("insert", null);
                        }}
                    >
                        INSERISCI
                    </Button>{' '}
                </Col> : null}
                {isATL_OP_ADM_RESP ? 
                <Tooltip
                    placement='top'
                    isOpen={inserisciTooltip}
                    target={'inserisci_btn'}
                    toggle={() => setInserisciTooltip(!inserisciTooltip)}
                >
                    Inserisci azienda
                </Tooltip> : null}
                <Col xs={12} xl={2}>
                    <Button className="buttonPrimary"
                        id='cerca_btn'
                        color='primary'
                        block
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        CERCA
                    </Button>{' '}
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={cercaTooltip}
                    target={'cerca_btn'}
                    toggle={() => setCercaTooltip(!cercaTooltip)}
                >
                    Cerca aziende
                </Tooltip>
            </Row>
            <LinkList>
                <LinkListItem divider />
            </LinkList>
            {
                aziendaList && aziendaList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th scope='col'>Codice</th>
                                <th scope='col'>Denominazione</th>
                                <th scope='col'>Tipo</th>
                                <th scope='col'>Comune</th>
                                <th scope='col'>Indirizzo</th>
                                <th scope='col' class="text-center">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {aziendaList.map((item, index) => (
                                <tr>
                                    <td>{item.codAzienda}</td>
                                    <td>{item.denominazione}</td>
                                    <td>{item.tipoAzienda}</td>
                                    <td>{item.comune}</td>
                                    <td>{item.indirizzo}</td>
                                    <td class="text-center">
                                        <Button title="Visualizza" onClick={() => {
                                            handleNavigation("detail", item);
                                        }}> <Icon icon={"it-password-visible"} /></Button>
                                        <Button title="Aggiorna" onClick={() => {
                                            handleNavigation("update", item);
                                        }}>  <Icon icon={"it-pencil"} /></Button>
                                        {isATL_OP_ADM_RESP ? <Button title="Elimina" onClick={() => {
                                            handleDeleteToggle(item);
                                        }}>  <Icon icon={"it-delete"} /></Button> : null}
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
            }

            {isOpenDelete &&
                <Modal
                    isOpen={isOpenDelete}
                    toggle={() => toggleModalDelete(!isOpenDelete)}
                >
                    <ModalHeader toggle={() => toggleModalDelete(!isOpenDelete)} id='esempio12'>
                        Conferma
                    </ModalHeader>
                    <ModalBody>
                        <p>L'operazione è irreversibile. Sei sicuro di voler procedere?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="buttonPrimary" onClick={() => toggleModalDelete(!isOpenDelete)}>
                            Annulla
                        </Button>
                        <Button className="buttonPrimary" onClick={() => confirmDeleteClick(item)}>
                            Conferma
                        </Button>
                    </ModalFooter>
                </Modal>}

            {alert.state &&
                <div>
                    <div className='modal-content'>
                        <Modal fade centered isOpen={alert.state}>
                            <ModalHeader>Errore</ModalHeader>
                            <ModalBody>{alert.text}</ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => {
                                    setAlert({
                                        'state': false,
                                        'text': "",
                                        'type': ''
                                    });
                                }} color='secondary'>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            }
            <Row style={{ display: "flex", justifyContent: "end" }}>
                <PaginationControl
                    selectedPage={pageNumber}
                    numberOfPages={numberOfPages}
                    changePage={setPageNumber}
                />
            </Row>
        </Col></>
    );
}

export default AziendePage;