import CustomHeader from "./components/common/CustomHeader";
import CustomFooter from "./components/common/CustomFooter";
import RootPage from "./components/RootPage";
import { BrowserRouter as Router } from "react-router-dom";
import packageInfo from "../package.json";
import Loading from './components/common/Loading';
import React, { useState } from "react";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { RequiredProfileModal } from './components/custom/RequiredProfileModal';

function App() {
  const [isPageChanged, setIsPageChanged] = useState(false);
  const [isATL_OP_ADM_RESP, setIsATL_OP_ADM_RESP] = useState(false);
  const [isATL_OP_ADM, setIsATL_OP_ADM] = useState(false);
  const [requiredProfileModalIsOpen, toggleRequiredProfile] = useState(false);
  return (
    <ReactKeycloakProvider authClient={keycloak} onReady={() => { keycloak.updateToken(5) }}
       initOptions={{ onLoad: 'login-required',  pkceMethod: 'S256', checkLoginIframe: false }} >
      <Router basename={packageInfo.homepage}>
        <Loading/>
        <CustomHeader isATL_OP_ADM={isATL_OP_ADM} isATL_OP_ADM_RESP={isATL_OP_ADM_RESP} isPageChanged={isPageChanged} setIsPageChanged={setIsPageChanged}/>
        <RootPage toggleRequiredProfile={toggleRequiredProfile} isATL_OP_ADM={isATL_OP_ADM} setIsATL_OP_ADM={setIsATL_OP_ADM} isATL_OP_ADM_RESP={isATL_OP_ADM_RESP} setIsATL_OP_ADM_RESP={setIsATL_OP_ADM_RESP} isPageChanged={isPageChanged} setIsPageChanged={setIsPageChanged}/>
        <RequiredProfileModal requiredProfileModalIsOpen={requiredProfileModalIsOpen}
                 toggleRequiredProfile={toggleRequiredProfile}/>
        <CustomFooter />
      </Router>
    </ReactKeycloakProvider>
  );
}

export default App;