import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import {
    Collapse, Header, HeaderBrand, HeaderContent, HeaderRightZone, Headers, HeaderToggler, Icon, Nav, NavItem
    , NavLink, ModalFooter, ModalBody, ModalHeader, Modal, Button, Megamenu, MegamenuItem, LinkList, LinkListItem, Col, Row
} from 'design-react-kit';
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'typeface-lora';
import 'typeface-roboto-mono';
import 'typeface-titillium-web';
import logo from '../../img/regione-sicilia-logo.svg';
import { UserAvatar } from '../custom/UserAvatar';
import { useKeycloak } from "@react-keycloak/web";

function CustomHeader({ isATL_OP_ADM, isATL_OP_ADM_RESP, isPageChanged, setIsPageChanged }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [isRequestedATabChange, toggleModalTabChange] = useState(false);

    const { keycloak } = useKeycloak();

    const [navigateTo, setNavigateTo] = useState(null);
    const [collapse, setCollapse] = useState(false);

    const toggle = () => {
        setCollapse(!collapse);
    };

    return (
        <Headers>
            <div className="it-nav-wrapper">

                <Header
                    small
                    theme=""
                    type="center"
                >
                    <HeaderContent>
                        <HeaderBrand iconName={logo}>
                            <h2>
                                Atlante dei Servizi
                            </h2>
                            <h3>
                                Regione Sicilia
                            </h3>
                        </HeaderBrand>
                        <HeaderRightZone>
                            {keycloak.authenticated && <UserAvatar />}
                        </HeaderRightZone>
                    </HeaderContent>
                </Header>
                <Header theme="" type="navbar">
                    <HeaderContent
                        expand="lg"
                        megamenu
                    >
                        <HeaderToggler
                            aria-controls="nav1"
                            aria-label="Toggle navigation"
                            onClick={toggle}
                            aria-expanded={collapse}
                        >
                            <Icon icon="it-burger" />
                        </HeaderToggler>
                        <Collapse
                            header
                            navbar
                            onOverlayClick={toggle}
                            isOpen={collapse}
                        >
                            <div className="menu-wrapper">
                                <Nav navbar>
                                    {isATL_OP_ADM || isATL_OP_ADM_RESP ? <NavItem>
                                        <NavLink
                                            as={Link} to="/aziende"
                                            active={location.pathname === "/aziende"}
                                            onClick={() => {
                                                localStorage.setItem('page', 0);
                                                if ((location.pathname === "/gestione-specialita" || location.pathname === "/gestione-servizi"
                                                    || location.pathname === "/gestione-prestazioni" || location.pathname === "/gestione-reparti"
                                                    || location.pathname === "/gestione-azienda" || location.pathname === "/prestazioni"
                                                    || location.pathname === "/servizi" || location.pathname === "/specialita"
                                                    || location.pathname === "/aziende" || location.pathname === "/gestione-presidi") && isPageChanged) {
                                                    setNavigateTo("/aziende");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/aziende")
                                                    setIsPageChanged(false);
                                                }

                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Aziende{' '}
                                            </span>
                                        </NavLink>
                                    </NavItem> : null}
                                    {isATL_OP_ADM || isATL_OP_ADM_RESP ? <NavItem>
                                        <NavLink
                                            as={Link} to="/presidi"
                                            active={location.pathname === "/presidi"}
                                            onClick={() => {
                                                localStorage.setItem('page', 0);
                                                if ((location.pathname === "/gestione-specialita" || location.pathname === "/gestione-servizi"
                                                    || location.pathname === "/gestione-prestazioni" || location.pathname === "/gestione-reparti"
                                                    || location.pathname === "/gestione-azienda" || location.pathname === "/prestazioni"
                                                    || location.pathname === "/servizi" || location.pathname === "/specialita"
                                                    || location.pathname === "/presidi" || location.pathname === "/gestione-presidi") && isPageChanged) {
                                                    setNavigateTo("/presidi");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/presidi")
                                                    setIsPageChanged(false);
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Presidi{' '}
                                            </span>
                                        </NavLink>
                                    </NavItem> : null}
                                    {isATL_OP_ADM || isATL_OP_ADM_RESP ? <NavItem>
                                        <NavLink
                                            active={location.pathname === "/reparti"}
                                            onClick={() => {
                                                localStorage.setItem('page', 0);
                                                if ((location.pathname === "/servizi" || location.pathname === "/aziende"
                                                    || location.pathname === "/prestazioni" || location.pathname === "/gestione-reparti"
                                                    || location.pathname === "/gestione-azienda"
                                                    || location.pathname === "/specialita" || location.pathname === "/gestione-presidi") && isPageChanged) {
                                                    setNavigateTo("/reparti");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/reparti");
                                                    setIsPageChanged(false);
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Reparti/Strutture
                                            </span>
                                        </NavLink>
                                    </NavItem> : null}
                                    {isATL_OP_ADM_RESP ? <NavItem>
                                        <NavLink
                                            active={location.pathname === "/specialita"}
                                            onClick={() => {
                                                if ((location.pathname === "/servizi" || location.pathname === "/aziende"
                                                    || location.pathname === "/prestazioni" || location.pathname === "/gestione-reparti"
                                                    || location.pathname === "/gestione-azienda"
                                                    || location.pathname === "/specialita" || location.pathname === "/gestione-presidi") && isPageChanged) {
                                                    setNavigateTo("/specialita");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/specialita");
                                                    setIsPageChanged(false);
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Specialità
                                            </span>
                                        </NavLink>
                                    </NavItem> : null}
                                    {isATL_OP_ADM_RESP ? <NavItem>
                                        <NavLink
                                            active={location.pathname === "/servizi"}
                                            onClick={() => {
                                                if ((location.pathname === "/specialita" || location.pathname === "/aziende"
                                                    || location.pathname === "/prestazioni" || location.pathname === "/gestione-reparti"
                                                    || location.pathname === "/gestione-azienda" || location.pathname === "/specialita"
                                                    || location.pathname === "/gestione-presidi") && isPageChanged) {
                                                    setNavigateTo("/servizi");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/servizi");
                                                    setIsPageChanged(false);
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Servizi
                                            </span>
                                        </NavLink>
                                    </NavItem> : null}
                                    {isATL_OP_ADM_RESP ? <NavItem>
                                        <NavLink
                                            active={location.pathname === "/prestazioni"}
                                            onClick={() => {
                                                if ((location.pathname === "/specialita" || location.pathname === "/aziende"
                                                    || location.pathname === "/servizi" || location.pathname === "/gestione-repartis"
                                                    || location.pathname === "/gestione-azienda" || location.pathname === "/specialita"
                                                    || location.pathname === "/gestione-presidi") && isPageChanged) {
                                                    setNavigateTo("/prestazioni");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/prestazioni");
                                                    setIsPageChanged(false);
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Prestazioni
                                            </span>
                                        </NavLink>
                                    </NavItem> : null}
                                    {isATL_OP_ADM_RESP ? <NavItem>
                                        <NavLink
                                            active={location.pathname === "/admin"}
                                            onClick={() => {
                                                if ((location.pathname === "/specialita" || location.pathname === "/aziende"
                                                    || location.pathname === "/servizi" || location.pathname === "/gestione-repartis"
                                                    || location.pathname === "/gestione-azienda" || location.pathname === "/specialita"
                                                    || location.pathname === "/gestione-presidi" || location.pathname === "/prestazioni") && isPageChanged) {
                                                    setNavigateTo("/admin");
                                                    toggleModalTabChange(!isRequestedATabChange);
                                                } else {
                                                    navigate("/admin");
                                                    setIsPageChanged(false);
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span>
                                                Admin
                                            </span>
                                        </NavLink>
                                    </NavItem> : null}
                                </Nav>
                            </div>
                        </Collapse>
                    </HeaderContent>
                </Header>
                <Modal
                    isOpen={isRequestedATabChange}
                    toggle={() => toggleModalTabChange(!isRequestedATabChange)}
                >
                    <ModalHeader toggle={() => toggleModalTabChange(!isRequestedATabChange)} id='esempio12'>
                        Indietro
                    </ModalHeader>
                    <ModalBody>
                        <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="buttonPrimary" onClick={() => toggleModalTabChange(!isRequestedATabChange)}>
                            Annulla
                        </Button>
                        <Button className="buttonPrimary" onClick={() => { navigate(navigateTo); toggleModalTabChange(!isRequestedATabChange); setIsPageChanged(false); }}
                        >
                            Conferma
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>

        </Headers>
    );
}

export default CustomHeader;