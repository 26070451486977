import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const caricaPresidiStrutture = payload => {
    console.log("Payload",payload)
    return axios.post(`${BASE_ENDPOINT}/v1/caricamenti`, payload);
};

export default {
    caricaPresidiStrutture
};