import {
    Accordion, Alert, AccordionBody, AccordionHeader, Button, Col, Container, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Row, Select, Table, Tooltip
} from 'design-react-kit';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import '../../App.css';
import { isEmpty } from '../common/stringUtils';
import { EMAIL_REGEX, INTEGER_REGEX, PHONE_REGEX } from "../constants/regexConstants";
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';
import { CustomDateField } from '../custom/CustomDateField';
import aziendaService from "../service/aziendaService";
import presidiService from '../service/presidiService';
import strutturaService from '../service/strutturaService';
import tipologiaService from '../service/tipologiaService';
import { wait } from './../common/Loading';

function GestioneAzienda({isATL_OP_ADM_RESP, isPageChanged, setIsPageChanged }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidTelefono, setIsValidTelefono] = useState(true);
    const [isValidFax, setIsValidFax] = useState(true);
    const [latitudineHelper, setLatitudineHelper] = useState("La latitudine deve essere tra 90 e -90.");
    const [isLatitudineValid, setIsLatitudineValid] = useState(true);
    const [longitudineHelper, setLongitudineHelper] = useState("La longitudine deve essere tra 180 e -180.");
    const [isLongitudineValid, setIsLongitudineValid] = useState(true);
 


    const [comuniLista, setComuniLista] = useState([]);
    const [labelComune, setLabelComune] = useState(false);
    const [inputComune, setInputComune] = useState([]);
    const [isSalvaClicked, setIsSalvaClicked] = useState(false);
    const [idAzienda, setIdAzienda] = useState(location && location.state && location.state.id ? location.state.id : null);
    const [denominazione, setDenominazione] = useState("");
    const [denominazioneHelper, setDenominazioneHelper] = useState("Campo obbligatorio");
    const [denominazioneError, setDenominazioneError] = useState(true);
    const [codiceAzienda, setCodiceAzienda] = useState("");
    const [codiceAziendaHelper, setCodiceAziendaHelper] = useState("Campo obbligatorio");
    const [codiceAziendaError, setCodiceAziendaError] = useState(true);

    const [initialTipoAzienda, setInitialTipoAzienda] = useState(null);
    const [tipoAzienda, setTipoAzienda] = useState(null);
    const [tipoAziendaError, setTipoAziendaError] = useState(true);
    const [tipoAziendaHelper, setTipoAziendaHelper] = useState("Campo obbligatorio");
    const [tipoAziendaList, setTipoAziendaList] = useState([]);
    const [presidiList, setPresidiList] = useState([]);
    const [struttureList, setStruttureList] = useState([]);
    const [isTipoEnabled, setIsTipoEnabled] = useState(false);

    const [fonte, setFonte] = useState(null);
    const [fonteError, setFonteError] = useState(true);
    const [fonteHelper, setFonteHelper] = useState("Campo obbligatorio");
    const [fonteList, setFonteList] = useState([]);
    const [isFonteEnabled, setIsFonteEnabled] = useState(false);

    const formatDate = (date) => {
        return (date.getFullYear() + '-' + ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + ((date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate())))
    }

    const [datRifFonte, setDatRifFonte] = useState(null);

    const [comune, setComune] = useState({
        "codIstatComune": "",
        "nomeComune": "",
    });
    const [comuneError, setComuneError] = useState(true);
    const [comuneHelper, setComuneHelper] = useState("Campo obbligatorio");
    const [indirizzo, setIndirizzo] = useState("");
    const [indirizzoHelper, setIndirizzoHelper] = useState("Campo obbligatorio");
    const [indirizzoError, setIndirizzoError] = useState(true);
    const [telefono, setTelefono] = useState("");
    const [telefonoHelper, setTelefonoHelper] = useState("");
    const [telefonoError, setTelefonoError] = useState(false);
    const [fax, setFax] = useState("");
    const [faxHelper, setFaxHelper] = useState("");
    const [faxError, setFaxError] = useState(false);
    const [email, setEmail] = useState("");
    const [emailHelper, setEmailHelper] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [longitudine, setLongitudine] = useState(null);
    const [latitudine, setLatitudine] = useState(null);

    const [genericError, setGenericError] = useState(false);
    const [genericErrorMessage, setGenericErrorMessage] = useState("");

    const [inserisciTooltip, setInserisciTooltip] = useState(false);
    const [inserisciStrutturaTooltip, setInserisciStrutturaTooltip] = useState(false);
    const [isOpenSalva, toggleModalSalva] = useState(false);
    const [isOpenIndietro, toggleModalIndietro] = useState(false);
    const [isOpenStruttureChange, toggleModalStrutture] = useState(false);
    const [itemToNavigateStruttura, setItemToNavigateStruttura] = useState(null);
    const [causeForNavigateStruttura, setCauseForNavigateStruttura] = useState(null);
    const [isOpenPresidiChange, toggleModalPresidi] = useState(false);
    const [itemToNavigatePresidi, setItemToNavigatePresidi] = useState(null);
    const [causeForNavigatePresidi, setCauseForNavigatePresidi] = useState(null);

    const [dettaglioPage, setDettaglioPage] = useState(false);
    const [isComunePopulated, setIsComunePopulated] = useState(false);

    const [isOpenDeletePresidio, toggleModalDeletePresidio] = useState(false);

    const [isOpenDeleteStruttura, toggleModalDeleteStruttura] = useState(false);

    const [itemStruttura, setItemStruttura] = useState(null);

    const [itemPresidio, setItemPresidio] = useState(null);

    const[isUpdateDetail, setIsUpdateDetail] = useState(false);


    const handleToggleModalPresidiNavigate = () => {
        setIsPageChanged(false);
        handleNavigation(causeForNavigatePresidi, itemToNavigatePresidi);
    }

    const handleToggleModalStruttureNavigate = () => {
        setIsPageChanged(false);
        handleNavigationStrutture(causeForNavigateStruttura, itemToNavigateStruttura);
    }

    const handleDeleteTogglePresidio = (item) => {
        setItemPresidio(item);
        toggleModalDeletePresidio(!isOpenDeletePresidio);
    }

    const confirmDeletePresidioClick = (item) => {
        handleDelete(itemPresidio);
        toggleModalDeletePresidio(!isOpenDeletePresidio);
    }

    const handleDeleteToggleStruttura = (item) => {
        setItemStruttura(item);
        toggleModalDeleteStruttura(!isOpenDeleteStruttura);
    }

    const confirmDeleteStrutturaClick = (item) => {
        handleDeleteStrutture(itemStruttura);
        toggleModalDeleteStruttura(!isOpenDeleteStruttura);
    }

    const phoneValidation = (phone) => {
        setIsValidTelefono(!phone || PHONE_REGEX.test(phone));
    }

    const emailValidation = (email) => {
        setIsValidEmail(!email || EMAIL_REGEX.test(email));
    }

    const faxValidation = (fax) => {
        setIsValidFax(!fax || PHONE_REGEX.test(fax));
    }

    const latitudineValidation = (latitudine) => {
        setIsLatitudineValid(!latitudine || (latitudine >= -90 && latitudine <= 90));
        setLatitudineHelper("La latitudine deve essere tra 90 e -90.");
    }

    const longitudineValidation = (longitudine) => {
        setIsLongitudineValid(!longitudine || (longitudine >= -180 && longitudine <= 180));
        setLongitudineHelper("La longitudine deve essere tra 180 e -180.");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setIsComunePopulated(true);
        setComune({
            "codIstatComune": inputComune.value ? inputComune.value : null,
            "nomeComune": inputComune.label ? inputComune.label : null,
        })
    }, [inputComune]);

    useEffect(() => {
        let isPopulated = (comune.codIstatComune !== null && comune.nomeComune !== null)
        setIsComunePopulated(isPopulated);
        setLabelComune(isPopulated);
    }, [comune]);

    const handleChangeComune = (selectedOption) => {
        setIsPageChanged(true);
        setInputComune(selectedOption ? selectedOption : { "label": null, "value": null });
        if (!selectedOption) {
            setComuneError(true);
            setComuneHelper("Campo obbligatorio");
        } else {
            setComuneError(false);
            setComuneHelper("");
        }
    };
    const onInputChangeComune = (inputValue, action) => {
        switch (action.action) {
            case 'input-change':
                {
                    if (inputValue === '') {
                        setComuniLista([]);
                    } else {
                        let payload = inputValue
                        wait(tipologiaService.listaComuni(payload)).then((response) => {
                            let comuni = [];
                            comuni = response.data.data.map(
                                (comune) => {
                                    return {
                                        "label": comune.descrizione,
                                        "value": comune.id
                                    };
                                }
                            );
                            setComuniLista(comuni);
                        });
                    }
                }
        }
    };

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    const [collapseOpen1, setCollapseOpen1] = useState(true);
    const [collapseOpen2, setCollapseOpen2] = useState(true);
    const [collapseOpen3, setCollapseOpen3] = useState(true);
    const [collapseOpen4, setCollapseOpen4] = useState(true);


    useEffect(() => {
        wait(tipologiaService.getTipoAzienda())
            .then((response) => {
                let tipoAziendaTmp = [];
                response.data.data.map((index) => {
                    tipoAziendaTmp.push({
                        'label': index.descrizione,
                        'value': index.id
                    });
                });
                setTipoAziendaList(tipoAziendaTmp);
            });
    }, []);

    useEffect(() => {
        wait(tipologiaService.getFonte())
            .then((response) => {
                let fonteListTmp = [];
                response.data.data.map((index) => {
                    fonteListTmp.push({
                        'label': index.descrizione + " - " + index.codice,
                        'value': index.id
                    });
                });
                setFonteList(fonteListTmp);
            });
    }, []);

    useEffect(() => {
        if (location && location.state && (location.state.pageStatus === "update" || location.state.pageStatus === "detail")) {
            setIsUpdateDetail(true);
            if (location.state.pageStatus === "detail") { setDettaglioPage(true); }
            wait(aziendaService.getAziende(location.state.id))
                .then((response) => {
                    if (response && response.data && response.data.data) {
                        setInitialTipoAzienda(response.data.data.tipoAzienda);
                        buildAzienda(response.data.data);
                        setGenericError(false);
                    }
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setGenericError(true);
                        setGenericErrorMessage(error.response.data.apierror.debugMessage);
                        return;
                    } else {
                        setGenericError(true);
                        setGenericErrorMessage("Errore durante l'operazione");
                        return;
                    }
                });
        } else {
            if(!isATL_OP_ADM_RESP){
                navigate("/aziende");
            }
        }
    }, []);


    const buildAzienda = (data) => {
        setDenominazione(data.nomeAzienda);
        setDenominazioneError(data.nomeAzienda === null);
        setDenominazioneHelper("Campo obbligatorio");

        setTipoAzienda({ 'label': data.tipoAzienda.descrizione, 'value': data.tipoAzienda.id });
        setTipoAziendaError(data.tipoAzienda.id === null);
        setTipoAziendaHelper("Campo obbligatorio");

        setInputComune({
            "value": data.comune.id,
            "label": data.comune.descrizione,
        });
        setComuneError(data.comune.id === null);
        setComuneHelper("Campo obbligatorio");

        setIndirizzo(data.indirizzo);
        setIndirizzoError(data.indirizzo === null);
        setIndirizzoHelper("Campo obbligatorio");

        setTelefono(data.telefono);
        setFax(data.fax);
        setEmail(data.mail);
        setPresidiList(data.presidi);
        setStruttureList(data.strutture);
        setIdAzienda(data.idAzienda);

        setFonte(data.fonte ? { 'label': data.fonte.descrizione + " - " + data.fonte.codice, 'value': data.fonte.id } : null);
        setFonteError(data.fonte === null);
        setFonteHelper("Campo obbligatorio");

        setCodiceAzienda(data.codAzienda);
        setCodiceAziendaError(data.codAzienda === null);
        setCodiceAziendaHelper("Campo obbligatorio");
        setDatRifFonte(data.datRifFonte ? formatDate(new Date(data.datRifFonte)) : null);

        setLatitudine(data.latitudine);
        latitudineValidation(data.latitudine);

        setLongitudine(data.longitudine);
        longitudineValidation(data.longitudine);
    }

    const handleSubmit = () => {
        if (isValidEmail && isValidFax && isValidTelefono && isLongitudineValid && isLatitudineValid) {
            if (tipoAziendaError || codiceAziendaError || denominazioneError || comuneError || indirizzoError || fonteError || !datRifFonte) {
                setIsSalvaClicked(true);
            } else {
                let payload = {
                    nomeAzienda: denominazione,
                    indirizzo: indirizzo,
                    tipoAzienda: { id: tipoAzienda.value },
                    comune: { id: comune.codIstatComune },
                    telefono: telefono,
                    fax: fax,
                    mail: email,
                    idAzienda: idAzienda,
                    flgVisibile: true,
                    codAzienda: codiceAzienda,
                    fonte: { id: fonte.value },
                    datRifFonte: new Date(datRifFonte),
                    longitudine: longitudine,
                    latitudine: latitudine
                };
                if(payload.idAzienda) {
                wait(aziendaService.updateAziende(payload))
                    .then((result) => {
                        navigate("/aziende");
                        setIsPageChanged(false);
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {
                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
                } else {
                wait(aziendaService.insertAziende(payload))
                    .then((result) => {
                        navigate("/aziende");
                        setIsPageChanged(false);
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {
                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
                }                
            }
        }
    }

    const onChangeTipoAzienda = (event) => {
        setIsTipoEnabled(false);
        setTipoAzienda(event);
    }

    const onChangeFonte = (event) => {
        setIsFonteEnabled(false);
        setFonte(event);
    }

    const onChangeDatRifFonte = (event) => {
        console.log(datRifFonte);
        setDatRifFonte((event.target.value === '') ? null : event.target.value);
    }


    const handleNavigation = (pageStatus, item) => {
        navigate("/gestione-presidi", {
            state: {
                pageStatus: pageStatus,
                id: pageStatus && pageStatus === "insert" ? null : item && item.idPresidio,
                nomeAzienda: denominazione,
                idAzienda: idAzienda,
                fromAzienda: true,
                indirizzo: pageStatus && pageStatus === "insert" ? indirizzo : null,
            }
        });
    };


    const handleNavigationStrutture = (pageStatus, item) => {
        navigate("/gestione-reparti", {
            state: {
                pageStatus: pageStatus,
                id: pageStatus && pageStatus === "insert" ? null : item && item.idStruttura,
                idAzienda: idAzienda,
                nomeAzienda: denominazione,
                fromPresidio: false,
                fromAzienda: true,
                fromStruttura: false,
                indirizzo: pageStatus && pageStatus === "insert" ? indirizzo : null,
                isTypeAspAzienda: initialTipoAzienda.id === 1
            }
        });
    };

    const handleDelete = (item) => {
        wait(presidiService.deletePresidi(item.idPresidio))
            .then((response) => {
                wait(aziendaService.getAziende(location && location.state && location.state.id ? location.state.id : idAzienda))
                    .then((response) => {
                        if (response && response.data && response.data.data) {
                            buildAzienda(response.data.data);
                            setGenericError(false);
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {
                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setGenericError(true);
                    setGenericErrorMessage(error.response.data.apierror.debugMessage);
                    return;
                } else {
                    setGenericError(true);
                    setGenericErrorMessage("Errore durante l'operazione");
                    return;
                }
            });
    };

    const handleDeleteStrutture = (item) => {
        wait(strutturaService.deleteStrutture(item.idStruttura))
            .then((response) => {
                wait(aziendaService.getAziende(location && location.state && location.state.id ? location.state.id : idAzienda))
                    .then((response) => {
                        if (response && response.data && response.data.data) {
                            buildAzienda(response.data.data);
                            setGenericError(false);
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {
                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setGenericError(true);
                    setGenericErrorMessage(error.response.data.apierror.debugMessage);
                    return;
                } else {
                    setGenericError(true);
                    setGenericErrorMessage("Errore durante l'operazione");
                    return;
                }
            });
    };


    const confirmSalvaClick = () => {
        handleSubmit();
        toggleModalSalva(false);
    }

    return (<Container fluid>
        <CustomBreadCrumb
            first={{
                "name": "Elenco Aziende",
                "onClick": () => { navigate(-1) },
                "active": false
            }}
            second={{
                "name": "Azienda",
                "onClick": () => { },
                "active": true
            }}
        />
        <Accordion>
            <AccordionHeader
                className="d-none d-lg-block accordionButton"
                active={collapseOpen1}
                onToggle={() => setCollapseOpen1(!collapseOpen1)}
            >
                Anagrafica Azienda
            </AccordionHeader>
            <AccordionBody active={collapseOpen1}>
                <Row style={{ marginTop: '4vh' }}>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Denominazione Azienda*'
                            value={denominazione}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setDenominazione(event.target.value);
                                if (!event.target.value) {
                                    setDenominazioneError(true);
                                    setDenominazioneHelper("Campo obbligatorio");
                                } else if (event.target.value < 3) {
                                    setDenominazioneError(true);
                                    setDenominazioneHelper("Inserire almeno tre caratteri");
                                }
                                else {
                                    setDenominazioneError(false);
                                    setDenominazioneHelper("");
                                }
                            }}
                            infoText={isSalvaClicked && denominazioneError && denominazioneHelper}
                            invalid={isSalvaClicked && denominazioneError}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            maxLength={100}
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Codice Azienda*'
                            value={codiceAzienda}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setCodiceAzienda(event.target.value);
                                if (!event.target.value) {
                                    setCodiceAziendaError(true);
                                    setCodiceAziendaHelper("Campo obbligatorio");
                                }
                                else {
                                    setCodiceAziendaError(false);
                                    setCodiceAziendaHelper("");
                                }
                            }}
                            infoText={isSalvaClicked && codiceAziendaError && codiceAziendaHelper}
                            invalid={isSalvaClicked && codiceAziendaError}
                            readOnly={isUpdateDetail}
                            maxLength={100}
                        />
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3}>
                        <div
                            class='form-group'
                            onClick={() => {
                                if (location && location.state && location.state.pageStatus === "detail")
                                    return
                                setIsTipoEnabled(true);
                                document.getElementById('select tipo').focus();
                            }}
                        >
                            <label
                                htmlFor='selectExampleClassic'
                                class={(isTipoEnabled || tipoAzienda != null) ? 'active' : ''}
                                style={{ cursor: 'context-menu' }}
                            >
                                Tipo*</label>
                            <Select
                                id='selectTipoAzienda'
                                inputId='select tipo'
                                onBlur={() => { setIsTipoEnabled(false); }}
                                isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}
                                placeholder=''
                                aria-label='Scegli una opzione'
                                isSearchable={false}
                                options={tipoAziendaList}
                                onChange={(event) => {
                                    setIsPageChanged(true);
                                    onChangeTipoAzienda(event ? event : null);
                                    if (!event) {
                                        setTipoAziendaError(true);
                                        setTipoAziendaHelper("Campo obbligatorio");
                                    } else {
                                        setTipoAziendaError(false);
                                        setTipoAziendaHelper("");
                                    }
                                }}
                                value={tipoAzienda}
                                styles={customStylesInputSelect}
                                menuPortalTarget={document.body}
                                menuIsOpen={isTipoEnabled}
                                infoText={tipoAziendaHelper}
                                invalid={tipoAziendaError}
                            />
                        </div>
                        {(isSalvaClicked && tipoAziendaError) && <div style={{ marginTop: "-3em" }}>
                            <label style={{ color: "red", fontSize: "0.70em" }}>{tipoAziendaHelper}</label>
                        </div>}
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <div class="form-group">
                            <label htmlFor='selectExampleClassic'
                                class={(labelComune === true) ? "active" : null}
                                onClick={() => {
                                    if (location && location.state && location.state.pageStatus === "detail")
                                        return
                                    setLabelComune(true);
                                    document.getElementById("input select comuni").focus();
                                }}
                            >Comune*</label>
                            <Select
                                key={comune.nomeComune}
                                id={"inputComune"}
                                inputId={"input select comuni"}
                                onChange={handleChangeComune}
                                isDisabled={location && location.state && location.state.pageStatus === "detail" ? true : false}
                                defaultValue={isComunePopulated && { label: isComunePopulated && comune.nomeComune, value: isComunePopulated && comune.codIstatComune }}
                                placeholder=""
                                isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}

                                onBlur={() => {
                                    if ((inputComune.label == null || inputComune.label === "") && (inputComune.value == null || inputComune.value === "")) {
                                        setLabelComune(false)
                                    }
                                }}
                                onFocus={() => { setLabelComune(true) }}
                                onInputChange={(inputValue, action) => { onInputChangeComune(inputValue, action) }}
                                options={comuniLista}
                                noOptionsMessage={({ inputValue }) => !inputValue ? null : "Nessun risultato"}
                            />
                        </div>
                        {(isSalvaClicked && comuneError) && <div style={{ marginTop: "-3em" }}>
                            <label style={{ color: "red", fontSize: "0.70em" }}>{comuneHelper}</label>
                        </div>}
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Indirizzo*'
                            value={indirizzo}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setIndirizzo(event.target.value);
                                if (!event.target.value) {
                                    setIndirizzoError(true);
                                    setIndirizzoHelper("Campo Indirizzo obbligatori");
                                } else {
                                    setIndirizzoError(false);
                                    setIndirizzoHelper("");
                                }
                            }}
                            infoText={isSalvaClicked && indirizzoError && indirizzoHelper}
                            invalid={isSalvaClicked && indirizzoError}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            maxLength={100}
                        />
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3}>
                        <div
                            class='form-group'
                            onClick={() => {
                                if (location && location.state && location.state.pageStatus === "detail")
                                    return
                                setIsFonteEnabled(true);
                                document.getElementById('select fonte').focus();
                            }}
                        >
                            <label
                                htmlFor='selectExampleClassic'
                                class={(isFonteEnabled || fonte != null) ? 'active' : ''}
                                style={{ cursor: 'context-menu' }}
                            >
                                Fonte*</label>
                            <Select
                                id='selectTipoFonte'
                                inputId='select fonte'
                                onBlur={() => { setIsFonteEnabled(false); }}
                                isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}
                                placeholder=''
                                aria-label='Scegli una opzione'
                                isSearchable={false}
                                options={fonteList}
                                onChange={(event) => {
                                    setIsPageChanged(true);
                                    onChangeFonte(event ? event : null);
                                    if (!event) {
                                        setFonteError(true);
                                        setFonteHelper("Campo obbligatorio");
                                    } else {
                                        setFonteError(false);
                                        setFonteHelper("");
                                    }
                                }}
                                value={fonte}
                                styles={customStylesInputSelect}
                                menuPortalTarget={document.body}
                                menuIsOpen={isFonteEnabled}
                                infoText={fonteHelper}
                                invalid={fonteError}
                            />
                        </div>
                        {(isSalvaClicked && fonteError) && <div style={{ marginTop: "-3em" }}>
                            <label style={{ color: "red", fontSize: "0.70em" }}>{fonteHelper}</label>
                        </div>}
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3}>
                        <CustomDateField
                            value={datRifFonte}
                            placeholder='Data Riferimento Fonte'
                            disabled={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            onChange={(event) => { onChangeDatRifFonte(event); }}
                        />
                        {(isSalvaClicked && !datRifFonte &&
                            <label style={{ color: "red", fontSize: "0.70em" }}>Campo obbligatorio</label>)}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Input
                            type='number'
                            className='clickableLabel'
                            label='Longitudine'
                            value={longitudine}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setLongitudine(event.target.value);
                                longitudineValidation(event.target.value);
                            }}
                            infoText={isLongitudineValid ? false : longitudineHelper}
                            invalid={!isLongitudineValid}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            maxLength={100}
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Input
                            type='number'
                            className='clickableLabel'
                            label='Latitudine'
                            value={latitudine}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setLatitudine(event.target.value);
                                latitudineValidation(event.target.value);
                            }}
                            infoText={isLatitudineValid ? false : latitudineHelper}
                            invalid={!isLatitudineValid}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                            maxLength={100}
                        />
                    </Col>
                </Row>

            </AccordionBody>

            <AccordionHeader
                className='accordionButton'
                active={collapseOpen2}
                onToggle={() => setCollapseOpen2(!collapseOpen2)}
            >
                Dati di contatto
            </AccordionHeader>
            <AccordionBody active={collapseOpen2}>
                <Row style={{ marginTop: '4vh' }}>
                    <Col xs={12} md={3}  >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Telefono'
                            value={telefono}
                            onChange={(e) => {
                                setIsPageChanged(true);
                                setTelefono(e.target.value);
                                phoneValidation(e.target.value);
                            }}
                            infoText={isValidTelefono ? false : "Telefono non valido"}
                            invalid={!isValidTelefono}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                        />
                    </Col>
                    <Col xs={12} md={3}  >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Fax'
                            value={fax}
                            onChange={(e) => {
                                setIsPageChanged(true);
                                setFax(e.target.value);
                                faxValidation(e.target.value);
                            }}
                            infoText={isValidFax ? false : "Fax non valido"}
                            invalid={!isValidFax}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                        />
                    </Col>
                    <Col xs={12} md={3}  >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Email'
                            value={email}
                            onChange={(event) => {
                                setIsPageChanged(true);
                                setEmail(event.target.value);
                                emailValidation(event.target.value);
                            }}
                            infoText={isValidEmail ? false : "Email non valido"}
                            invalid={!isValidEmail}
                            readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                        />
                    </Col>
                </Row>

            </AccordionBody>
            {location && location.state && location.state.pageStatus === "insert" ? ("") : <>
                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen3}
                    onToggle={() => setCollapseOpen3(!collapseOpen3)}
                >
                    Presidi
                </AccordionHeader>
                <AccordionBody active={collapseOpen3}>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && 
                        isATL_OP_ADM_RESP ? 
                          <Col xs={12} xl={2}>
                            <Button className="buttonPrimary"
                                id='inserisci_btn_presidi'
                                color='primary'
                                block
                                onClick={() => {
                                    setItemToNavigatePresidi(null);
                                    setCauseForNavigatePresidi("insert");
                                    if (!dettaglioPage) {
                                        if (!isPageChanged) {
                                            handleNavigation("insert", null);
                                        } else {
                                            toggleModalPresidi(!isOpenPresidiChange);
                                            if (isOpenPresidiChange) {
                                                handleNavigation("insert", null);
                                            }
                                        }
                                    } else {
                                        handleNavigation("insert", null);
                                    }
                                }}
                            >
                                INSERISCI
                            </Button>{' '}
                          </Col> : null}
                        {isATL_OP_ADM_RESP ? 
                          <Tooltip
                            placement='top'
                            isOpen={inserisciTooltip}
                            target={'inserisci_btn_presidi'}
                            toggle={() => setInserisciTooltip(!inserisciTooltip)}
                        >
                            Inserisci presidio
                          </Tooltip> : null}
                    </Row>
                    {
                        presidiList && presidiList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th scope='col'>Denominazione</th>
                                        <th scope='col'>Comune</th>
                                        <th scope='col'>Indirizzo</th>
                                        <th scope='col'>Mail</th>
                                        <th scope='col'>Telefono</th>
                                        <th scope='col' class="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {presidiList.map((item, index) => (
                                        <tr>
                                            <td>{item.nomePresidio}</td>
                                            <td>{item.comune.descrizione}</td>
                                            <td>{item.indirizzo}</td>
                                            <td>{item.mail}</td>
                                            <td>{item.telefono}</td>
                                            <td class="text-center">
                                                <Button title="Visualizza" onClick={() => {
                                                    setItemToNavigatePresidi(item);
                                                    setCauseForNavigatePresidi("detail");
                                                    if (!dettaglioPage) {
                                                        if (!isPageChanged) {
                                                            handleNavigation("detail", item);
                                                        } else {
                                                            toggleModalPresidi(!isOpenPresidiChange);
                                                            if (isOpenPresidiChange) {
                                                                handleNavigation("detail", item);
                                                            }
                                                        }
                                                    } else {
                                                        handleNavigation("detail", item);
                                                    }
                                                }}> <Icon icon={"it-password-visible"} /></Button>
                                                {!dettaglioPage && <>
                                                    <Button title="Aggiorna" onClick={() => {
                                                        setItemToNavigatePresidi(item);
                                                        setCauseForNavigatePresidi("update");
                                                        if (!dettaglioPage) {
                                                            if (!isPageChanged) {
                                                                handleNavigation("update", item);
                                                            } else {
                                                                toggleModalPresidi(!isOpenPresidiChange);
                                                                if (isOpenPresidiChange) {
                                                                    handleNavigation("update", item);
                                                                }
                                                            }
                                                        } else {
                                                            handleNavigation("update", item);
                                                        }
                                                    }}>  <Icon icon={"it-pencil"} /></Button>
                                                    {isATL_OP_ADM_RESP ? <Button title="Elimina" onClick={() => {
                                                        handleDeleteTogglePresidio(item);
                                                    }}>  <Icon icon={"it-delete"} /></Button> : null}</>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
                    }
                </AccordionBody>

                <AccordionHeader
                    className='accordionButton'
                    active={collapseOpen4}
                    onToggle={() => setCollapseOpen4(!collapseOpen4)}
                >
                    {tipoAzienda && tipoAzienda.value === 1 ? "Strutture" : "Reparti"}
                </AccordionHeader>
                <AccordionBody active={collapseOpen4}>
                    <Row className='d-flex justify-content-end'>
                        {!dettaglioPage && 
                        isATL_OP_ADM_RESP ? <Col xs={12} xl={2}>
                            <Button className="buttonPrimary"
                                id='inserisci_btn_strutture'
                                color='primary'
                                block
                                onClick={() => {
                                    setItemToNavigateStruttura(null);
                                    setCauseForNavigateStruttura("insert");
                                    if (!dettaglioPage) {
                                        if (!isPageChanged) {
                                            handleNavigationStrutture("insert", null);
                                        } else {
                                            toggleModalStrutture(!isOpenStruttureChange);
                                            if (isOpenStruttureChange) {
                                                handleNavigationStrutture("insert", null);
                                            }
                                        }
                                    } else {
                                        handleNavigationStrutture("insert", null);
                                    }
                                }}
                            >
                                INSERISCI
                            </Button>{' '}
                        </Col> : null}
                        {isATL_OP_ADM_RESP ? 
                          <Tooltip
                            placement='top'
                            isOpen={inserisciStrutturaTooltip}
                            target={'inserisci_btn_strutture'}
                            toggle={() => setInserisciStrutturaTooltip(!inserisciStrutturaTooltip)}
                        >
                            Inserisci struttura
                          </Tooltip> : null}
                    </Row>
                    {
                        struttureList && struttureList.length !== 0 ? (<Row className='d-flex justify-content-end'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th scope='col'>Denominazione</th>
                                        <th scope='col'>Responsabile</th>
                                        <th scope='col'>Telefono Direzione</th>
                                        <th scope='col'>Telefono Segreteria</th>
                                        <th scope='col' class="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {struttureList.map((item, index) => (
                                        <tr>
                                            <td>{item.nomeStruttura}</td>
                                            <td>{item.responsabile}</td>
                                            <td>{item.telefonoDirezione}</td>
                                            <td>{item.telefonoSegreteria}</td>
                                            <td class="text-center">
                                                <Button title="Visualizza" onClick={() => {
                                                    setItemToNavigateStruttura(item);
                                                    setCauseForNavigateStruttura("detail");
                                                    if (!dettaglioPage) {
                                                        if (!isPageChanged) {
                                                            handleNavigationStrutture("detail", item);
                                                        } else {
                                                            toggleModalStrutture(!isOpenStruttureChange);
                                                            if (isOpenStruttureChange) {
                                                                handleNavigationStrutture("detail", item);
                                                            }
                                                        }
                                                    } else {
                                                        handleNavigationStrutture("detail", item);
                                                    }
                                                }}> <Icon icon={"it-password-visible"} /></Button>
                                                {!dettaglioPage && <> <Button title="Aggiorna" onClick={() => {
                                                    setItemToNavigateStruttura(item);
                                                    setCauseForNavigateStruttura("update");
                                                    if (!dettaglioPage) {
                                                        if (!isPageChanged) {
                                                            handleNavigationStrutture("update", item);
                                                        } else {
                                                            toggleModalStrutture(!isOpenStruttureChange);
                                                            if (isOpenStruttureChange) {
                                                                handleNavigationStrutture("update", item);
                                                            }
                                                        }
                                                    } else {
                                                        handleNavigationStrutture("update", item);
                                                    }
                                                }}>  <Icon icon={"it-pencil"} /></Button>
                                                    {isATL_OP_ADM_RESP ? <Button title="Elimina" onClick={() => {
                                                        handleDeleteToggleStruttura(item);
                                                    }}>  <Icon icon={"it-delete"} /></Button> : null}</>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
                    }
                </AccordionBody>
            </>}
        </Accordion>
        <LinkList>
            <LinkListItem divider />
        </LinkList>
        {
            (genericError) && <Alert color='danger' className='display-linebreak'>{genericErrorMessage}</Alert>
        }
        <Container fluid style={{ marginBottom: "3%" }}>
            <Row className="alignElementsCenterAndEnd" style={{ display: "flex" }}>
                <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                    <Button className="buttonPrimary" onClick={() => {
                        if (!dettaglioPage) {
                            if (!isPageChanged) {
                                navigate(-1);
                            } else {
                                toggleModalIndietro(!isOpenIndietro);
                                if (isOpenIndietro) {
                                    navigate(-1);
                                }
                            }
                        } else {
                            navigate(-1);
                        }
                    }} id='indietro_btn'>INDIETRO</Button>
                    <Modal
                        isOpen={isOpenIndietro}
                        toggle={() => toggleModalIndietro(!isOpenIndietro)}
                    >
                        <ModalHeader toggle={() => toggleModalIndietro(!isOpenIndietro)} id='esempio12'>
                            Indietro
                        </ModalHeader>
                        <ModalBody>
                            <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => toggleModalIndietro(!isOpenIndietro)}>
                                Annulla
                            </Button>
                            <Button className="buttonPrimary" onClick={() => navigate(-1)}>
                                Conferma
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Col>
                {!dettaglioPage &&
                    <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                        <Button
                            className="buttonPrimary"
                            onClick={() => {
                                if (!isPageChanged) {
                                    confirmSalvaClick()
                                } else {
                                    toggleModalSalva(true)
                                }
                            }}
                            id='salva_btn'>SALVA</Button>
                        <Modal
                            isOpen={isOpenSalva}
                            toggle={() => toggleModalSalva(!isOpenSalva)}
                        >
                            <ModalHeader toggle={() => toggleModalSalva(!isOpenSalva)} id='esempio12'>
                                Conferma
                            </ModalHeader>
                            <ModalBody>
                                <p>Confermi il salvataggio delle modifiche?</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => toggleModalSalva(false)}>
                                    Annulla
                                </Button>
                                <Button className="buttonPrimary" onClick={confirmSalvaClick}>
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Col>}
                <Modal
                    isOpen={isOpenStruttureChange}
                    toggle={() => toggleModalStrutture(!isOpenStruttureChange)}
                >
                    <ModalHeader toggle={() => toggleModalStrutture(!isOpenStruttureChange)} id='esempio12'>
                        ATTENZIONE
                    </ModalHeader>
                    <ModalBody>
                        <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="buttonPrimary" onClick={() => toggleModalStrutture(!isOpenStruttureChange)}>
                            Annulla
                        </Button>
                        <Button className="buttonPrimary" onClick={handleToggleModalStruttureNavigate}>
                            Conferma
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={isOpenPresidiChange}
                    toggle={() => toggleModalPresidi(!isOpenPresidiChange)}
                >
                    <ModalHeader toggle={() => toggleModalPresidi(!isOpenPresidiChange)} id='esempio12'>
                        ATTENZIONE
                    </ModalHeader>
                    <ModalBody>
                        <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="buttonPrimary" onClick={() => toggleModalPresidi(!isOpenPresidiChange)}>
                            Annulla
                        </Button>
                        <Button className="buttonPrimary" onClick={handleToggleModalPresidiNavigate}>
                            Conferma
                        </Button>
                    </ModalFooter>
                </Modal>
            </Row>
        </Container>
        <LinkList>
            <LinkListItem divider />
        </LinkList>
        {
            isOpenDeletePresidio &&
            <Modal
                isOpen={isOpenDeletePresidio}
                toggle={() => toggleModalDeletePresidio(!isOpenDeletePresidio)}
            >
                <ModalHeader toggle={() => toggleModalDeletePresidio(!isOpenDeletePresidio)} id='esempio12'>
                    Conferma
                </ModalHeader>
                <ModalBody>
                    <p>L'operazione è irreversibile. Sei sicuro di voler procedere?</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="buttonPrimary" onClick={() => toggleModalDeletePresidio(!isOpenDeletePresidio)}>
                        Annulla
                    </Button>
                    <Button className="buttonPrimary" onClick={() => confirmDeletePresidioClick(itemPresidio)}>
                        Conferma
                    </Button>
                </ModalFooter>
            </Modal>
        }
        {
            isOpenDeleteStruttura &&
            <Modal
                isOpen={isOpenDeleteStruttura}
                toggle={() => toggleModalDeleteStruttura(!isOpenDeleteStruttura)}
            >
                <ModalHeader toggle={() => toggleModalDeleteStruttura(!isOpenDeleteStruttura)} id='esempio12'>
                    Conferma
                </ModalHeader>
                <ModalBody>
                    <p>L'operazione è irreversibile. Sei sicuro di voler procedere?</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="buttonPrimary" onClick={() => toggleModalDeleteStruttura(!isOpenDeleteStruttura)}>
                        Annulla
                    </Button>
                    <Button className="buttonPrimary" onClick={() => confirmDeleteStrutturaClick(itemStruttura)}>
                        Conferma
                    </Button>
                </ModalFooter>
            </Modal>
        }
    </Container>
    )
}

export default GestioneAzienda;