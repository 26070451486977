import { Accordion, Alert, AccordionHeader, AccordionBody, Col, Container, Button, Row, Icon, Input, Select, Table, UncontrolledTooltip, ModalFooter, ModalBody, ModalHeader, Modal, LinkListItem, LinkList, DropdownMenu, Dropdown, DropdownToggle } from 'design-react-kit';
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { wait } from '../common/Loading';
import { EMAIL_REGEX, INTEGER_REGEX, PHONE_REGEX } from "../constants/regexConstants";
import presidiService from '../service/presidiService';
import strutturaService from '../service/strutturaService';
import tipologiaService from '../service/tipologiaService';
import { isEmpty } from '../common/stringUtils';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';
import { CustomDateField } from '../custom/CustomDateField';

function GestionePresidio({isATL_OP_ADM_RESP, isPageChanged, setIsPageChanged }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [comeFromTypeAspAzienda, setComeFromTypeAspAzienda] = useState(false);
    const [comeFromAzienda, setComeFromAzienda] = useState(false);
    const [comeFromPresidio, setComeFromPresidio] = useState(false);
    const [itemStruttura, setItemStruttura] = useState(null);
    const [comuniLista, setComuniLista] = useState([]);
    const [labelComune, setLabelComune] = useState(false);
    const [inputComune, setInputComune] = useState([]);
    const [isValidCodIstatComune, setIsValidCodIstatComune] = useState(false);
    const [dettaglioPage, setDettaglioPage] = useState(false);
    const [isComunePopulated, setIsComunePopulated] = useState(false);

    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidTelefono, setIsValidTelefono] = useState(true);
    const [isValidFax, setIsValidFax] = useState(true);
    const [isSalvaClicked, setIsSalvaClicked] = useState(false);

    const [latitudineHelper, setLatitudineHelper] = useState("La latitudine deve essere tra 90 e -90.");
    const [isLatitudineValid, setIsLatitudineValid] = useState(true);
    const [longitudineHelper, setLongitudineHelper] = useState("La longitudine deve essere tra 180 e -180.");
    const [isLongitudineValid, setIsLongitudineValid] = useState(true);
 

    const [comuneError, setComuneError] = useState(true);
    const [comuneHelper, setComuneHelper] = useState("Campo obbligatorio");
    const [indirizzoHelper, setIndirizzoHelper] = useState("Campo obbligatorio");
    const [indirizzoError, setIndirizzoError] = useState(true);
    const [denominazioneHelper, setDenominazioneHelper] = useState("Campo obbligatorio");
    const [denominazioneError, setDenominazioneError] = useState(true);
    const [tipoPresidioError, setTipoPresidioError] = useState(true);
    const [tipoPresidioHelper, setTipoPresidioHelper] = useState("Campo obbligatorio");
    const [longitudine, setLongitudine] = useState(null);
    const [latitudine, setLatitudine] = useState(null);

    const [codicePresidio, setCodicePresidio] = useState("");
    const [codicePresidioHelper, setCodicePresidioHelper] = useState("Campo obbligatorio");
    const [codicePresidioError, setCodicePresidioError] = useState(true);

    const [fonte, setFonte] = useState(null);
    const [fonteError, setFonteError] = useState(true);
    const [fonteHelper, setFonteHelper] = useState("Campo obbligatorio");
    const [fonteList, setFonteList] = useState([]);
    const [isFonteEnabled, setIsFonteEnabled] = useState(false);

    const [genericError, setGenericError] = useState(false);
    const [genericErrorMessage, setGenericErrorMessage] = useState("");

    const [isOpenStruttureChange, toggleModalStrutture] = useState(false);
    const [itemToNavigateStruttura, setItemToNavigateStruttura] = useState(null);
    const [causeForNavigateStruttura, setCauseForNavigateStruttura] = useState(null);
    const[isUpdateDetail, setIsUpdateDetail] = useState(false);

    const formatDate = (date) => {
        return (date.getFullYear() + '-' + ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + ((date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate())))
    }

    const [datRifFonte, setDatRifFonte] = useState(null);

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };


    const [tipoPresidioList, setTipoPresidioList] = useState([]);
    const [isTipoEnabled, setIsTipoEnabled] = useState(false);

    const [collapseOpen1, setCollapseOpen1] = useState(true);
    const [collapseOpen2, setCollapseOpen2] = useState(true);
    const [collapseOpen3, setCollapseOpen3] = useState(true);

    const [pageStatus, setPageStatus] = useState(null);
    const [dettaglio, setDettaglio] = useState(false);
    const [itemId, setItemId] = useState(null);

    const [idPresidio, setIdPresidio] = useState(null);
    const [idAzienda, setIdAzienda] = useState(null);
    const [nomeAzienda, setNomeAzienda] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!location || !location.state || (!location.state.idAzienda && !location.state.id))
            navigate("/presidi");
        else {
            setIdAzienda(location.state.idAzienda);
            setIdPresidio(location.state.id);
            setNomeAzienda(location.state.nomeAzienda);
        }
        if (location.state) {
            setNomeAzienda(location.state.nomeAzienda);
            setIdAzienda(location.state.idAzienda);
            setComeFromAzienda(location.state.fromAzienda);
            setComeFromPresidio(location.state.fromPresidio);
            if (location.state.pageStatus === "detail") { setDettaglioPage(true); }
            let pageStatusTmp = location.state.pageStatus;
            if (pageStatusTmp === 'detail') setDettaglio(true);
            setPageStatus(pageStatusTmp);
            setItemId(location.state.id);
            handleChangeAnagrafica("indirizzo", location.state.indirizzo);
        }
    }, [])

    const [listaStrutture, setListaStrutture] = useState([]);

    const [isOpenDeleteStruttura, toggleModalDeleteStruttura] = useState(false);

    const handleToggleModalStruttureNavigate = () => {
        setIsPageChanged(false);
        handleNavigation(causeForNavigateStruttura, itemToNavigateStruttura);
    }

    const handleDeleteToggleStruttura = (item) => {
        setItemStruttura(item);
        toggleModalDeleteStruttura(!isOpenDeleteStruttura);
    }

    const confirmDeleteStrutturaClick = (item) => {
        handleDeleteStrutture(itemStruttura);
        toggleModalDeleteStruttura(!isOpenDeleteStruttura);
    }


    const phoneValidation = (phone) => {
        setIsValidTelefono(!phone || PHONE_REGEX.test(phone));
    }

    const emailValidation = (email) => {
        setIsValidEmail(!email || EMAIL_REGEX.test(email));
    }

    const faxValidation = (fax) => {
        setIsValidFax(!fax || PHONE_REGEX.test(fax));
    }

    const latitudineValidation = (latitudine) => {
        setIsLatitudineValid(!latitudine || (latitudine >= -90 && latitudine <= 90));
        setLatitudineHelper("La latitudine deve essere tra 90 e -90.");
    }

    const longitudineValidation = (longitudine) => {
        setIsLongitudineValid(!longitudine || (longitudine >= -180 && longitudine <= 180));
        setLongitudineHelper("La longitudine deve essere tra 180 e -180.");
    }

    const modificaStatoAzioni = (data) => {
        const listaStruttureCopia = [];
        listaStrutture.map((strutture) => {
            listaStruttureCopia.push(strutture);
        })
        listaStruttureCopia.map((elem) => {
            if (elem.id === data.id) {
                elem.azioni = !data.azioni;
            }
        })
        setListaStrutture(listaStruttureCopia);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setIsComunePopulated(true);
        setAnagraficaPresidio({
            "denominazioneAzienda": anagraficaPresidio.denominazioneAzienda,
            "denominazionePresidio": anagraficaPresidio.denominazionePresidio,
            "codIstatComune": inputComune.value ? inputComune.value : null,
            "nomeComune": inputComune.label ? inputComune.label : null,
            "indirizzo": anagraficaPresidio.indirizzo,
            "tipoPresidio": anagraficaPresidio.tipoPresidio,
            "nomeAzienda": anagraficaPresidio.nomeAzienda,
        });
        setLabelComune(inputComune.value != null && inputComune.label != null);
    }, [inputComune]);

    const handleChangeComune = (selectedOption) => {
        setIsPageChanged(true);
        setInputComune(selectedOption ? selectedOption : { "label": null, "value": null });
        if (!selectedOption) {
            setComuneError(true);
            setComuneHelper("Campo obbligatorio");
        } else {
            setComuneError(false);
            setComuneHelper("");
        }
    };

    const onInputChangeComune = (inputValue, action) => {
        switch (action.action) {
            case 'input-change':
                {
                    if (inputValue === '') {
                        setComuniLista([]);
                    } else {
                        let payload = inputValue
                        wait(tipologiaService.listaComuni(payload)).then((response) => {
                            let comuni = [];
                            comuni = response.data.data.map(
                                (comune) => {
                                    return {
                                        "label": comune.descrizione,
                                        "value": comune.id
                                    };
                                }
                            );
                            setComuniLista(comuni);
                        });
                    }
                }
        }
    };

    const [anagraficaPresidio, setAnagraficaPresidio] = useState({
        "denominazioneAzienda": null,
        "denominazionePresidio": null,
        "codIstatComune": "",
        "nomeComune": "",
        "indirizzo": null,
        "tipoPresidio": null,
        "nomeAzienda": null,
    });

    const handleChangeAnagrafica = (inputField, event) => {
        setAnagraficaPresidio({
            "denominazioneAzienda": (inputField === "denominazioneAzienda") ? event.target.value : anagraficaPresidio.denominazioneAzienda,
            "denominazionePresidio": (inputField === "denominazionePresidio") ? event.target.value : anagraficaPresidio.denominazionePresidio,
            "codIstatComune": (inputField === "codIstatComune") ? event.target.value : anagraficaPresidio.codIstatComune,
            "nomeComune": (inputField === "nomeComune") ? event.target.value : anagraficaPresidio.nomeComune,
            "indirizzo": inputField === "indirizzo" ? event : anagraficaPresidio.indirizzo,
            "tipoPresidio": inputField === "tipoPresidio" ? event : anagraficaPresidio.tipoPresidio,
            "nomeAzienda": anagraficaPresidio.nomeAzienda,
        });
        if (inputField === "tipoPresidio") {
            setIsTipoEnabled(false);
        }
        if (inputField === "denominazionePresidio") {
            if (!event.target.value) {
                setDenominazioneError(true);
                setDenominazioneHelper("Campo obbligatorio");
            } else if (event.target.value < 3) {
                setDenominazioneError(true);
                setDenominazioneHelper("Inserire almeno tre caratteri");
            }
            else {
                setDenominazioneError(false);
                setDenominazioneHelper("");
            }
        }
        if (inputField === "indirizzo") {
            if (!event) {
                setIndirizzoError(true);
                setIndirizzoHelper("Campo Indirizzo obbligatori");
            } else {
                setIndirizzoError(false);
                setIndirizzoHelper("");
            }
        }
        if (inputField === "tipoPresidio") {
            if (!event) {
                setTipoPresidioError(true);
                setTipoPresidioHelper("Campo obbligatorio");
            } else {
                setTipoPresidioError(false);
                setTipoPresidioHelper("");
            }
        }
    };

    const [datiDiContatto, setDatiDiContatto] = useState({
        "telefono": null,
        "fax": null,
        "email": null,
    });

    const handleChangeDatiDiContatto = (inputField, event) => {
        setDatiDiContatto({
            "telefono": (inputField === "telefono") ? event.target.value : datiDiContatto.telefono,
            "fax": (inputField === "fax") ? event.target.value : datiDiContatto.fax,
            "email": (inputField === "email") ? event.target.value : datiDiContatto.email
        });
        if (inputField === "email") {
            emailValidation(event.target.value);
        }
        if (inputField === "telefono") {
            phoneValidation(event.target.value);
        }
        if (inputField === "fax") {
            faxValidation(event.target.value);
        }
    };

    const [isOpenSalva, toggleModalSalva] = useState(false);
    const [isOpenIndietro, toggleModalIndietro] = useState(false);

    const salvaClick = () => {
        if (isValidEmail && isValidFax && isValidTelefono && isLongitudineValid && isLatitudineValid) {
            if (tipoPresidioError || denominazioneError || codicePresidioError || comuneError || indirizzoError || fonteError || !datRifFonte) {
                setIsSalvaClicked(true);
            } else {
                let payload = {
                    azienda: idAzienda && { idAzienda: idAzienda },
                    idPresidio: idPresidio ? idPresidio : null,
                    nomePresidio: anagraficaPresidio.denominazionePresidio,
                    indirizzo: anagraficaPresidio.indirizzo,
                    tipoPresidio: { idTipoPresidio: anagraficaPresidio.tipoPresidio.value },
                    comune: { id: anagraficaPresidio.codIstatComune },
                    telefono: datiDiContatto.telefono,
                    fax: datiDiContatto.fax,
                    mail: datiDiContatto.email,
                    flgVisibile: true,
                    strutture: listaStrutture,
                    codPresidio: codicePresidio,
                    fonte: { id: fonte.value },
                    datRifFonte: new Date(datRifFonte),
                    longitudine: longitudine,
                    latitudine: latitudine
                };
                if(payload.idPresidio) {
                wait(presidiService.updatePresidi(payload))
                    .then((result) => {
                        setIsPageChanged(false);
                        navigate(-1);
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {

                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
                } else {
                wait(presidiService.insertPresidi(payload))
                    .then((result) => {
                        setIsPageChanged(false);
                        navigate(-1);
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {

                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
                }
            }
        }
    }

    const confirmSalvaClick = () => {
        salvaClick();
        toggleModalSalva(false);
    }

    const buildPresidio = (presidio) => {
        setAnagraficaPresidio({
            "denominazioneAzienda": presidio.azienda.nomeAzienda,
            "denominazionePresidio": presidio.nomePresidio,
            "codIstatComune": presidio.comune.id,
            "nomeComune": presidio.comune.descrizione,
            "indirizzo": (presidio.indirizzo !== null && presidio.indirizzo !== "") ? presidio.indirizzo : presidio.azienda.indirizzo,
            "nomeAzienda": presidio.azienda.nomeAzienda,
            "tipoPresidio": ({ 'label': presidio.tipoPresidio.nomeTipoPresidio, 'value': presidio.tipoPresidio.idTipoPresidio })
        });
        setDenominazioneError(presidio.nomePresidio === null);
        setDenominazioneHelper("Campo obbligatorio");
        setComuneError(presidio.comune.descrizione === null);
        setComuneHelper("Campo obbligatorio");
        setIndirizzoError(presidio.indirizzo === null);
        setIndirizzoHelper("Campo obbligatorio");
        setTipoPresidioError(presidio.tipoPresidio.idTipoPresidio === null);
        setTipoPresidioHelper("Campo obbligatorio");
        setInputComune({
            "label": presidio.comune.descrizione,
            "value": presidio.comune.id,
        })
        setDatiDiContatto({
            "telefono": presidio.telefono,
            "fax": presidio.fax,
            "email": presidio.mail,
        })

        setIsComunePopulated(true);
        setLabelComune(true);

        setListaStrutture(presidio.strutture);
        setIdPresidio(presidio.idPresidio);

        setFonte(presidio.fonte ? { 'label': presidio.fonte.descrizione + " - " + presidio.fonte.codice, 'value': presidio.fonte.id } : null);
        setFonteError(presidio.fonte === null);
        setFonteHelper("Campo obbligatorio");

        setCodicePresidio(presidio.codPresidio);
        setCodicePresidioError(presidio.codPresidio === null);
        setCodicePresidioHelper("Campo obbligatorio");
        setDatRifFonte(presidio.datRifFonte ? formatDate(new Date(presidio.datRifFonte)) : null);

        setLatitudine(presidio.latitudine);
        latitudineValidation(presidio.latitudine);

        setLongitudine(presidio.longitudine);
        longitudineValidation(presidio.longitudine);
    }

    useEffect(() => {
        wait(tipologiaService.getTipoPresidio())
            .then((response) => {
                let tipoPresidiTmp = [];
                response.data.data.map((index) => {
                    tipoPresidiTmp.push({
                        'label': index.descrizione,
                        'value': index.id
                    });
                });
                setTipoPresidioList(tipoPresidiTmp);
            });
    }, []);

    useEffect(() => {
        wait(tipologiaService.getFonte())
            .then((response) => {
                let fonteListTmp = [];
                response.data.data.map((index) => {
                    fonteListTmp.push({
                        'label': index.descrizione + " - " + index.codice,
                        'value': index.id
                    });
                });
                setFonteList(fonteListTmp);
            });
    }, []);

    useEffect(() => {
        if (pageStatus === 'update' || dettaglio) {
            setIsUpdateDetail(true);
            wait(presidiService.getPresidi(itemId))
                .then((response) => {
                    if (response && response.data && response.data.data) {
                        setComeFromTypeAspAzienda(response.data.data.azienda.tipoAzienda.id === 1);
                        setNomeAzienda(response.data.data.azienda.nomeAzienda);
                        buildPresidio(response.data.data);
                    } else {
                        setAnagraficaPresidio({
                            "denominazioneAzienda": null,
                            "denominazionePresidio": null,
                            "codIstatComune": "",
                            "nomeComune": "",
                            "indirizzo": null,
                            "tipoPresidio": [],
                            "nomeAzienda": null,
                        });

                        setDatiDiContatto({
                            "telefono": null,
                            "fax": null,
                            "email": null,
                        })
                    }
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                        setGenericError(true);
                        setGenericErrorMessage(error.response.data.apierror.debugMessage);
                        return;
                    } else {
                        setGenericError(true);
                        setGenericErrorMessage("Errore durante l'operazione");
                        return;
                    }
                });
        } else if (pageStatus === "insert") {
            setAnagraficaPresidio({
                "denominazioneAzienda": anagraficaPresidio.denominazioneAzienda,
                "denominazionePresidio": anagraficaPresidio.denominazionePresidio,
                "codIstatComune": anagraficaPresidio.codIstatComune,
                "nomeComune": anagraficaPresidio.nomeComune,
                "indirizzo": location.state.indirizzo,
                "tipoPresidio": anagraficaPresidio.tipoPresidio,
                "nomeAzienda": anagraficaPresidio.nomeAzienda,
            });
        }
    }, [pageStatus])

    const onChangeFonte = (event) => {
        setIsFonteEnabled(false);
        setFonte(event);
    }

    const onChangeDatRifFonte = (event) => {
        console.log(datRifFonte);
        setDatRifFonte((event.target.value === '') ? null : event.target.value);
    }

    const handleNavigation = (pageStatus, item) => {
        navigate("/gestione-reparti", {
            state: {
                pageStatus: pageStatus,
                id: pageStatus && pageStatus === "insert" ? null : item && item.idStruttura,
                idPresidio: idPresidio,
                nomePresidio: anagraficaPresidio.denominazionePresidio,
                fromPresidio: true,
                nomeAzienda: anagraficaPresidio.nomeAzienda,
                fromAzienda: comeFromAzienda,
                fromStruttura: false,
                indirizzo: pageStatus && pageStatus === "insert" ? anagraficaPresidio.indirizzo : null,
                isTypeAspAzienda: comeFromTypeAspAzienda
            }
        });
    };

    const handleDeleteStrutture = (item) => {
        wait(strutturaService.deleteStrutture(item.idStruttura))
            .then((response) => {
                wait(presidiService.getPresidi(location && location.state && location.state.id ? location.state.id : itemId))
                    .then((response) => {
                        if (response && response.data && response.data.data) {
                            buildPresidio(response.data.data);
                            setGenericError(false);
                        }
                    }).catch((error) => {
                        if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                            setGenericError(true);
                            setGenericErrorMessage(error.response.data.apierror.debugMessage);
                            return;
                        } else {
                            setGenericError(true);
                            setGenericErrorMessage("Errore durante l'operazione");
                            return;
                        }
                    });
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
                    setGenericError(true);
                    setGenericErrorMessage(error.response.data.apierror.debugMessage);
                    return;
                } else {
                    setGenericError(true);
                    setGenericErrorMessage("Errore durante l'operazione");
                    return;
                }
            });
    };


    return (
        <Container fluid>
            {!comeFromPresidio && <CustomBreadCrumb
                key={comeFromAzienda}
                first={{
                    "name": "Elenco Aziende",
                    "onClick": () => {
                        if (!dettaglioPage) {
                            if (!isPageChanged) {
                                navigate("/aziende");
                            } else {
                                toggleModalIndietro(!isOpenIndietro);
                                if (isOpenIndietro) {
                                    navigate("/aziende");
                                }
                            }
                        } else {
                            navigate("/aziende");
                        }
                    },
                    "active": false
                }}
                second={{
                    "name": "Azienda",
                    "onClick": () => {
                        if (!dettaglioPage) {
                            if (!isPageChanged) {
                                navigate(-1);
                            } else {
                                toggleModalIndietro(!isOpenIndietro);
                                if (isOpenIndietro) {
                                    navigate(-1);
                                }
                            }
                        } else {
                            navigate(-1);
                        }
                    },
                    "active": false
                }}
                third={{
                    "name": "Presidio",
                    "onClick": () => { },
                    "active": true
                }}
            />}
            {comeFromPresidio && <CustomBreadCrumb
                key={comeFromAzienda}
                first={{
                    "name": "Elenco Presidi",
                    "onClick": () => {
                        if (!dettaglioPage) {
                            if (!isPageChanged) {
                                navigate(-1);
                            } else {
                                toggleModalIndietro(!isOpenIndietro);
                                if (isOpenIndietro) {
                                    navigate(-1);
                                }
                            }
                        } else {
                            navigate(-1);
                        }
                    },
                    "active": false
                }}
                second={{
                    "name": "Presidio",
                    "onClick": () => { },
                    "active": true
                }}
            />}
            <Accordion style={{ marginTop: "0px" }}>
                <AccordionHeader
                    className="d-none d-lg-block accordionButton"
                    active={collapseOpen1}
                    onToggle={() => setCollapseOpen1(!collapseOpen1)}
                >
                    Anagrafica
                </AccordionHeader>
                <AccordionBody active={collapseOpen1}>
                    <Row style={{ justifyContent: "flex-start", marginTop: "1em", marginBottom: "-2.5em" }}>
                        {/* <Col xs={12} md={6} lg={3} >
                            <Input
                                className='clickableLabel'
                                type='text'
                                label='Denominazione Azienda'
                                disabled
                                value={anagraficaPresidio.denominazioneAzienda}
                            />
                        </Col> */}
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                key={nomeAzienda}
                                className='clickableLabel'
                                type='text'
                                label='Denominazione Azienda'
                                disabled={true}
                                value={nomeAzienda}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                className='clickableLabel'
                                type='text'
                                label='Denominazione Presidio*'
                                disabled={dettaglio}
                                value={anagraficaPresidio.denominazionePresidio}
                                infoText={isSalvaClicked && denominazioneError && denominazioneHelper}
                                invalid={isSalvaClicked && denominazioneError}
                                onChange={(event) => { setIsPageChanged(true); handleChangeAnagrafica("denominazionePresidio", event); }}
                                maxLength={100}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                type='text'
                                className='clickableLabel'
                                label='Codice Presidio*'
                                value={codicePresidio}
                                onChange={(event) => {
                                    setIsPageChanged(true);
                                    setCodicePresidio(event.target.value);
                                    if (!event.target.value) {
                                        setCodicePresidioError(true);
                                        setCodicePresidioHelper("Campo obbligatorio");
                                    }
                                    else {
                                        setCodicePresidioError(false);
                                        setCodicePresidioHelper("");
                                    }
                                }}
                                infoText={isSalvaClicked && codicePresidioHelper}
                                invalid={isSalvaClicked && codicePresidioError}
                                readOnly={isUpdateDetail}
                                maxLength={100}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Col>
                                <div class="form-group">
                                    <label htmlFor='selectExampleClassic'
                                        class={(labelComune) ? "active" : null}
                                        onClick={() => {
                                            if (location && location.state && location.state.pageStatus === "detail")
                                                return
                                            setLabelComune(true);
                                            document.getElementById("input select comuni").focus();
                                        }}
                                    >Comune*</label>
                                    <Select
                                        key={anagraficaPresidio.codIstatComune}
                                        id={"inputComune"}
                                        inputId={"input select comuni"}
                                        onChange={handleChangeComune}
                                        isDisabled={dettaglio}
                                        defaultValue={isComunePopulated && { label: isComunePopulated && anagraficaPresidio.nomeComune, value: isComunePopulated && anagraficaPresidio.codIstatComune }}
                                        placeholder=""
                                        isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}

                                        onBlur={() => {
                                            if ((inputComune.label == null || inputComune.label === "") && (inputComune.value == null || inputComune.value === "")) {
                                                setLabelComune(false)
                                            }
                                        }}
                                        onFocus={() => { setLabelComune(true) }}
                                        onInputChange={(inputValue, action) => { onInputChangeComune(inputValue, action) }}
                                        options={comuniLista}
                                        invalid={!isValidCodIstatComune}
                                        noOptionsMessage={({ inputValue }) => !inputValue ? null : "Nessun risultato"}
                                    />
                                </div>
                                {(isSalvaClicked && comuneError) && <div style={{ marginTop: "-3em" }}>
                                    <label style={{ color: "red", fontSize: "0.70em" }}>{comuneHelper}</label>
                                </div>}
                            </Col>
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <div
                                class='form-group'
                                onClick={() => {
                                    if (location && location.state && location.state.pageStatus === "detail")
                                        return
                                    setIsTipoEnabled(true);
                                    document.getElementById('select tipo').focus();
                                }}
                            >
                                <label
                                    htmlFor='selectExampleClassic'
                                    class={(isTipoEnabled || anagraficaPresidio.tipoPresidio != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                    Tipo Presidio*</label>
                                <Select
                                    id='selectTipoPresidio'
                                    inputId='select tipo'
                                    onBlur={() => { setIsTipoEnabled(false); }}
                                    isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}

                                    placeholder=''
                                    aria-label='Scegli una opzione'
                                    isSearchable={false}
                                    options={tipoPresidioList}
                                    onChange={(event) => { setIsPageChanged(true); handleChangeAnagrafica("tipoPresidio", event ? event : null); }}
                                    value={anagraficaPresidio.tipoPresidio}
                                    styles={customStylesInputSelect}
                                    menuPortalTarget={document.body}
                                    menuIsOpen={isTipoEnabled}
                                    infoText={tipoPresidioHelper}
                                    invalid={tipoPresidioError}
                                    isDisabled={location && location.state && location.state.pageStatus === "detail" ? true : false}
                                />
                            </div>
                            {(isSalvaClicked && tipoPresidioError) && <div style={{ marginTop: "-3em" }}>
                                <label style={{ color: "red", fontSize: "0.70em" }}>{tipoPresidioHelper}</label>
                            </div>}
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                className='clickableLabel'
                                type='text'
                                label='Indirizzo*'
                                disabled={dettaglio}
                                value={anagraficaPresidio.indirizzo}
                                infoText={isSalvaClicked && indirizzoError && indirizzoHelper}
                                invalid={isSalvaClicked && indirizzoError}
                                onChange={(event) => { setIsPageChanged(true); handleChangeAnagrafica("indirizzo", event.target.value); }}
                                maxLength={100}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <div
                                class='form-group'
                                onClick={() => {
                                    if (location && location.state && location.state.pageStatus === "detail")
                                        return
                                    setIsFonteEnabled(true);
                                    document.getElementById('select fonte').focus();
                                }}
                            >
                                <label
                                    htmlFor='selectExampleClassic'
                                    class={(isFonteEnabled || fonte != null) ? 'active' : ''}
                                    style={{ cursor: 'context-menu' }}
                                >
                                    Fonte*</label>
                                <Select
                                    id='selectTipoFonte'
                                    inputId='select fonte'
                                    onBlur={() => { setIsFonteEnabled(false); }}
                                    isClearable={location && location.state && location.state.pageStatus === "detail" ? false : true}
                                    placeholder=''
                                    aria-label='Scegli una opzione'
                                    isSearchable={false}
                                    options={fonteList}
                                    onChange={(event) => {
                                        setIsPageChanged(true);
                                        onChangeFonte(event ? event : null);
                                        if (!event) {
                                            setFonteError(true);
                                            setFonteHelper("Campo obbligatorio");
                                        } else {
                                            setFonteError(false);
                                            setFonteHelper("");
                                        }
                                    }}
                                    value={fonte}
                                    styles={customStylesInputSelect}
                                    menuPortalTarget={document.body}
                                    menuIsOpen={isFonteEnabled}
                                    infoText={fonteHelper}
                                    invalid={fonteError}
                                />
                            </div>
                            {(isSalvaClicked && fonteError) && <div style={{ marginTop: "-3em" }}>
                                <label style={{ color: "red", fontSize: "0.70em" }}>{fonteHelper}</label>
                            </div>}
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <CustomDateField
                                value={datRifFonte}
                                placeholder='Data Riferimento Fonte'
                                disabled={location && location.state && location.state.pageStatus === "detail" ? true : false}
                                onChange={(event) => { onChangeDatRifFonte(event); }}
                            />
                            {(isSalvaClicked && !datRifFonte &&
                                <label style={{ color: "red", fontSize: "0.70em" }}>Campo obbligatorio</label>)}
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                type='number'
                                className='clickableLabel'
                                label='Longitudine'
                                value={longitudine}
                                onChange={(event) => {
                                    setIsPageChanged(true);
                                    setLongitudine(event.target.value);
                                    longitudineValidation(event.target.value);
                                }}
                                infoText={isLongitudineValid ? false : longitudineHelper}
                                invalid={!isLongitudineValid}
                                readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                                maxLength={100}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4} xl={3}>
                            <Input
                                type='number'
                                className='clickableLabel'
                                label='Latitudine'
                                value={latitudine}
                                onChange={(event) => {
                                    setIsPageChanged(true);
                                    setLatitudine(event.target.value);
                                    latitudineValidation(event.target.value);
                                }}
                                infoText={isLatitudineValid ? false : latitudineHelper}
                                invalid={!isLatitudineValid}
                                readOnly={location && location.state && location.state.pageStatus === "detail" ? true : false}
                                maxLength={100}
                            />
                        </Col>
                    </Row>
                </AccordionBody>

                <AccordionHeader
                    className="d-none d-lg-block accordionButton"
                    active={collapseOpen2}
                    onToggle={() => setCollapseOpen2(!collapseOpen2)}
                >
                    Dati di contatto
                </AccordionHeader>
                <AccordionBody active={collapseOpen2}>
                    <Row style={{ justifyContent: "flex-start", marginTop: "1em", marginBottom: "-2.5em" }}>
                        <Col xs={12} md={8} lg={3}  >
                            <Input
                                className='clickableLabel'
                                type='text'
                                label='Telefono'
                                value={datiDiContatto.telefono}
                                disabled={dettaglio}
                                onChange={(event) => { setIsPageChanged(true); handleChangeDatiDiContatto("telefono", event); }}
                                infoText={isValidTelefono ? false : "Telefono non valido"}
                                invalid={!isValidTelefono}
                            />
                        </Col>
                        <Col xs={12} md={8} lg={3}  >
                            <Input
                                className='clickableLabel'
                                type='text'
                                label='Fax'
                                value={datiDiContatto.fax}
                                disabled={dettaglio}
                                onChange={(event) => { setIsPageChanged(true); handleChangeDatiDiContatto("fax", event); }}
                                infoText={isValidFax ? false : "Fax non valido"}
                                invalid={!isValidFax}
                            />
                        </Col>
                        <Col xs={12} md={8} lg={3}>
                            <Input
                                className='clickableLabel'
                                type='email'
                                label='Email'
                                value={datiDiContatto.email}
                                disabled={dettaglio}
                                onChange={(event) => { setIsPageChanged(true); handleChangeDatiDiContatto("email", event); }}
                                infoText={isValidEmail ? false : "Email non valido"}
                                invalid={!isValidEmail}
                            />
                        </Col>
                    </Row>
                </AccordionBody>
                {location && location.state && location.state.pageStatus === "insert" ? ("") : <>
                    <AccordionHeader
                        className="d-none d-lg-block accordionButton"
                        active={collapseOpen3}
                        onToggle={() => setCollapseOpen3(!collapseOpen3)}
                    >
                        {comeFromTypeAspAzienda ? "Strutture" : "Reparti"}
                    </AccordionHeader>
                    <AccordionBody active={collapseOpen3}>
                        <Row className='d-flex justify-content-end'>
                            {!dettaglioPage && 
                            isATL_OP_ADM_RESP ? 
                              <Col xs={12} xl={2}>
                                <Button
                                    className='buttonPrimary'
                                    id='inserisci_btn_presidi'
                                    color='primary'
                                    block
                                    onClick={() => {
                                        setItemToNavigateStruttura(null);
                                        setCauseForNavigateStruttura("insert");
                                        if (!dettaglioPage) {
                                            if (!isPageChanged) {
                                                handleNavigation("insert", null);
                                            } else {
                                                toggleModalStrutture(!isOpenStruttureChange);
                                                if (isOpenStruttureChange) {
                                                    handleNavigation("insert", null);
                                                }
                                            }
                                        } else {
                                            handleNavigation("insert", null);
                                        }
                                    }}
                                >
                                    INSERISCI
                                </Button>{' '}
                              </Col> : null}
                        </Row>
                        {listaStrutture && listaStrutture.length !== 0 ?
                            (<Row className='d-flex justify-content-end'>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Denominazione</th>
                                            <th scope='col'>Responsabile</th>
                                            <th scope='col'>Telefono Direzione</th>
                                            <th scope='col'>Telefono Segreteria/Fax</th>
                                            <th scope='col' class="text-center">Azioni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listaStrutture.map((data) => {
                                            return (
                                                <tr>
                                                    <td>{data.nomeStruttura}</td>
                                                    <td>{data.responsabile}</td>
                                                    <td>{data.telefonoDirezione}</td>
                                                    <td>{data.telefonoSegreteria}</td>
                                                    <td class="text-center">
                                                        <Button title="Visualizza" onClick={() => {
                                                            setItemToNavigateStruttura(data);
                                                            setCauseForNavigateStruttura("detail");
                                                            if (!dettaglioPage) {
                                                                if (!isPageChanged) {
                                                                    handleNavigation("detail", data);
                                                                } else {
                                                                    toggleModalStrutture(!isOpenStruttureChange);
                                                                    if (isOpenStruttureChange) {
                                                                        handleNavigation("detail", data);
                                                                    }
                                                                }
                                                            } else {
                                                                handleNavigation("detail", data);
                                                            }
                                                        }}> <Icon icon={"it-password-visible"} /></Button>
                                                        {!dettaglio && <>
                                                            <Button title="Aggiorna" onClick={() => {
                                                                setItemToNavigateStruttura(data);
                                                                setCauseForNavigateStruttura("update");
                                                                if (!dettaglioPage) {
                                                                    if (!isPageChanged) {
                                                                        handleNavigation("update", data);
                                                                    } else {
                                                                        toggleModalStrutture(!isOpenStruttureChange);
                                                                        if (isOpenStruttureChange) {
                                                                            handleNavigation("update", data);
                                                                        }
                                                                    }
                                                                } else {
                                                                    handleNavigation("update", data);
                                                                }
                                                            }}>  <Icon icon={"it-pencil"} /></Button>
                                                            {isATL_OP_ADM_RESP ? <Button title="Elimina" onClick={() => {
                                                                handleDeleteToggleStruttura(data);
                                                            }}>  <Icon icon={"it-delete"} /></Button> : null}</>}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </Table></Row>) : (<Row className='d-flex justify-content-center'><p>Nessun risultato trovato</p></Row>)
                        }
                        {
                            isOpenDeleteStruttura &&
                            <Modal
                                isOpen={isOpenDeleteStruttura}
                                toggle={() => toggleModalDeleteStruttura(!isOpenDeleteStruttura)}
                            >
                                <ModalHeader toggle={() => toggleModalDeleteStruttura(!isOpenDeleteStruttura)} id='esempio12'>
                                    Conferma
                                </ModalHeader>
                                <ModalBody>
                                    <p>L'operazione è irreversibile. Sei sicuro di voler procedere?</p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="buttonPrimary" onClick={() => toggleModalDeleteStruttura(!isOpenDeleteStruttura)}>
                                        Annulla
                                    </Button>
                                    <Button className="buttonPrimary" onClick={() => confirmDeleteStrutturaClick(itemStruttura)}>
                                        Conferma
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        }
                    </AccordionBody>
                </>}
            </Accordion>
            {
                (genericError) && <Alert color='danger' className='display-linebreak'>{genericErrorMessage}</Alert>
            }
            <Container fluid style={{ marginBottom: "3%" }}>
                <Row className="alignElementsCenterAndEnd" style={{ display: "flex" }}>
                    <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                        <Button className="buttonPrimary" onClick={() => {
                            if (!dettaglioPage) {
                                if (!isPageChanged) {
                                    navigate(-1);
                                } else {
                                    toggleModalIndietro(!isOpenIndietro);
                                    if (isOpenIndietro) {
                                        navigate(-1);
                                    }
                                }
                            } else {
                                navigate(-1);
                            }
                        }} id='indietro_btn'>INDIETRO</Button>
                        <Modal
                            isOpen={isOpenIndietro}
                            toggle={() => toggleModalIndietro(!isOpenIndietro)}
                        >
                            <ModalHeader toggle={() => toggleModalIndietro(!isOpenIndietro)} id='esempio12'>
                                Indietro
                            </ModalHeader>
                            <ModalBody>
                                <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => toggleModalIndietro(!isOpenIndietro)}>
                                    Annulla
                                </Button>
                                <Button className="buttonPrimary" onClick={() => { setIsPageChanged(false); navigate(-1); }}
                                >
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                    {!dettaglioPage && <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                        <Button
                            className="buttonPrimary"
                            onClick={() => {
                                if (!isPageChanged) {
                                    confirmSalvaClick()
                                } else {
                                    toggleModalSalva(true);
                                }
                            }}
                            id='salva_btn'>SALVA</Button>
                        <Modal
                            isOpen={isOpenSalva}
                            toggle={() => toggleModalSalva(!isOpenSalva)}
                        >
                            <ModalHeader toggle={() => toggleModalSalva(!isOpenSalva)} id='esempio12'>
                                Conferma
                            </ModalHeader>
                            <ModalBody>
                                <p>Confermi il salvataggio delle modifiche?</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => toggleModalSalva(false)}>
                                    Annulla
                                </Button>
                                <Button className="buttonPrimary" onClick={confirmSalvaClick}>
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Col>}
                    <Modal
                        isOpen={isOpenStruttureChange}
                        toggle={() => toggleModalStrutture(!isOpenStruttureChange)}
                    >
                        <ModalHeader toggle={() => toggleModalStrutture(!isOpenStruttureChange)} id='esempio12'>
                            ATTENZIONE
                        </ModalHeader>
                        <ModalBody>
                            <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => toggleModalStrutture(!isOpenStruttureChange)}>
                                Annulla
                            </Button>
                            <Button className="buttonPrimary" onClick={handleToggleModalStruttureNavigate}>
                                Conferma
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Row>
            </Container>
        </Container>
    )
}

export default GestionePresidio;