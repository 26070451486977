
const EMAIL_REGEX = /[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z_+])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9}$/;
const NOME_COGNOME_REGEX = /^[A-zÀ-ú ]{0,30}$/;
const CODICE_FISCALE_REGEX = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
const NOME_UTENTE_REGEX = /^[A-zÀ-ú0-9#_$^\"\'\-+=!*()@%&.,:;/ ]{0,30}$/;
const HHMM_TIME_FORMAT = /(^([0-5]?[0-9]):([0-5]?[0-9]))$|^(([0-9]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$)/;
const CAP_REGEX = /^[0-9]{5}$/;
const PHONE_REGEX = /^(\+{0,1}[0-9]{1,15}){0,1}$/;
const INTEGER_REGEX=/^[0-9]*$/;

export {
    INTEGER_REGEX,
    EMAIL_REGEX,
    NOME_UTENTE_REGEX,
    CODICE_FISCALE_REGEX,
    NOME_COGNOME_REGEX,
    HHMM_TIME_FORMAT,
    CAP_REGEX,
    PHONE_REGEX
};
