import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchAzienda = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/aziende/search`, payload);
};

const deleteAziende = payload => {
    return axios.delete(`${BASE_ENDPOINT}/v1/aziende/` + payload);
};

const saveAziende = payload => {
    return axios.put(`${BASE_ENDPOINT}/v1/aziende`, payload);
};

const insertAziende = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/aziende/insert`, payload);
};

const updateAziende = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/aziende/update`, payload);
};

const getAziende = payload => {
    return axios.get(`${BASE_ENDPOINT}/v1/aziende?idAzienda=` + payload);
};

export default {
    saveAziende,
    deleteAziende,
    searchAzienda,
    getAziende,
    insertAziende,
    updateAziende
};