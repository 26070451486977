import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchStruttura = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/strutture/search`, payload);
};

const deleteStrutture = payload => {
    return axios.delete(`${BASE_ENDPOINT}/v1/strutture/` + payload);
};

const saveStrutture = payload => {
    return axios.put(`${BASE_ENDPOINT}/v1/strutture`, payload);
};

const insertStrutture = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/strutture/insert`, payload);
};

const updateStrutture = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/strutture/update`, payload);
};

const getStrutture = payload => {
    return axios.get(`${BASE_ENDPOINT}/v1/strutture?idStruttura=` + payload);
};

const getServiziOptions = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/list/servizi`, payload);
};

const getPrestazioniOptions = payload => {
    return axios.post(`${BASE_ENDPOINT}/v1/list/prestazioni`, payload);
};

const getSpecializzazioneOptions = () => {
    return axios.get(`${BASE_ENDPOINT}/v1/list/tipoSpecialita`);
};

export default {
    saveStrutture,
    deleteStrutture,
    searchStruttura,
    getStrutture,
    getServiziOptions,
    getPrestazioniOptions,
    getSpecializzazioneOptions,
    insertStrutture,
    updateStrutture
};