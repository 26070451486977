import { Select, Col, Row, Button, Alert, Tooltip, Container } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import { wait } from '../common/Loading';
import '../../App.css';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';
import caricamentoService from '../service/caricamentoService';
import tipologiaService from '../service/tipologiaService';
function CaricamentoPresidio() {
  const [filesBase64, setFilesBase64] = useState(null);
  const [toolTip, setTooltip] = useState(false);
  const [svuotaToolTip, setSvuotaTooltip] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [tipoFonte, setTipoFonte] = useState(null);
  const [tipoFonteList, setTipoFonteList] = useState([]);
  const [isTipoEnabled, setIsTipoEnabled] = useState(false);

  const customStylesInputSelect = {
    menuPortal: base => ({
      ...base,
      zIndex: 999
    })
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    useEffect(() => {
        localStorage.setItem('page', 0);
    }, []);

  useEffect(() => {
    wait(tipologiaService.getTipoFonte())
      .then((response) => {
        let tipoFonteTmp = [];
        response.data.data.map((index) => {
          tipoFonteTmp.push({
            'label': index.descrizione,
            'value': index.id
          });
        });
        setTipoFonteList(tipoFonteTmp);
      });
  }, []);

  const fileToBase64 = (fileInput) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileInput);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  async function changeHandler(e) {

    const tmp = e.target.files[0];

    const file = await fileToBase64(tmp);

    setFilesBase64({
      allegato: file.split(",")[1],
      estensione: tmp.name.split(".")[1],
      size: tmp.size,
      nomeFile: tmp.name,
      mimeType: tmp.type,
    });
  }

  const handleSubmission = () => {
    setGenericError(false);
    setGenericErrorMessage("");
    let error = 0;

    if (tipoFonte === null) {
      setGenericError(true);
      setGenericErrorMessage("Il tipo fonte non è stato selezionato");
      error++;
    } else if (!filesBase64) {
      setGenericError(true);
      setGenericErrorMessage("Il file è vuoto");
      document.getElementById("file").value = "";
      error++;
    } else if (filesBase64.estensione !== "csv") {
      setGenericError(true);
      setGenericErrorMessage("Il formato del file non è csv");
      document.getElementById("file").value = "";
      error++;
    }

    if (error < 1) {
      let payload = {
        tipoFonte: tipoFonte,
        tipoCaricamento: 1,
        file: {
          allegato: filesBase64.allegato,
          nomeFile: filesBase64.nomeFile,
          estensione: filesBase64.estensione,
          mimeType: filesBase64.mimeType
        },
      };
      wait(caricamentoService.caricaPresidiStrutture(payload))
        .then((response) => {

        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.apierror && error.response.data.apierror.debugMessage) {
            setGenericError(true);
            setGenericErrorMessage(error.response.data.apierror.debugMessage);
            return;
          } else {
            setGenericError(true);
            setGenericErrorMessage("Errore durante l'operazione");
            return;
          }
        });
    }
  };

  const handlePulisci = () => {
    setGenericError(false);
    setGenericErrorMessage("");
    setFilesBase64(null);
    document.getElementById("file").value = "";
  }

  const onChangeTipoFonte = (event) => {
    setTipoFonte(event);
    setIsTipoEnabled(false);
  }

  return (<Container fluid>
    <CustomBreadCrumb
      first={{
        "name": "Caricamento Presidi/Strutture",
        "onClick": () => { },
        "active": true
      }}
    />
    <Col>
      <span class='bi bi-check-circle' />
      <Row style={{ marginTop: '4vh', columnGap: "1em" }}>
        <Col xs={12} md={3}  >
          <div
            class='form-group'
            onClick={() => {
              setIsTipoEnabled(true);
              document.getElementById('selectTipoFonte').focus();
            }}
          >
            <label
              htmlFor='selectExampleClassic'
              class={(isTipoEnabled || tipoFonte != null) ? 'active' : ''}
              style={{ cursor: 'context-menu' }}
            >
              Tipo</label>
            <Select
              id='selectTipoFonte'
              inputId='select tipo'
              onBlur={() => { setIsTipoEnabled(false); }}
              isClearable={true}
              placeholder=''
              aria-label='Scegli una opzione'
              isSearchable={false}
              options={tipoFonteList}
              onChange={(event) => { onChangeTipoFonte(event ? event.value : null); }}
              styles={customStylesInputSelect}
              menuPortalTarget={document.body}
              menuIsOpen={isTipoEnabled}
            />
          </div>
        </Col>
        <Col xs={12} md={3}  >
          <div>
            <input id="file" type="file" name="file" onChange={changeHandler} />
          </div>
        </Col>

        <Tooltip
          placement='top'
          isOpen={toolTip}
          target={'carica_btn'}
          toggle={() => setTooltip(!toolTip)}
        >
          Carica file in formato .csv contenente le informazioni dei Presidi/Strutture
        </Tooltip>
        <Tooltip
          placement='top'
          isOpen={svuotaToolTip}
          target={'pulisci_btn'}
          toggle={() => setSvuotaTooltip(!svuotaToolTip)}
        >
          Carica file in formato .csv contenente le informazioni dei Presidi/Strutture
        </Tooltip>
      </Row>
      <Row className='d-flex justify-content-end'>
        <Col xs={12} xl={2}>
          <Button className="buttonPrimary"
            id='pulisci_btn'
            color='primary'
            block
            onClick={handlePulisci}
          >
            PULISCI
          </Button>{' '}
        </Col>  <Col xs={12} xl={2}>
          <Button className="buttonPrimary"
            id='carica_btn'
            color='primary'
            block
            onClick={handleSubmission}
          >
            CARICA
          </Button>{' '}
        </Col>
      </Row>
      {
        (genericError) && <Alert color='danger' className='display-linebreak'>{genericErrorMessage}</Alert>
      }
    </Col></Container>

  );
}

export default CaricamentoPresidio;